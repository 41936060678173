.faq {
    padding-top: 30px;

    .accordion {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        
    
        &::after {
            content: '+';
            float: right;
            font-size: 30px;
        }

        &.active {    
            &::after {
                content: '-';
            }
        }
    }
    
    .panel {
        position: absolute;
        top:-9999px !important;
        left:-9999px !important;
    
        &.show {
            position: relative;
            top: 0 !important;
            left: 0 !important;
            margin-top: 30px;
        }

        b {
            font-family: 'Montserrat-Bold';
        }
    }

    .line--thick {
        @media (max-width: 500px) {
            border-top: 3px solid #F1F1F1;
        }
    }

    &__search {
        text-align: center;
        margin-bottom: 50px;

        input[type=text] {
            padding: 20px;
            width: 100%;
            background-image: url('/assets/images/magnifying.png');
            background-repeat: no-repeat;
            background-size: 10%;
            background-position: 95% center; 
        }
    }

    &__each {
        background: #F6F6F6;
        padding: 30px 100px;
        margin: 0 100px 5px;

        @media (max-width: 1199px) {
            padding: 30px;
            margin: 0 0px 5px;
        }

        .sub-title {
            margin-bottom: 0;
            padding-right: 50px;
            text-transform: initial;
            letter-spacing: 0;

            @media (max-width: 1199px) {
                margin: 0;
            }

            @media (max-width: 500px) {
                font-size: 15px;
                padding-right: 20px;
            }
        }
    }
} 
   