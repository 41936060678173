.header {
    display: flex;
    justify-content: space-between;
    z-index: 99;

    &__logo {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        img {
            max-width: 200px;
            width: 100%;
        }
    }

    &__nav {

        &__lang {
            a {
                color: #777777;
            }

            a.active {
                font-family: 'Montserrat-Bold';
            }
        }
        &__contact {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 500px) {
                left: 50%;
                transform: translateX(-60%);
                position: absolute;
            }
        

            &::before {
                background-image: url('/assets/images/contact/icon-call.png');
                background-size: 100%;
                background-repeat: no-repeat;
                display: inline-block;
                width: 25px;
                height: 25px;
                content: "";
                margin-right: 5px;
            }
        }

        &__saved{
            display: flex;
            justify-content: center;
            align-items: center;
        

            &::before {
                background-image: url('/assets/images/property/icon-fav.png');
                background-size: 100%;
                background-repeat: no-repeat;
                display: inline-block;
                width: 25px;
                height: 25px;
                content: "";
                margin-right: 5px;
            }
        }
    }
    
    @media (max-width: 1199px) {
        flex-wrap: wrap;
        background: #F2F2F2;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;

        &__logo {
            text-align: center;
            width: 100vw;
            background: white;
            padding: 15px 0;
        }

        &__nav {
            &__lang {
                order: 2;
            }

            &__contact {
                order: 0;
            }
        }

        .nav__top {
            padding: 0 20px;
            margin-bottom: 12px;
            margin-top: 10px;
        }

        .nav__bottom {
            position: absolute;
            width: 100%;
            left: 0;
            top: 121px;
            justify-content: initial;
            flex-direction: column;
            background: #ffffff;
            padding: 20px;
            transform: translateX(-100%);
            opacity: 0;
            transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

            li {
                padding: 10px; 
            }

            &.show {
                transform: translateX(0);
                opacity: 0.96;
                transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
                z-index: 99;
            }
        }
    }

    @media (max-width: 500px) {
        .header__nav__saved {
            display: none;
        }

        .nav__top {
            padding: 0 5px;
        }
    }
}

.hamburger-menu {
    padding: 5px 20px 0;
    
    @media (min-width: 1199px){
        display: none;
    }
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: black;
  border-radius: 10px;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-7px, 6px);
  transform: rotate(-45deg) translate(-7px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-9px, -9px);
  transform: rotate(45deg) translate(-9px, -9px);
}

.g-header {
    &__bg {
        background: #F4F4F4;
        height: 50px;
        width: 100vw;
        position: absolute;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        top: 0;
        z-index: -1;
    }
}

.home-header {
    .g-header__bg {
        display: none;
    }
}