html{
    overflow-x: hidden;
}

body{
    width: 100%;
    max-width: 1700px;
    margin: 0 auto;
    font-family: 'Montserrat-Medium';
    padding: 10px 20px 0;   

    @media (max-width: 1024px) {
        overflow-x: hidden;
    }

    @media (max-width: 991px) {
        padding: 0 20px;            
    }

    @media (max-width: 500px) {
        padding: 0 10px;            
    }

    ::selection {
        background-color: #FFC85A;
      }
}

body.body{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Montserrat-Medium';
    padding: 10px 20px 0;   

    @media (max-width: 1024px) {
        overflow-x: hidden;
    }

    @media (max-width: 991px) {
        padding: 0 20px;            
    }

    @media (max-width: 500px) {
        padding: 0 10px;            
    }

    ::selection {
        background-color: #FFC85A;
      }
}

ul {
    list-style: none;

    li {
        margin: 0 10px;
        color: #27241A;
        font-family: 'Montserrat-Medium';
        letter-spacing: 0.7px;
        text-transform: uppercase;
    }
}


input,
input[type=text],
input[type=date],
input[type=email],
select {
    border: 1px solid #F2F2F2;
    margin-top: 5px;
    padding: 5px;
    outline: none;
    width: 170px;
    height: 31px;
    color: #817F79;
    background: transparent;
    

    @media (max-width: 751px) {
        width: 32vw;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
}

select {
    padding: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23AAAAAA'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: 97% 12px;
    background-repeat: no-repeat;
    color: #757575;
}


a {
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

