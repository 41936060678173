.pet {

    .container{
        // @media (min-width: 1200px) {
        //     max-width: 1100px;
        // }
        @media (max-width: 1250px) {
            max-width: 1010px;
        }
    }

    section{
       padding: 15px;
       @media (max-width: 500px) {
        padding: 1rem 2rem;
    }
    }
    .page-title {
        @media (max-width: 500px) {
            padding: 30px 0 0 0;
            margin-bottom: -50px !important;
        }
    }
    
    &__sec1{
        padding: 0 !important;
        position: relative;
        &__bg{
            img{
                width: 75%;
                height: auto;
                margin-right: 0;
                margin-left: auto;
                display: block; 
                min-height: 400px;
                object-fit: cover;
                object-position: center;
                @media (max-width: 1199px) {
                    width: 100%;
                    min-height: 240px;
                }
            }
        }
        &__text{
            position: absolute;
            background: #fff;
            width: 45%;
            top: 50%;
            left: 5%;
            max-width: 640px ;
            transform: translate(0%, -50%);     
            padding: 2rem 4rem;
            @media (max-width: 1550px) {
                max-width: 550px;

            }
            @media (max-width: 1199px) {
                margin: 0 auto;
                padding: 2rem 1rem;
                margin-top: -70px;
                position: relative;
                width: 90%;
                top: auto;
                left: auto;
                transform: unset ;
              } 
              @media (max-width: 500px) {
               padding-bottom: 0;
              }
        }
    }

    &__sec2{
        padding: 0 !important;
        position: relative;
        margin-bottom: 3rem;
        @media (max-width: 1199px) {
            margin-bottom: 0;
        }
        &__bg{
            img{
                width: 75%;
                height: auto;
                display: block; 
                min-height: 500px;
                object-fit: cover;
                object-position: center;
                @media (max-width: 1199px) {
                    width: 100%;
                    min-height: 240px;
                }
            }
        }
        &__text{
            position: absolute;
            background: #fff;
            width: 52%;
            top: 50%;
            right: 5%;
            transform: translate(0%, -50%);
            padding: 2rem 4rem;
            @media (max-width: 1199px) {
                margin: 0 auto;
                padding: 2rem 1rem;
                margin-top: -70px;
                position: relative;
                width: 90%;
                top: auto;
                right: auto;
                transform: unset ;
              } 

            h3{
                font-size: 23px;
                margin-bottom: 10px;
            }
            .btn{
                padding: 8px 25px;
                margin: 0;
                width: auto;
                border-radius: 0;
            }
        }
    }

       
    &__faq {
        background: #F6F6F6;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        padding-top: 50px;

        @media (max-width: 500px) {
            margin-top: 80px;
        }

        .d-flex {
            @media (max-width: 991px) {
                flex-direction: column;
            }
        }

        &__col {
            @media (max-width: 991px) {
                margin: 0 auto;
                text-align: center;
                border-bottom: 1px solid #979797;
            }

            &:last-child {
                margin-left: 200px;

                @media (max-width: 991px) {
                    margin: 0 auto;
                    border-bottom: none;
                }
            }
        }

        &__each {
            padding: 30px 30px 30px 0px;
            width: 420px;
            border-bottom: 1px solid #979797;
            font-family: 'Montserrat-Bold';

            &:last-child {
                border-bottom: none;
                margin-bottom: 10px;
            }

            @media (max-width: 500px) {
                width: 100%;
            }
        }
    }
    @media (max-width: 500px) {
        .accordion {
            margin-top:1rem;
            &::after{
                bottom: 0;
                font-size: 1.5rem;
                content: "+";
                line-height: 17px;
            }
    
            &.active {   
                &::after{
                    content: "-";
                }
            }
        }
    
        .panel {
            display: none;
        
            &.show {
                display: block;
            }
        }
        
    }

    &-info{
        text-align: center;
        max-width: 900px;
        margin: 3rem auto;
        @media (max-width: 769px) {
            margin: 0rem auto 1rem
        }
       .row{
           .pet-info-col{
               .card-title{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                text-align: left;

                p{
                    margin: 0;
                    padding: 0 10px;
                }
               }

           }
       }
        
    }
    &__travel{
        padding: 10px 15px !important;
        padding: 0; 
        text-align: center;
        border-top: 2px solid #DBDBDB;
        border-bottom: 2px solid #DBDBDB;

        p{
            margin: 0;
        }
    }
    &-features{
        padding: 4rem 15px 2rem !important;
        @media (max-width: 769px)  {
            padding: 1rem 0 1.8rem !important;
        }
        .row{
            .pet-features-col{
                .card{
                    border: 0;
                    padding: 1.5rem;
                    
                    @media (max-width: 769px)  {
                        border-bottom: 3px solid #F1F1F1;
                    }
                    &-title{
                        display: flex;
                        align-items: center;
                        flex-wrap: nowrap;
                        @media (max-width: 769px) {
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            margin: 1.25rem auto;
                            }
                        img{
                            height: 40px;
                            max-height: 40px;
                            max-width: 70px;
                            object-fit: contain;
                        }
                        h3{
                            padding: 0 15px;
                            margin: 0;
                            
                            @media (max-width: 769px) {
                                font-size: 17px;
                                margin: 1rem auto;
                            }
                        }
                        
                    }
                    &-text{
                        @media (max-width: 769px) {
                            text-align: center;
                        }
                        p{
                            line-height: 28px;
                        }
                        a.card-link{
                            font-weight: bold;
                            color: #232019;
                            line-height: 18px !important;
                        }
                    }
                }
           
            }
        }
    }

    .questions{
        @media (max-width: 769px) {
           padding: 50px 15px;
        }
    }
}