.privacy {
    max-width: 1000px;
    margin: 0 auto;
    padding: 50px 0 0;

    h3.sub-title {
        text-transform: initial;
        padding: 5px 0;
        margin: 0;
    }

    p {
        text-transform: initial;
        letter-spacing: 0.7px;
        line-height: 32px;

        b {
            font-family: 'Montserrat-Bold';
        }
    }

    ul {
        padding-inline-start: 0;

        li {
            margin: 0;
        }
    }

    @media (max-width: 500px) {
        padding: 10px;
        
        .line--thick {
            border-top: 3px solid #F1F1F1;
        }
    }
}


