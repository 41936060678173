.dropdown {
    position: relative;
    display: inline-block;

    @media (max-width: 1199px) {
        display: contents;
    }

    span {
        cursor: pointer;

        @media (max-width: 1199px) {
            padding: 10px;
        }

        &::after {
            content: "v";
            display: inline-block;
            -webkit-transform: scaleX(1.4) scaleY(0.5);
            -moz-transform: scaleX(1.4) scaleY(0.5);
            -o-transform: scaleX(1.4) scaleY(0.5);
            -ms-transform: scaleX(1.4) scaleY(0.5);
            transform: scaleX(1.4) scaleY(0.5);
            font-family: 'Montserrat-Bold';
            color: #27241A;
            font-size: 10px;
            margin-left: 5px;
        }
    }
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    width: max-content;
    padding: 12px 16px;
    z-index: 99;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);

    &.show {
        display: block;
    }

    &.w-auto {
        @media (max-width: 1199px) {
            width: 100% !important;
        }
        
        a {
            margin-left: 0;
        }
    }

    ul {
        padding-left: 0;

        li {
            padding: 10px 35px 10px;
            background: #f9f9f9;

            &:hover {
                background: #F1F0F0;
            }

            a {
                text-transform: capitalize;
                font-family: 'Montserrat-Semibold';
                letter-spacing: 0;
                font-size: 15px;
            }
            
        }
    }

    &.clicked {
        display: block;
    }

    @media (max-width: 1199px) {
       position: relative;
       width: 100%;
       text-align: center;
    }
  }
  
  .dropdown:hover .dropdown-content {
    @media (min-width: 1200px){
        display: block;
    }
  }

