.fav {
    &::before {
        background-image: url('/assets/images/fav-empty.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 23px;
        height: 23px;
        content: "";
        position: absolute;
        top: 13px;
        right: 0;
        margin-right: 11px;
        z-index: 2;

        @media (max-width: 500px) {
            top: -5px;
            margin-right: -5px;
        }
    }

    &::after {
        background-image: url('/assets/images/triangle.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 50px;
        height: 50px;
        content: "";
        margin-top: -5px;
        position: absolute;
        top: 15px;
        right: 0;
        margin-right: 10px;
        z-index: 1;

        @media (max-width: 500px) {
            display: none;
        }
    }

    &.active {
        &::before {
            background-image: url('/assets/images/fav.png');
        }
    }
}

.star {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;

    @media (max-width: 500px) {
        margin-top: 4px;
        margin-left: 15px;
    }

    &::before {
        background-image: url('/assets/images/star.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 14px;
        height: 14px;
        content: "";
        margin-right: 3px;
    }
}

.chat {
    display: flex;
    align-items: center;

    &::before {
        background-image: url('/assets/images/property/icon-chat.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 40px;
        height: 40px;
        content: "";
        margin-right: 10px;

        @media (max-width: 1199px) {
            width: 25px;
            height: 25px;
        }
    }
}

.icon-grid-view {
    opacity: 0.5;

    &::before {
        background-image: url('/assets/images/grid-view.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 30px;
        height: 25px;
        content: "";
        margin: 10px 12px 10px 23px;
        cursor: pointer;
    }

    &.active {
        opacity: 1;
    }
}

.icon-map-view {
    opacity: 0.5;

    &::before {
        background-image: url('/assets/images/map-view.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 30px;
        height: 25px;
        content: "";
        cursor: pointer;
    }

    &.active {
        opacity: 1;
    }
}

.fav-local {
    position: relative; 
    
    &::before {
        content: "Local's Favorite";
        position: absolute;
        right: 0;
        margin: 0px 10px;
        font-size: 14px;
        background: white;
        opacity: 0.9;
        color: #7D796E;
        text-align: center;
        padding: 5px 10px;
    }
}

.icon-bed {
    &::before {
        background-image: url('/assets/images/property/icon-bed.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 25px;
        height: 20px;
        content: "";
        margin-right: 10px;
    }
}

.icon-bath {
    &::before {
        background-image: url('/assets/images/property/icon-bath.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 20px;
        height: 20px;
        content: "";
        margin-right: 10px;
    }
}

.icon-person {
    &::before {
        background-image: url('/assets/images/property/icon-user.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 20px;
        height: 20px;
        content: "";
        margin-right: 10px;
    }
}

.icon-pet {
    &::before {
        background-image: url('/assets/images/property/icon-paw.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 18px;
        height: 20px;
        content: "";
        margin-right: 10px;
    }
}

.icon-calendar {
    &::before {
        background-image: url('/assets/images/property/icon-calender.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 21px;
        height: 20px;
        content: "";
        margin-right: 10px;
    }
}

.icon-clock {
    &::before {
        background-image: url('/assets/images/property/icon-clock.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 20px;
        height: 22px;
        content: "";
        margin-top: 6px;
        margin-right: 10px;
    }
}

.icon-nosmoking {
    &::before {
        background-image: url('/assets/images/property/icon-nosmoking.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 25px;
        height: 20px;
        content: "";
        margin-top: 6px;
        margin-right: 10px;
    }
}

.icon-wifi {
    &::before {
        background-image: url('/assets/images/property/icon-wifi.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 29px;
        height: 22px;
        content: "";
        margin-top: 4px;
        margin-right: 10px;
    }
}

.icon-queen {
    &::before {
        background-image: url('/assets/images/property/icon-queen.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 29px;
        height: 22px;
        content: "";
        margin-top: 4px;
        margin-right: 10px;
    }
}

.icon-laundry {
    &::before {
        background-image: url('/assets/images/property/icon-laundry.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 21px;
        height: 28px;
        content: "";
        margin-left: 3px;
        margin-top: 4px;
        margin-right: 16px;
    }
}

.icon-phone {
    &::before {
        background-image: url('/assets/images/property/icon-telephone.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 22px;
        height: 22px;
        content: "";
        margin-top: 5px;
        margin-right: 16px;
        margin-left: 3px;
    }
}

.icon-dishwasher {
    &::before {
        background-image: url('/assets/images/property/icon-dishwasher.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 24px;
        height: 22px;
        content: "";
        margin-left: 2px;
        margin-top: 6px;
        margin-right: 15px;
    }
}

.icon-pool {
    &::before {
        background-image: url('/assets/images/property/icon-pool.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 24px;
        height: 22px;
        content: "";
        margin-left: 2px;
        margin-top: 6px;
        margin-right: 15px;
    }
}

.icon-kid {
    &::before {
        background-image: url('/assets/images/property/icon-kid.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 21px;
        height: 28px;
        content: "";
        margin-left: 4px;
        margin-top: 0px;
        margin-right: 16px;
    }
}

.icon-tv {
    &::before {
        background-image: url('/assets/images/property/icon-tv.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 22px;
        height: 23px;
        content: "";
        margin-left: 3px;
        margin-top: 6px;
        margin-right: 15px;
    }
}

.icon-parking {
    &::before {
        background-image: url('/assets/images/property/icon-parking.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 23px;
        height: 22px;
        content: "";
        margin-left: 2px;
        margin-top: 6px;
        margin-right: 15px;
    }
}

.icon-microwave {
    &::before {
        background-image: url('/assets/images/property/icon-microwave.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 25px;
        height: 22px;
        content: "";
        margin-top: 8px;
        margin-right: 15px;
    }
}

.icon-oval {
    &::before {
        background-image: url('/assets/images/property/icon-oval.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 21px;
        height: 21px;
        content: "";
        margin-top: 6px;
        margin-right: 15px;
    }
}

.icon-dryer {
    &::before {
        background-image: url('/assets/images/property/icon-dryer.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 30px;
        height: 28px;
        content: "";
        margin-top: 0px;
        margin-right: 7px;
    }
}

.icon-iron {
    &::before {
        background-image: url('/assets/images/property/icon-iron.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 25px;
        height: 20px;
        content: "";
        margin-top: 8px;
        margin-right: 13px;
    }
}

.icon-safety {
    &::before {
        content: url('/assets/images/why/safety.png');
        margin-right: 20px;
    }
}

.icon-cleaning {
    &::before {
        content: url('/assets/images/why/cleaning.png');
        margin-right: 20px;
    }
}

.icon-laundry-why {
    &::before {
        content: url('/assets/images/why/laundry.png');
        margin-right: 20px;
    }
}

.icon-tea {
    &::before {
        content: url('/assets/images/why/tea.png');
        margin-right: 20px;
    }
}

.icon-location {
    &::before {
        content: url('/assets/images/why/location.png');
        margin-right: 15px;
        margin-left: 5px;
    }
}

.icon-clean{
    &::before {
        content: url('/assets/images/why/clean.png');
        margin-right: 20px;
    }
}
.icon-workspace{
    &::before {
        content: url('/assets/images/why/workspace.png');
        margin-right: 20px;
    }
}
.icon-towel  {
    &::before {
        content: url('/assets/images/why/towel.png');
        margin-right: 20px;
    }
}
.icon-bed-why {
    &::before {
        content: url('/assets/images/why/bed.png');
        margin-right: 20px;
    }
}
.icon-star {
    &::before {
        content: url('/assets/images/why/star.png');
        margin-right: 30px;
        margin-left: -10px;
    }
}
.icon-wifi-why {
    &::before {
        content: url('/assets/images/why/wifi.png');
        margin-right: 20px;
    }
}
.icon-chat{
    &::before {
        content: url('/assets/images/why/chat.png');
        margin-right: 20px;
    }
}

.icon-bubble{
    &::before {
        content: url('/assets/images/why/bubble.png');
        margin-right: 20px;
    }
}

   
