.footer {
    a {
        &:hover {
            opacity: 0.6;
        }
    }


    &__contact {
        margin: 10px;
        min-width: fit-content;
        width: 40%;


        &--each {
            display:flex;
            align-items: center;

            &:first-child img {
                width: 30px;
                margin-right: 10px;
            }

            &:nth-child(2) img {
                width: 20px;
                margin: 5px 15px 5px 5px;
            }

            &:nth-child(3) img {
                width: 25px;
                margin-right: 12px;
                margin-left: 2px;
            }

            &:last-child {
                margin-top: 30px;

                @media (max-width: 500px) {
                    margin-top: 10px;
                }

                img {
                    width: 30px;
                    margin: 0 5px;
                }
            } 

            &:not(:last-child) {
                @media (max-width: 500px) {
                    display: none;
                }
            }
        }
    }
    &__upper {
        display: flex;
        justify-content: space-between;
        max-width: 1700px;
        width: 100%;
        margin: 0 auto;
        text-align: left;
        padding: 30px 20px;

        @media (max-width: 500px) {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
        }
    }

    &__menu {
        display: flex;
        width: 75%;
        justify-content: initial;
        flex-wrap: wrap;

        @media (max-width: 500px) {
            flex-direction: column;
            text-align: center;
        }

        &__each {
            display: flex;
            flex-direction: column;
            margin: 10px 30px;

            @media (max-width: 500px) {
                margin: 10px 0;
            }
        }
    }

    .newsletter-form {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 500px) {
            flex-direction: column;
        }

        &--fields {
            display: flex;
        }

        &--submit {
            input[type=submit] {
                @media (max-width: 500px) {
                    padding: 7px 40px;
                }
            }
        }
    }

    &__copyright {
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: small;

        @media (max-width: 500px) {
            flex-direction: column;
            color: #8a8a8a;

            a {
                color: #8a8a8a;
            }
        }

        &--right {

            @media (max-width: 500px) {
                margin-top: 20px;
                font-size: 14px;
            }

            img:first-child {
                height: 25px;
                margin-left: 20px;
                margin-right: 10px;
            }

            img:last-child {
                height: 35px;
                margin-top: -5px;
            }
        }

        &--left {
            font-size: 14px;

            @media (max-width: 600px) {
                text-align: center;
            }

            a {
                font-size: 14px;
            }
        }
    }

    label {
        margin: 0;
        
    }

    input[type=email], select {
        background: transparent;
        border: 1px solid #C7C7C7;
        font-size: small;
        width: 100%;
        height: 32px;
    }

    input[type=email] {
        font-style: italic;
    }

    select {
        width: 100px;
        padding: 6px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23AAAAAA'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
        background-size: 12px;
        background-position: 90% 12px;
        background-repeat: no-repeat;
        color: #757575;
        border-radius: 0;
    }

    input[type=submit] {
        background-color: #8E8E8E;
        color: #ffffff;
        width: auto;
        padding: 7px 10px;
        margin: 5px 0 0;
    }
}