
#ajax-pagination {
    padding: 20px 0;

    ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline-start: 0;
    

        li {
            cursor: pointer;

            &.active {
                font-weight: 700;
                font-size: 110%;
            }
            
            &:first-child {
                &::after {
                    content: "|";
                    margin: 0 -10px 0 10px;
                }
                
                &:last-child {
                    &::after {
                        content: "";
                    }
                }

                &.active {
                    font-weight: bold;

                    &::after {
                        font-weight: normal;
                    }
                }
            }    
        }    
    }
}
