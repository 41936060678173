.saved {
    &__contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 0;

        @media (max-width: 500px) {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            padding: 20px;
            background-color: #F4F4F4;
        }

        h3.sub-title {
            margin-bottom: 30px;
        }

        .btn { 
            border-radius: 0;
            
            @media (max-width: 500px) {
                width: 100vw;
                background: #EBEBEB;
                margin: 5px;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-family: 'Montserrat-Medium';

                @media (max-width: 500px) {
                    color: black;
                    text-transform: capitalize;
                    font-family: 'Montserrat-SemiBold';
                } 

                &::before {
                    background-image: url('/assets/images/icon-call.png');            
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: 0px 10px;
                    display: inline-block;
                    content: "";
                    width: 30px;
                    height: 50px;
                    margin-right: 10px;
                    margin-top: -10px;
                    margin-bottom: -10px;

                    @media (max-width: 500px) {
                        margin-right: 30px;
                    }                        
                }
            }
        }

        .btn:first-child {
            a::before {
                background-image: url('/assets/images/contact/icon-chat.png');            
            }
        }
    }
}
