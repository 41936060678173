.item {
    text-align: center;
    margin: 0 10px;
}

.owl-nav {
    display: flex;
    justify-content: space-between;
    width: 70px;
    margin: 0 auto;

    @media (min-width: 1101px) {
        display: none;
    }
}

#processCarousel {
    padding: 30px 0;

    .item {
        max-width: 320px;
    }

    .owl-dots {
        display: flex;
        justify-content: center;
        align-items: center;

        .owl-dot {
            margin: 10px;
            transform: translateY(-5px);
            opacity: 0.38;

            &.active {
                opacity: 1;
                transform: scale(1.7);
                margin: 0 20px;
            }

            &:first-child > span::after {
                content: "1";
            }

            &:nth-child(2) > span::after {
                content: "2";
            }

            &:nth-child(3) > span::after {
                content: "3";
            }

            &:last-child > span::after {
                content: "···";
            }
        }
    }
}

@media (max-width: 500px) {
    #processCarousel {
        display: flex;
        flex-direction: column;

        .owl-stage-outer {
            order: 1;
        }

        .owl-nav {
            order: 2;
        }

        .owl-dots {
            order : 0;
        }

        .item span {
            display: none;
        }
    }
}


#citiesCarousel,
#citiesMobileCarousel {
    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 15px;      

        @media (max-width: 600px) {
            flex-wrap: wrap;
            flex-direction: row;
            padding: 0;
        }
          

        h5 {
            text-transform: capitalize;
            padding: 20px;
            font-size: 17px;
            font-family: 'Montserrat-SemiBold';
            letter-spacing: 0.7px;

            @media (max-width: 600px) {
                padding: 5px 0;
            }
        }
    }
}

#citiesCarousel {
    .owl-nav {
        display: block;

        .prev-grey {
            position: absolute;
            left: -30px;
            top: 35%;
        }

        .next-grey {
            position: absolute;
            right: -30px;
            top: 35%;
        }
    }

    .owl-item {
        img {
            max-width: 150px;
        }
    }

    @media (max-width: 600px) {
        display: none;
    }
}


#citiesMobileCarousel {
    @media (min-width: 600px) {
        display: none;
    }

    .item__wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        width: 50%;
        padding: 0;

        img {
            width: 85%;
        }
    }

    .owl-nav {
        padding: 15px 0;
    }


}


#reviewCarousel,
#propertyReviewCarousel,
#propertyAmenitiesCarousel,
#awards-carousel,
#blog-carousel {
    .owl-nav {
        display: none;
    }
    
    .owl-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-bottom: -20px;

        @media (max-width: 500px) {
            margin-top: 20px;
        }
    }

    .owl-dot {
        border: 1.7px solid #A5A5A5;
        background-color: #EAEAEA;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        margin: 0 5px;

        &.active {
            background-color: #FFC85A;
            border: 1.7px solid #636363;
        }
    }

}

#propertyAmenitiesCarousel {
    .owl-dots {
        margin-top: 0;
        margin-bottom: 20px;
    }
}

