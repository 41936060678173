.prev-mobile::before {
    background-image: url('/assets/images/prev-mobile.png');
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 25px;
    height: 25px;
    content: "";
    vertical-align: bottom;
    margin-right: 5px;
}

.next-mobile::before {
    background-image: url('/assets/images/next-mobile.png');
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 25px;
    height: 25px;
    content: "";
    vertical-align: bottom;
    margin-right: 5px;
}

.prev-grey::before {
    background-image: url('/assets/images/prev-grey.png');
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 25px;
    height: 25px;
    content: "";
    vertical-align: bottom;
    margin-right: 5px;
}

.next-grey::before {
    background-image: url('/assets/images/next-grey.png');
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 25px;
    height: 25px;
    content: "";
    vertical-align: bottom;
    margin-right: 5px;
}


.prev-white::before {
    background-image: url('/assets/images/property/prev.png');
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 30px;
    height: 30px;
    content: "";
    vertical-align: bottom;
    margin-right: 5px;
}

.next-white::before {
    background-image: url('/assets/images/property/next.png');
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 30px;
    height: 30px;
    content: "";
    vertical-align: bottom;
    margin-right: 5px;
}
