.blogs {
    padding-top: 50px;
    padding-bottom: 30px;
    border-bottom: 4px solid #f1f1f1;

    @media(max-width: 500px) {
        padding-top: 30px;
        padding-bottom: 0px;
        border-bottom: 2px solid #f1f1f1;
    }
    
    .sub-title {
        color: #000000;
        font-family: Montserrat;
        font-size: 17px;
        letter-spacing: 0.47px;
        line-height: 32px;
        text-align: center;
    }
}

.blog-container {
    // display:flex;
    // align-items: flex-start;
    // flex-wrap:wrap;
    // margin-left: -10px;
    // margin-right: -10px;

    // @media(max-width: 640px) {
    //     justify-content: center;
    // }
}

.blog {
    min-height: 300px;
    margin: 25px 0;


    &--img {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
    &--date {
        display:flex;
        align-items: center;
        color: #383838;
        font-family: Montserrat;
        font-size: 14px;
        letter-spacing: 0.61px;
        line-height: 30px;

        .icon-calendar:before {
            margin-right: 8px;
        }
    }

    &--title {
        color: #000000;
        font-family: "Montserrat-SemiBold";
        font-size: 18px;
        letter-spacing: 0.79px;
        line-height: 25px;
        margin: 10px 0;
    }
    &--detail {
        color: #232019;
        font-family: "Montserrat";
        font-size: 16px;
        letter-spacing: 0.7px;
        line-height: 32px;
    }

    @media (max-width: 500px) {
        margin: 15px 0px;
        
        &--img {
            margin-left: -10px;
            margin-right: -10px;
            width: calc(100% + 20px);
        }
    }

    //for first big blog
    &-latest {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        position: relative;
        margin: 40px 0 10px;
        margin-top: 40px;
        min-height: initial;

        .blog--img {
            height: auto;
        }

        .blog--box {
             background: white;
             padding: 16px 35px;
             transform: translateY(-100px);
             margin-bottom: -100px;

             @media screen and (min-width:501px) and (max-width: 1100px) {
                 margin-left: 20px;
                 margin-right: 20px;
             }

            .blog--title {
                margin: 20px 0 15px;
            }
        }
            
     

        @media(min-width: 1100px) {
            .blog--img {
                width:49.5%;
                margin-left: 0;
            }

            .blog--box {
                padding: 30px 58px;
                width: 60%;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

}

