.home {
    &__search {
        height: 550px;
        position: relative;

        @media (max-width: 1199px) {
            height: auto;
            margin-bottom: -75px;
        }

        @media (max-width: 500px) {
            margin-bottom: 0;
        }

        &__inner {
            background: white;
            padding: 30px 5px 30px 30px;
            position: absolute;
            top: 150px;
            max-width: 700px;
            width: 100%;

            @media (max-width: 1199px){
                position: relative;
                top: -100px;
                left: 50%;
                transform: translateX(-50%);
                padding: 30px;
            }

            @media (max-width: 500px) {
                position: relative;
                top: 0;
                margin-top: -150px;
                padding: 10px 20px;
                text-align: center;
            }
        }

        &__bg {
            background-image: url('/assets/images/home/search-banner.png');            
            background-size: 1500px 100%;
            background-position: right center;
            background-repeat: no-repeat;
            display: inline-block;
            max-width: 1920px;
            width: 100vw;            
            height: 580px;
            margin-top: -55px;
            z-index: -1;
            position: absolute;
            right: -300px;

            @media (max-width: 1199px) {
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                background-size: cover;
                background-position: center;
            }

            @media (max-width: 500px) {
                height: 400px;
                margin-top: 0;
                background-image: url('/assets/images/home/mobile-search-banner.png');            
            }
        }

        &__form {

            &__option {
                display: flex;
                flex-wrap: wrap;
                justify-content: start;

                #labelBedroom {
                    margin: 0 10px;

                    @media (max-width: 500px) {
                        margin: 0;
                    }
                }
            }

            &__date {
                display: flex;

                #checkIn, #checkOut {
                    width: 100px;

                    @media (max-width: 751px) {
                        width: 32vw;
                    }
                }

              

                @media (max-width: 500px) {
                    width: 100%;
                    margin-top: 5px;

                    label {
                        width: 50% !important;

                        &:first-child {
                            text-align: right;
                            margin-right: 5px;
                        }

                        &:last-child {
                            text-align: left;
                        }
                    }

                    #checkIn, #checkOut {
                        width: 100%;
                    }
                }
           
            }

            &__submit {

                input[type=submit] {
                    font-size: 14px;
                }

                @media (max-width: 500px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            label {
                display: flex;
                flex-direction: column;
                color: #232019;
                font-size: 14px;

                @media (max-width: 500px) {
                    width: 100%;
                }

            }
       
            input[type=submit] {
                margin: 0;
                margin-top: 20px;
            }

            #searchLocation{
                @media (max-width: 500px) {
                    width: 100%;
                }
            }

            #bedroom {
                @media (max-width: 500px) {
                    width: 100%;
                }
            }

        }
    }

    &__process {
        padding: 30px 0 0;

        span {
            border-radius: 50%;
            width: 35px;
            height: 35px;
            color: black;
            border: 3px solid #0B0B0B;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            font-size: 23px;
            font-family: sans-serif;
            font-weight: 900;
            margin-bottom: 15px;
            padding-bottom: 2px;
            padding-left: 1px;
        }
    }

    &__or {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E9E9E9;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        width: 100vw;
        padding: 10px;

        a {
            display: flex;
            align-items: center;
        }

        img {
            max-width: 40px;
            width: 100%;
            margin-right: 10px;
        }
        

        span {
            @media (max-width: 500px){
                width: 180px;
            }
        }
    }

    &__match {
        background-color: #F8F8F8;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        text-align: center;
        padding: 30px;
    }

    &__cities {
        padding: 30px;
        
        p:nth-child(2) {
            max-width: 700px;
            margin: 0 auto;
            width: 100%;
            text-align: center;
        }
    }

    &__help {
        position: relative; 
        height: 550px;

        @media (max-width: 1199px) {
            height: auto;
            margin-bottom: -100px;
        }

        &__bg {
            background-image: url('/assets/images/home/help-banner.png');            
            background-size: 1350px;
            background-position: top left;
            background-repeat: no-repeat;
            display: inline-block;
            max-width: 1920px;
            width: 100vw;
            height: 550px;
            position: absolute;
            left: -125px;
            z-index: -1;

            @media (max-width: 1199px) {
                background-position: center;     
                background-size: cover;
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;               
            }

            @media (max-width: 500px) {
                background-size: cover;                
                height: 400px;
                margin-top: 0;
            }
        }

        &__inner {
            background: white;
            padding: 50px 5px 60px 80px;
            position: absolute;
            top: 45px;
            right: 120px;
            max-width: 700px;
            width: 100%;

            @media (max-width: 1500px) {
                right: 0;
            }

            @media (max-width: 1199px) {
                left: 50%;
                transform: translateX(-50%);
                position: relative;
                top: -100px;
            }

            @media (max-width: 500px) {
                position: relative;
                padding: 30px 10px;
                text-align: center;
            }

            h3 {
                text-align: center;
                max-width: 400px;
                width: 100%;
                margin: 30px auto;
            }

            .btn-wrapper {
                margin-top: 40px;
            }
        }
    }

    &__why {
        padding: 40px 0;

        &__img {
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__title {
            display: inline;
        }

        .btn-fluid {
            @media (max-width: 616px) {
                margin-top: 25px;
            }
        }

        .flexbox {
            justify-content: space-evenly;
            margin-top: 10px;
            margin-bottom: 20px;

            &__each {
                max-width: 310px;
                width: 100%;

                @media (max-width: 616px) {
                    border-bottom: 2px solid #DADADA;

                    &:first-child {
                        border-top: 2px solid #DADADA;
                        margin-top: 20px;
                    }

                }
            }

            h5, p {
            display: inline;
            }

            &__each:first-child {
                img {
                    max-width: 45px;
                    width: 100%;
                }
            }

            &__each:nth-child(2) {
                img {
                    max-width: 45px;
                    width: 100%;
                }
            }

            &__each:nth-child(3) {
                img {
                    max-width: 35px;
                    width: 100%;
                }
            }

            &__each:last-child {
                img {
                    max-width: 33px;
                    width: 100%;
                }
            }

            .accordion{
                display: none;

                @media (max-width: 616px) {
                    display: block;
                    text-align: center;
                }
            }

            .accordion::after {
                display: block;
                content: "+";
                font-size: 25px;
                margin: 5px auto -15px;
            }

            .accordion.active::after {
                display: none;
            }

            .panel {
                display: inline;

                @media (max-width: 616px) {
                    display: none;
                }
            }

            .panel.show {
                @media (max-width: 616px) {
                    display: block;
                }
            }

            .panel-close {
                display: none; 

                @media (max-width: 616px) {
                    display: block;
                }

                &::before {
                    display: block;
                    font-size: 25px;
                    content: "-";
                    transform: scaleX(2);
                    margin: -5px auto -15px;
                }

                &::after {
                    display: none;
                }
            }
      
        }
        
        p:nth-child(2) {
            max-width: 700px;
            margin: 0 auto;
            width: 100%;
            text-align: center;
        }
    }

    &__review {
        position: relative;
        height: 500px;

        @media (max-width: 1199px ){
            height: auto;
            margin-bottom: -100px;
        }

        @media (max-width: 500px ){
            margin-bottom: 0;
        }

        &__quote {
            padding: 10px;

            &::after {
                background-image: url('/assets/images/quote.png');
                background-size: 100%;
                background-repeat: no-repeat;
                display: inline-block;
                width: 50px;
                height: 50px;
                content: "";
            }
        }

        &__inner {
            background: white;
            padding: 70px 50px;
            position: absolute;
            top: 35px;
            left: 140px;
            max-width: 700px;
            width: 100%;
            text-align: center;

            @media (max-width: 1500px) {
                left: 0;
            }

            @media (max-width: 1199px) {
                left: 50%;
                transform: translateX(-50%);
                position: relative;
                top: -100px;
            }

            @media (max-width: 500px) {
                position: relative;
                margin-top: -150px;
                padding: 30px 10px;
                top: 35px;
            }

            h4 {
                max-width: 500px;
                margin: 0 auto;
                width: 100%;
                font-style: italic;
                line-height: 30px;
            }
        }

        &__bg {
            background-image: url('/assets/images/home/review-banner.png');            
            background-size: 1300px;
            background-position: center right;
            background-repeat: no-repeat;
            display: inline-block;
            max-width: 1920px;
            width: 100vw;
            height: 500px;
            z-index: -1;
            position: absolute;
            right: -125px;


            @media (max-width: 1199px) {
                background-position: center; 
                background-size: cover;   
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;                            
            }

            @media (max-width: 500px) {
                background-size: cover;                
                height: 400px;
                margin-top: 0;
            }
        }
    }
}