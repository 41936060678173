.city {
    p:nth-child(2) {
        text-align: center;
        max-width: 900px;
        margin: 0 auto;
        letter-spacing: 0.7px;
        line-height: 32px;
    }

    &__category {

        &__menu {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px 0;

            @media (max-width: 1199px) {
                -ms-overflow-style: none; 
                scrollbar-width: none;  /* Firefox */
                overflow-x: scroll;
                justify-content: end;
                border-top: 2px solid #DFDFDF;
                border-bottom: 2px solid #DFDFDF;
                padding: 10px 0;
                margin-top: 15px;
            }
           

            li {
                margin: 0 30px;

                @media (max-width: 1280px) {
                    margin: 0 15px;
                }

                a {
                    color: #232019;

                    &.active {
                        font-family: 'Montserrat-Bold';                        
                        position: relative;
                        

                        &::after {
                            content: "";
                            width: 70px;
                            height: 3px;
                            background: #FFC85A;
                            display: block;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            margin-top: 5px;
                            top: 20px;

                            @media (max-width: 500px) {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }

            ::-webkit-scrollbar {
                display: none;
              }
        }

        &__content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        &__each {
            display: flex;
            flex-direction: column;
            max-width: 25%;
            width: 100%;
            padding: 10px;

            @media (max-width: 1199px) {
                max-width: 33%;
            }

            @media (max-width: 768px) {
                max-width: 50%;
            }

            

            @media (max-width: 500px) {
                max-width: 100%;
            }

            img {
                width: inherit;
            }



            .text-wrap {
                p:first-child {
                    text-transform: uppercase;
                    margin-top: 15px;
                }

                h6 {
                    font-size: 17px;
                    margin: -10px 0 10px;
                }
                
                p > span {
                    font-family: 'Montserrat-Bold';
                    cursor: pointer;
                }
            }
        }
    }

    #loadmore {

    }

    &__map {
        #map {
            width: 100%;
            height: 600px;
        }
    }

    &__weather {
        padding: 50px 100px;
        background: #F6F6F6;
        margin-top: 50px;

        @media (max-width: 500px) {
            margin-top: 0;
        }
    }

    &__rentals {
        padding: 50px 0;

        @media (max-width: 500px) {
            padding: 30px 0 50px;
        }

        .sub-title {
            text-align: center;
        }
    }

    @media (max-width: 500px){
        h3.sub-title {
            letter-spacing: 0.8px;
            width: 270px;
            margin: 0 auto 30px;
        }

        .questions {
            padding-top: 0;
            margin-top: -20px;
        }

        .line--thick {
            display: none;
        }
    }
}