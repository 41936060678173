.questions {
    padding: 50px 0;     
    text-align: center;

    .btn-wrapper {
        padding: 20px 0;
    }

    .btn {
        border-radius: 0;
    }
}

.find-form {
    background: #f2f2f2;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    display: flex;
    flex-direction: column;
    padding: 50px 20px 30px;
    border-bottom: 2px solid #DFDFDF;

    &__upper {
        display: flex;
        max-width: 1620px;
        width: 96%;
        margin: 0 auto;
        justify-content: space-between;
        // border-bottom: 2px solid #DFDFDF;
        // margin-bottom: 30px;
        // padding-bottom: 30px;

        &__text {
            @media (max-width: 600px) {
                text-align: center;
            }
        }

        form {
            height: fit-content;
        }

        @media (max-width: 1199px) {
            width: auto;
            flex-direction: column;
        }

        @media (max-width: 600px) {
            padding: 0 20px;

            .d-flex {
                flex-direction: column;
            }
        }
    }

    &__lower {
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 17px;
    }

    label {
        display: flex;
        flex-direction: column;

        &:nth-child(2) {
            margin: 0 15px 0 25px;

            @media (max-width: 600px) {
                margin: 0;
            }
        }

        &:last-child {
            flex-direction: column-reverse;

        }
    }


    input[type=text] {
        border: 1px solid #DFDFDF;
        max-width: 240px;
        width: 100%;
        padding: 20px 10px;

        @media (max-width: 600px) {
            max-width: none;
        }
    }

    select {
        border: 1px solid #DFDFDF;
        width: 240px;
        height: 42px;
        background-image: url('/assets/images/dropdown-arrow.svg');
        background-position: 97% 14px;
        

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    input[type=submit] {
        background-color: #FFC85A;
        padding: 9px 40px;
        color: #232019;
        font-family: 'Montserrat-Medium';
        font-size: 16px;

        @media (max-width: 600px) {
            width: 100%;
            margin: 20px 0;
        }
    }
}

.ie-only {
    display: none;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        background-color: #FFC85A;
        color: #000;
        text-align: center;
        margin-top: -10px;
        margin-bottom: 10px;
        padding: 10px;

        &::before {
            content: 'The browser you are using to access this site is unsupported. For a better user experience, please use a modern browser. Thank you.';
        }
    }
}
    
.mob-only{
    display: none !important;
    @media (max-width: 500px) {
      display: block !important;
    } 
}
.desk-only{
    display: block !important;
    @media (max-width: 500px) {
      display: none !important;
    } 
}