
.about {
    width: 100vw;
    margin-left: -20px;
    margin-right: -20px;
    @media (max-width: 500px) {
        margin-left: 0px;
    margin-right: 0px;
    }

   

    .container{
        max-width: 1550px;
        @media (max-width: 1550px) {
            max-width: 1500px;
        }
    }

    .page-title {
        @media (max-width: 500px) {
            padding: 30px 0 0 0;
            margin-bottom: -50px !important;
        }
    }
    
    // &__each {
    //     position: relative;
    //     height: 550px;
        
    //     margin-bottom: 50px;
        
    //     @media (max-width: 1199px) {
    //         height: 880px;
    //         margin-bottom: 100px;
    //     }
    //     @media (max-width: 640px) {
    //         margin-bottom: 110px;
    //     }
    //     @media (max-width: 321px) {
    //         margin-bottom: 330px;
    //     }
    //     &__bg {
    //         position: absolute;
    //         background-size: contain;
    //         background-position: left center;
    //         background-repeat: no-repeat;
    //         display: inline-block;
    //         width: 100%;
    //         height: 570px;
    //         z-index: -1;

    //         &.short-term {
    //             .banner-top{
    //                 width: 80%;
    //                 margin-right: 0;
    //                 display: block;
    //                 margin-left: auto;
    //                 height: 400px;
    //                 object-fit: cover;
    //                 object-position: right;
    //                 @media (max-width: 1200px) {
    //                     display: none;
    //                 }
    //             }
    //             @media (max-width: 1200px) {
    //             background-image: url('/assets/images/about/bg-top.png');   
    //             background-position: right center;
    //             height: 400px;
    //             }
    //             @media (max-width: 450px) {
    //                 background-image: url('/assets/images/about/bg-top-mob.png');   
    //                 height: 310px;
    //             }
    //             @media (max-width: 400px) {
    //                 height: 270px;
    //             }
    //             @media (max-width: 350px) {
    //                 height: 265px;
    //             }
    //             @media (max-width: 321px) {
    //                 height: 235px;
    //             }
               
    //         }

    //         &.vacation {
    //             background-image: url('/assets/images/about/bg-bottom.png');
             
    //             @media (max-width: 500px) {
    //                 background-image: url('/assets/images/about/bg-bottom-mob.png');


    //             }            
    //         }


    //         @media (max-width: 1199px) {
    //             position: relative;
    //             left: 50%;
    //             right: 50%;
    //             margin-left: -50vw;
    //             margin-right: -50vw;
    //             background-size: cover;
    //             width: 100vw;
    //         }

    //         @media (max-width: 500px) {
    //             background-size: 100%;
    //             height: 220px;
    //         }
    //     }


    //     &__text {
    //         position: absolute;
    //         background: white;
    //         padding: 50px;
    //         width: 60%;
    //         right: 0;
    //         top: 50%;
    //         transform: translateY(-50%);

          
    //         @media (max-width: 1199px){
    //             position: relative;
    //             top: -100px;
    //             left: 50%;
    //             transform: translateX(-50%);
    //             padding: 30px;
    //         }

    //         @media (max-width: 768px){
    //             width: 90%;
    //         }


    //         @media (max-width: 500px) {
    //             position: relative;
    //             top: 0;
    //             margin-top: -50px;
    //             padding: 10px 20px;
    //         }

    //         b {
    //             font-family: 'Montserrat-Bold';
    //         }

    //         .accordion {
    //             @media (max-width: 500px) {
    //                 padding-top: 20px;
    //             }
    //         }

    //         .panel {
    //             @media (max-width: 500px) {
    //                 text-align: left;
    //             }
    //         }
    //     }

    //     .short-term-text{
    //         width: 50%;
    //         right: unset;
    //         left: 0;
    //         padding-left: 150px;
    //         @media (max-width: 1540px){
    //             width: 50%;
    //         }
    //         // @media (max-width: 1200px) {
    //         //     width:70%;
    //         // right: 0;
    //         // left: 50%;
    //         // padding-left: unset;
    //         // padding: 30px;

    //         // }
    //         @media (max-width: 1200px) {
    //             // width:90%;
    //             // padding: 10px 20px;
    //             margin: 0 auto;
    //             padding: 2rem 1rem;
    //             margin-top: -70px;
    //             position: relative;
    //             width: 90%;
    //             top: auto;
    //             right: auto;
    //             left: auto;
    //             transform: unset ;
    //         }
    //     }
    // }
    // .short-each{
    //     height: 400px;
    //     @media (max-width: 500px) {
    //         margin-bottom: 50px;
    //     }
    // }

    &__sec1{
        padding: 0 !important;
        position: relative;
        &__bg{
            img{
                width: 75%;
                height: auto;
                margin-right: 0;
                margin-left: auto;
                display: block; 
                min-height: 400px;
                object-fit: cover;
                object-position: right;
                // @media (max-width: 1200px) {
                //     width: 100%;
                //     min-height: 240px;
                // }
                @media (max-width: 769px) {
                    width: 100%;
                    min-height: 240px;
                }
            }
        }
        &__text{
            position: absolute;
            background: #fff;
            width: 45%;
            top: 50%;
            left: 5%;
            // margin-right: -50%;
            transform: translate(0%, -50%);     
            padding: 2rem 4rem;
            @media (max-width: 769px) {
                margin: 0 auto;
                padding: 2rem 1rem;
                margin-top: -70px;
                position: relative;
                width: 90%;
                top: auto;
                left: auto;
                transform: unset ;
              } 
        }
    }

    &__sec2{
        padding: 0 !important;
        position: relative;
        margin-bottom: 3rem;
        &__bg{
            img{
                width: 75%;
                height: auto;
                display: block; 
                min-height: 500px;
                object-fit: cover;
                object-position: center;
                @media (max-width: 1025px) {
                    width: 100%;
                    min-height: 240px;
                }
            }
        }
        &__text{
            position: absolute;
            background: #fff;
            width: 60%;
            top: 50%;
            right: 5%;
            transform: translate(0%, -50%);
            padding: 2rem 4rem;
            @media (max-width: 1025px) {
                margin: 0 auto;
                padding: 2rem 1rem;
                margin-top: -70px;
                position: relative;
                width: 90%;
                top: auto;
                right: auto;
                transform: unset ;
              } 
        }
    }

    &__faq {
        background: #F6F6F6;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        padding-top: 50px;

        @media (max-width: 500px) {
            margin-top: 80px;
        }

        .d-flex {
            @media (max-width: 991px) {
                flex-direction: column;
            }
        }

        &__col {
            @media (max-width: 991px) {
                margin: 0 auto;
                text-align: center;
                border-bottom: 1px solid #979797;
            }

            &:last-child {
                margin-left: 200px;

                @media (max-width: 991px) {
                    margin: 0 auto;
                    border-bottom: none;
                }
            }
        }

        &__each {
            padding: 30px 30px 30px 0px;
            width: 420px;
            border-bottom: 1px solid #979797;
            font-family: 'Montserrat-Bold';

            &:last-child {
                border-bottom: none;
                margin-bottom: 10px;
            }

            @media (max-width: 500px) {
                width: 100%;
            }
        }
    }

    @media (max-width: 500px) {
        .accordion {
            margin-top:1rem;
            // &::before {
            //     background: #F7F7F7;
            //     width: 40px;
            //     height: 40px;
            //     border-radius: 50%;
            //     position: absolute;
            //     top: 0;
            //     left: 50%;
            //     transform: translateX(-50%);
            //     content: "+";
            //     position: absolute;
            //     top: -20px;
            //     left: 50%;
            //     transform: translateX(-50%);
            //     justify-content: center;
            //     align-items: center;
            //     display: flex;
            // }
            &::after{
                bottom: 0;
                font-size: 1.5rem;
                content: "+";
                line-height: 17px;
            }
    
            &.active {    
                // &::before {
                //     content: "-";
                //     transform: translateX(-50%) rotate(180deg);
                // }
                &::after{
                    content: "-";
                }
            }
        }
    
        .panel {
            display: none;
        
            &.show {
                display: block;
            }
        }
        
    }

    &-info{
        text-align: center;
        max-width: 800px;
        margin: 3rem auto;
        @media (max-width: 769px)  {
            margin-top: 0;           
}
        @media (max-width: 500px)  {
                    text-align: left;
        }
    }
    
    &-features{
        border-top: 3px solid #F1F1F1;
        padding: 4rem 0;
        @media (max-width: 500px)  {
            padding: 1rem 0;
        }
        .row{
            // display: flex;
            // align-items: start;
            // justify-content: center;
            // flex-direction: row;
            .about-features-col{
                .card{
                    border: 0;
                    @media (max-width: 500px)  {
                        border-bottom: 3px solid #F1F1F1;
                    }
                    &-title{
                        display: flex;
                        align-items: center;
                        flex-wrap: nowrap;
                                    
                        @media (max-width: 500px) {
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            margin: 1.25rem auto;
                            }
                        img{
                            height: 40px;
                        }
                        p{
                           
                            text-transform: uppercase;
                            text-transform: uppercase;
                            margin: 0 10px;
                            color: #232019;
                            // font-family: Montserrat;
                            font-size: 20px;
                            font-weight: bold;
                            letter-spacing: 0.74px;
                            line-height: 32px;

                            @media (max-width: 500px) {
                                font-size: 21px;
                                font-weight: bold;
                                margin: 10px;
                                line-height: 29px;
                            }
                        }
                    }
                    &-text{
                        @media (max-width: 500px) {
                            text-align: center;
                        }
                    }

                }
            }
        }
    }

    &-awards{
        background: #F6F6F6;
        padding: 4rem 0;   
        border-bottom: 3px solid #F1F1F1;
        @media (max-width: 500px) {
            padding: 2.5rem 0 4rem;   
        }
            &-title{
                text-align: center;
            }
       .carousel-wrap{
           .awards-carousel{
               .owl-item{
                   .item{ 
                       .award-single{
                        background: #fff;
                        height: 210px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        margin: 1.5rem auto;
                        padding: 21px; 
                        max-width: 210px;

                        h6{
                         
                            font-size: 23px;
                            margin-bottom: 9px;
                        }

                        @media (max-width: 1600px) {
                            width: 100%;
                        }
                        @media (max-width: 500px) {
                            // justify-content: center;
                        }
                        p{
                            font-size: 14px;
                            line-height: 20px;
                            display: table;
                        }
                       }
                   }
               }
               .owl-dots{
                @media (max-width: 500px) {
                    flex-wrap: wrap;
                    max-width: 270px;
                    margin: 0 auto;
                }
                .owl-dot{
                    @media (max-width: 500px) {
                        // height: 8px !important;
                    margin: 5px !important;

                    } 
               }
               }
           }
       
       }
    }

    .questions{
        @media (max-width: 769px) {
           padding: 50px 15px;
        }
    }
}