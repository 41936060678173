.find {
    input, 
    label {
        font-size: 14px;
    }

    // input {
    //     padding: 20px 15px;
    // }

    input[type=checkbox],
    input[type=radio] {
        margin: 0 10px 0 30px;
    }

    #pagin {
        @media (max-width: 600px) {
            padding: 15px;
        }
    }

    &__filter {
        position: relative;

        form {
            display: flex;
            flex-direction: column;
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
        }

        &__top {
            order: 0;
            display: flex;
            justify-content: initial;
            align-items: baseline;
            margin-bottom: 10px;

            @media (max-width: 1199px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }


            & > label:first-child > input {
                width: 400px;

                @media (max-width: 1920px) {
                    width: 22vw;
                 }

                @media (max-width: 1350px) {
                   width: 19vw;
                }

                @media (max-width: 1199px) {
                    max-width: 270px;
                    width: 100%;
                }

                @media (max-width: 1199px) {
                    max-width: unset;
                    width: 40vw;
                }

                @media (max-width: 600px) {
                    width: 86vw;
                }
            }
        

            input[type=checkbox],
            input[type=radio] {
                @media (max-width: 1199px) {
                    margin: 0 10px;
                }
            }

            &__date {
                display: flex;
            }

            select {
                padding: 0;
                margin: 0 15px;

                width: 290px;
    
                @media (max-width: 1920px) {
                    width: 15vw;
                 }

                @media (max-width: 1199px) {
                    width: 40vw;
                }

                @media (max-width: 600px) {
                    width: 86vw;
                }
            }

            #checkIn,
            #checkOut {
                width: 290px;
    
                @media (max-width: 1920px) {
                    width: 15vw;
                 }

                @media (max-width: 1199px) {
                    width: 14vw;
                }

                @media (max-width: 1199px) {
                    width: 20vw;
                }

                @media (max-width: 600px) {
                    width: 43vw;
                }
            }

           

            input[type=submit] {
                background-color:#FFC85A;
                color: #000;
                margin-left: 30px;

                @media (max-width: 1199px) {
                    margin-left: 15px;
                    width: auto;
                    padding: 8px 30px;
                }

                @media (max-width: 1199px) {
                    padding: 8px 40px;
                }
            }
        }

        &__tailor {
            order: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            background-color: #F8F8F8;
            border-bottom: 2px solid #DBDBDB;
            padding: 15px 5px;

            @media (max-width: 1199px) {
                flex-direction: column;
                background-color: white;
                padding: 5px;
                border-bottom: none;
            }

            p {
                font-family: 'Montserrat-Medium';

                @media (max-width: 600px) {
                    width: 100%;
                    text-align: center;
                    padding: 0 15px;
                }
            }
        }
      
        &__more {
            display: none;

            &.active {
                order: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100vw;
                position: absolute;
                left: 50%;
                top: 55px;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                background-color: #F8F8F8;
                border-bottom: 2px solid #DBDBDB;
                padding: 20px 30px 10px;
                z-index: 99;

                @media (max-width: 1199px) {
                    padding: 20px;
                }

                @media (max-width: 1199px) {
                    position: relative;
                    top: -50px;
                    margin-bottom: -40px;
                }
            }

            label.checkbox span {
                margin: 0 8px 0 0px;
            }

            .c-row:first-child {
                max-width: 1700px;
                width: 100%;
                justify-content: space-between;

                @media (max-width: 1199px) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    align-items: center;
                }

                @media (max-width: 600px) {
                    flex-direction: column;
                    align-items: baseline;
                    padding-left: 0;
                    padding: 0 10px;
                }

            }

            .c-col {
                margin: 0px 20px 35px;

                @media (max-width: 1199px) {
                    margin: 0 10px 35px;
                }

                @media (max-width: 600px) {
                    margin: 10px 0;
                }

                p {
                    margin-bottom: 10px;
                    font-size: 14px;
                    font-family: 'Montserrat-Semibold';
                    text-transform: uppercase;
                }

                label {
                    font-size: 13px;
                    line-height: 25px;
                    display: flex;
                    align-items: center;
                }

                .c-row {
                    margin-top: 58px;

                    @media (max-width: 600px) {
                        flex-direction: column;
                    }

                    label:first-child {
                        margin-right: 25px;
                    }
                }
            }

            
            &__amentities {
                display: flex;
                align-items: baseline;

                @media (max-width: 600px) {
                    flex-direction: column;
                }

                .c-col {
                    margin: 0 25px 0 0;

                    &:last-child {
                        margin: 0 0 0 50px;

                        @media (max-width: 600px) {
                            margin: 0;
                        }
                    }
                }
            }
           
            input[type=checkbox], 
            input[type=radio] {
                margin: 0 5px 0 0px;
            }

            #budgetSliderLabel {
                margin-bottom: 10px;
                width : fit-content;
                height : 20px;
                line-height : 20px;
                position:relative;
                text-align : center;
                font-size: 14px;
            }
            #budgetSlider {
                border-radius: 9.5px;
                background-color: #FFFFFF;
                box-shadow: inset 0 1px 5px 0 rgba(0,0,0,0.13);
                width: 290px;
                height: 10px;
                border: none;

                @media (max-width: 600px) {
                    width: 75vw;
                }

                &::before {
                    content: "$100/night";
                    position: absolute;
                    top: 23px;
                    color: #8B8B8B;
                    font-size: 13px;
                    left: 0;
                    font-family: 'Montserrat';
                }

                &::after {
                    content: "$500/night";
                    position: absolute;
                    top: 23px;
                    right: 0;
                    color: #8B8B8B;
                    font-size: 13px;
                    font-family: 'Montserrat';
                }


                
                a {
                    left: 0%;
                    width: 50px;
                    border-radius: 9.5px;
                    background-color: rgb(230, 230, 230);
                    box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 5px 0px inset;
                    outline: none;
                }
            }
        }

        &__show{
            border: 1px solid #DFDFDF;
            padding: 7px 10px;
            color: #818181;
            font-size: 13px;
            text-transform: uppercase;
            margin-left: 20px;
            cursor: pointer;

            @media (max-width: 1199px) {
                border: none;
                background: #F8F8F8;
                width: 100vw;
                text-align: center;
                color: black;
                margin: 10px;
            }
    
            &::after {
                content: "V";
                font-size: 13px;
                margin-left: 15px;
                transform: scaleX(1.2) scaleY(0.7);
                font-family: 'Montserrat-SemiBold';
                display: inline-block;
                color: #8B8B8B;
            }
        }

        &__apply {
            input[type=submit] {
                background-color: #FFC85A;
                font-size: 14px;
                text-align: center;
                padding: 8px 15px;
                width: 170px;
                margin-bottom: 1rem;
                text-transform: uppercase;
                cursor: pointer;
                color: #000;
            }
           
        }
    
        &__hide {
            width: 100%;
            color: #818181;
            font-size: 14px;
            text-transform: uppercase;
            margin: 0 auto;
            text-align: center;
            padding: 10px 0 0;
            border-top: 2px solid #DBDBDB;
            cursor: pointer;

            &::after {
                content: "V";
                font-size: 13px;
                margin-left: 15px;
                transform: scaleX(1.2) scaleY(0.7) rotate(180deg);
                font-family: 'Montserrat-SemiBold';
                display: inline-block;
            }

            @media (max-width: 600px) {
                padding: 20px 0 0;
            }
        }
    }



    &__mode {
        display: flex;
        justify-content: space-between;
        align-content: center;
        padding-top: 10px;

        @media (max-width: 1199px) {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            padding-top: 0px;
            margin-top: -10px;
        }

        &__sort {
            display: flex;

            @media (max-width: 1199px) {
                margin-top: -15px;

                label:nth-child(2),
                label:last-child {
                    display: none;
                }
            }

            select {
                @media (max-width: 600px) {
                    width: auto;
                }
            }
        }

        &__view {
            display: flex;
            align-items: center;

            p {
                font-size: 14px;
                line-height: 1.5;
                margin-bottom: 0.5rem;
                font-family: 'Montserrat-Medium';
            }

        }
    }

    &__best {
        background: #F4F4F4;
        padding: 20px 2px 0;

        @media (max-width: 600px) {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
        }

        .sub-title {
            margin-left: 10px;

            @media (max-width: 600px) {
                margin: 0 auto 10px;
                width: auto;
                text-align: center;
            }
        }
    }

    &__contact {
        padding: 10px;
        text-align: center;
        background: #EBEBEB;

        @media (max-width: 600px) {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            text-align: center;
        }
    }

    &__results {
        padding-top: 30px;

        @media (max-width: 600px) {
            padding-top: 10px;
        }
    }

    &__recent {
        padding-top: 30px;
        padding-bottom: 10px;

        @media (max-width: 600px) {
            padding: 30px 0;
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            background: #F4F4F4;
        }
        
        .sub-title {
            text-align: center;
        }
    }
}

.grid-view {
    .find__map {
        display: none;
    }
}

.map-view {
    @media (max-width: 1199px) {
        display: block;

        #map {
            width: 100%;
            height: 500px;
            margin-left: 0;
        }
    }

    @media (min-width: 1024px) {
        display: flex;

        .find__wrapper {
            width: 32%;
            min-width: 490px;

            &__results {
                min-height: 700px;
                max-height: 700px;
                overflow-y: scroll;
                border: 2px solid #F2F2F2;
                border-radius: 0 13px 13px 0;
            }

            #pagin {
                margin-top: 20px;
                padding-bottom: 0;
            }

            .result-lists {
                flex-direction: column;

                &__each {
                    width: 100%;

                    img {
                        max-width: unset;
                    }

                    .carousel-wrap {
                        width: 45%;
                        float: left;
                    }

                    .text-wrap {
                        width: 55%;
                        float: right;
                        padding: 10% 20px 0;

                        div:nth-child(3) {
                            flex-direction: column;
                            align-items: flex-start;
                            margin-bottom: 15px;
                        }

                        h6 {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }

            .fav {
                &::before {
                    width: 30px;
                    height: 30px;
                    top: 10px;
                    margin-right: 20px;
                }

                &::after {
                    display: none;
                }
            }
        }

        .find__map {
            width: 68%;
        }

        #map {
            width: 68%;
            height: auto;
            margin-left: 15px;
        }

        /* width */
            ::-webkit-scrollbar {
                width: 30px;
            }
            
            /* Track */
            ::-webkit-scrollbar-track {
                border: 1px solid #F0F0F0;
                border-radius: 0 13px 13px 0;
                background-color: #F0F0F0;
            }
            
            /* Handle */
            ::-webkit-scrollbar-thumb {
                border-radius: 15px;
                border: 6px solid transparent;
                background-color: #CACACA;
                background-clip: content-box;
                
            }
    }
}

.gm-style-iw-c {
    max-height: 450px !important;
    overflow-y: scroll !important;
    max-width: 300px !important;
    padding: 5px !important;
    border-radius: 0 !important;

    .gm-style-iw-d {
        margin-right: -15px;
    }

    &::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        border: none;
        background-color: #FFFFFF;
        border-radius: 0;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        border: 6px solid transparent;
        background-color: #CACACA;
        background-clip: content-box;
        
    }
    
    .result-lists {
        &__each {
            width: 100%;

            img {
                width: 100% !important;
            }
        }
    }
}

.gm-style-cc {
    display: none;
}

.slider-col {
    width: 300px;
    margin-right: 35px !important;

    @media (max-width: 600px) {
        width: 230px;
    }

    .c-row {
        width: 350px;
    }
}
