h1, h2, h3 {
    font-family: 'Big Caslon Medium', 'Times New Roman', Times, serif;
}

h1 {
    font-size: 37px;
    letter-spacing: 1px;

    @media (max-width: 500px) {
        font-size: 6.5vw;
    }
}

h1.page-title {
    text-align: center;
    padding: 20px 0;
    margin-bottom: 0;

    @media (max-width: 500px) {
        text-align: center;
        margin: 0 auto;
        line-height: 30px;
        padding: 0;
        padding-bottom: 10px;
        width: 250px;
        font-size: 32px;
    }
}

h2 {
    text-align: center;
    font-size: 35px;
    color: #000000;

    @media (max-width: 500px) {
        font-size: 6.5vw;
    }
}

h3 {
    font-size: 27px;
    letter-spacing: 1px;
}

h3.sub-title {
    font-size: 17px;
    font-family: 'Montserrat-Bold';
    text-transform: uppercase;

    @media (max-width: 500px) {
        letter-spacing: 0.8px;
        width: 270px;
        margin: 0 auto 30px;
    }

    &--2 {
        text-transform: initial;
    }

    &.regular {
        font-family: 'Montserrat';
    }
}


h4 {
    font-family: 'Montserrat-MediumItalic';    
    font-size: 17px;
}

h5 {
    font-family: 'Montserrat-Bold';
    font-size: 15px;
    text-transform: uppercase;
}

h6 {
    font-family: 'Montserrat-SemiBold';
    font-size: 15px;
    margin: 5px 0 0 0;
}

p, a {
    font-family: 'Montserrat';
    font-size: 15px;
    line-height: 25px;
}

p {
    color: #232019;
    letter-spacing: 0.7px;
}

a {
    color: #000000;
}

.font-medium {
    font-family: 'Montserrat-Medium' !important;
}

.font-semibold {
    font-family: 'Montserrat-SemiBold' !important;
}

.font-bold {
    font-family: 'Montserrat-Bold' !important;
}

.text-initial {
    text-transform: initial !important;
}