.solution {

    .page-title {
        padding: 40px 0 10px;
        
        @media (max-width: 500px) {
            padding: 30px 0 0 0;
            margin-bottom: 30px !important;
            line-height: 35px;
            font-size: 26px;
            margin-left: -4px;
            margin-right: -4px;
            width: initial;
        }
    }
    
    &__each {
        position: relative;
        height: 550px;
        margin-bottom: 50px;
        
        @media (max-width: 1199px) {
            height: auto;
            margin-bottom: -70px;
        }

        @media (max-width: 500px) {
            margin-bottom: 0;
        }

        &__bg {
            position: absolute;
            background-size: 1050px 100%;
            background-position: left center;
            background-repeat: no-repeat;
            display: inline-block;
            width: 100%;
            height: 550px;
            z-index: -1;

            &.short-term {
                background-image: url('/assets/images/solution/short-term.png');   
                
                @media (max-width: 500px) {
                    background-image: url('/assets/images/solution/short-term-mobile.png');   
                }
            }

            &.vacation {
                background-image: url('/assets/images/solution/vacation.png');
                @media (max-width: 500px) {
                    background-image: url('/assets/images/solution/vacation-mobile.png');


                }            
            }

            &.corporate {
                background-image: url('/assets/images/solution/corporate.png');
                @media (max-width: 500px) {
                    background-image: url('/assets/images/solution/corporate-mobile.png');
                }            
            }

            &.military {
                background-image: url('/assets/images/solution/military.png');
                @media (max-width: 500px) {
                    background-image: url('/assets/images/solution/military-mobile.png');
                }            
            }

            &.entertainment {
                background-image: url('/assets/images/solution/entertainment.png');
                @media (max-width: 500px) {
                    background-image: url('/assets/images/solution/entertainment-mobile.png');
                }            
            }

            &.insurance {
                background-image: url('/assets/images/solution/insurance.png');
                @media (max-width: 500px) {
                    background-image: url('/assets/images/solution/insurance-mobile.png');

                }            
            }

            &.medical {
                background-image: url('/assets/images/solution/medical.png');
                @media (max-width: 500px) {
                    background-image: url('/assets/images/solution/medical-mobile.png');

                }            
            }

            @media (max-width: 1199px) {
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                background-size: cover;
                width: 100vw;
            }

            @media (max-width: 500px) {
                background-size: 100%;
                height: 300px;
            }
        }


        &__text {
            position: absolute;
            background: white;
            padding: 50px;
            width: 70%;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 1199px){
                position: relative;
                top: -100px;
                left: 50%;
                transform: translateX(-50%);
                padding: 30px;
            }

            @media (max-width: 768px){
                width: 100%;
            }


            @media (max-width: 500px) {
                position: relative;
                top: 0;
                margin-top: -50px;
                padding: 10px 20px;
            }

            b {
                font-family: 'Montserrat-Bold';
            }

            .accordion {
                @media (max-width: 500px) {
                    padding-top: 20px;
                }
            }

            .panel {
                @media (max-width: 500px) {
                    text-align: left;
                }
            }
        }
    }

    &__faq {
        background: #F6F6F6;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        padding-top: 50px;

        @media (max-width: 500px) {
            h3 {
                padding: 0 5px;
            }
        }

        .d-flex {
            @media (max-width: 991px) {
                flex-direction: column;
            }
        }

        &__col {
            @media (max-width: 991px) {
                margin: 0 auto;
                text-align: center;
                border-bottom: 1px solid #979797;
            }

            @media (max-width: 500px) {
                margin: 0;
            }

            &:last-child {
                margin-left: 200px;

                @media (max-width: 991px) {
                    margin: 0 auto;
                    border-bottom: none;
                }

                @media (max-width: 500px) {
                    margin: 0;
                }
            }
        }

        &__each {
            padding: 30px 30px 30px 0px;
            width: 420px;
            border-bottom: 1px solid #979797;
            font-family: 'Montserrat-Bold';

            &:last-child {
                border-bottom: none;
                margin-bottom: 10px;

                @media (max-width: 500px) {
                    margin-bottom: 0;

                }
            }

            @media (max-width: 500px) {
                width: 100%;
                padding: 20px;
            }

            .accordion {
                cursor: pointer;

                &::before {
                    display: none;
                }
            }
        
            .panel {
                display: none;
            
                &.show {
                    display: block;
                }
            }
            
        }
    }

    @media (max-width: 500px) {
        .solution__each {
            .accordion {
                &::before {
                    background: #F7F7F7;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    content: url('/assets/images/chevdown.png');
                    position: absolute;
                    top: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
        
                &.active {    
                    &::before {
                        content: url('/assets/images/chevdown.png');
                        transform: translateX(-50%) rotate(180deg);
                    }
                }
            }
        
            .panel {
                display: none;
            
                &.show {
                    display: block;
                }
            }
        }
        
    }
    
}