.nav {
    display: flex;
    flex-direction: column;

    &__top, 
    &__bottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__top li:not(.header__nav__lang) a{
        font-size: 12px;
        color: #777777;
        font-family: 'Montserrat-Bold';
        cursor: pointer;

        @media (min-width: 350px) and (max-width: 500px) {
            font-size: 14px;
        }
    }

    &__top li {
        margin: 0 10px 0 30px;

        @media (max-width: 600px) {
            margin: 0 10px;
        }
    }

    &__bottom {
        padding-top: 30px;

        li a, 
        li span {
            font-size: 15px;
            cursor: pointer;
            margin: 0 10px;
            color: #27241A;
            font-family: 'Montserrat-Medium';
            letter-spacing: 0.7px;
            text-transform: uppercase;

            &.active {
                font-family: 'Montserrat-Bold';
            }
        }
    } 
}