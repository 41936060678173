.contact {
    padding: 20px 0;

    @media (max-width: 1199px) {
        padding: 0 10px;
    }

    &__banner {
        img {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
        }
    }

    &__main {
        position: relative;
        display: flex;

        @media (max-width: 991px) {
            flex-direction: column;
        }
    }

    &__text {
        width: 50%;
        padding-top: 50px;

        @media (max-width: 1600px) {
            padding-bottom: 140px;
        }

        @media (max-width: 1199px) {
            width: 100%;
            padding-bottom: 0;
        }

        @media (max-width: 500px) {
            padding-top: 30px;
        }

        .sub-title {
            @media (max-width: 500px) {
                text-align: left;
                margin: 0;
                font-family: 'Big Caslon Medium', 'Times New Roman', Times, serif;
                font-size: 8vw;
                text-transform: initial;
                margin-bottom: 20px;
            }
        }

        p {
            letter-spacing: 0.7px;
            line-height: 32px;
        }

        .d-flex {
            @media (max-width: 500px) {
                flex-direction: column;
            }
        }

        .icon-chat, .icon-call {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                margin: 0 30px 0 0;
                padding: 10px 20px;
                width: 170px;
                border-radius: 0;

                @media (max-width: 500px) {
                    width: 100%;
                    justify-content: center;
                    margin: 0px 0 10px 0;
                    background: #EBEBEB;
                    text-transform: capitalize;
                    width: 100vw;
                    position: relative;
                    left: 50%;
                    right: 50%;
                    margin-left: -50vw;
                    margin-right: -50vw;
                    padding: 10px;

                    a {
                        color: black;
                        font-family: 'Montserrat-SemiBold';
                        width: 120px;
                        font-size: 15px;
                    }
                }
            
                &::before {
                    background-image: url('/assets/images/contact/icon-chat.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    content: "";
                    margin-right: 10px;
            }
        }

        .icon-call {
            &::before {
                background-image: url('/assets/images/contact/icon-call.png');
            }
        }
    }

    &__form {
        position: absolute;
        width: 45%;
        top: -21vw;
        background: white;
        padding: 50px;
        right: 0;

        @media (max-width: 1199px) {
            position: relative;
            top: 0;
            width: 100%;
            padding: 40px;
            
        }

        @media (max-width: 991px) {
            padding: 40px 0 10px;
        }

        h2 {
            text-align: left;
            margin-bottom: 25px;

            @media (max-width: 500px) {
                text-align: center;
                font-size: 8vw;
                margin-bottom: 20px;
            }
        }

        &__submit {
            input[type=submit]:hover {
                background-color: #d7d7d7;
                color: #818181;
            }

            @media (max-width: 991px) {
                display: flex;
                justify-content: center;
                margin-bottom: 30px;
            }
        }
    }

    form {
        label {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding: 0 20px 15px 0;
            cursor: default;

            @media (max-width: 500px) {
                width: 100%;
            }

            &:last-child {
                width: 100%;

                textarea {
                    height: 150px;
                    margin-top: 15px;
                    border: 1px solid #DFDFDF;
                    outline: none;
                }
            }
        }

        input,select {
            width: 100%;
            height: unset;
            padding: 10px;
            background-position: 97% 19px;
            border: 1px solid #DFDFDF;
            margin-top: 10px;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                padding: 0 5px;
                font-style: italic;
              }
              
        }

        input[type=submit] {
            background-color: #FFC85A;
            color: #000;
            float: right;
            width: 170px;
            font-size: 15px;    
            margin-top: 10px;
        }

        input::placeholder, select {
            font-style: italic;
            font-size: 15px;
            color: #bdbdbd;
            font-family: 'Montserrat';
        }
    }
}