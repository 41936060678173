@charset "UTF-8";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

@font-face {
  font-family: 'Montserrat-ExtraLight';
  src: url("/assets/fonts/Montserrat-ExtraLight.eot");
  src: url("/assets/fonts/Montserrat-ExtraLight.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-ExtraLight.ttf") format("truetype"), url("/assets/fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-MediumItalic';
  src: url("/assets/fonts/Montserrat-MediumItalic.eot");
  src: url("/assets/fonts/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-MediumItalic.ttf") format("truetype"), url("/assets/fonts/Montserrat-MediumItalic.svg#Montserrat-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-ExtraBoldItalic';
  src: url("/assets/fonts/Montserrat-ExtraBoldItalic.eot");
  src: url("/assets/fonts/Montserrat-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype"), url("/assets/fonts/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic") format("svg");
  font-weight: 800;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-Light';
  src: url("/assets/fonts/Montserrat-Light.eot");
  src: url("/assets/fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-Light.ttf") format("truetype"), url("/assets/fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-Thin';
  src: url("/assets/fonts/Montserrat-Thin.eot");
  src: url("/assets/fonts/Montserrat-Thin.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-Thin.ttf") format("truetype"), url("/assets/fonts/Montserrat-Thin.svg#Montserrat-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/assets/fonts/Montserrat-Regular.eot");
  src: url("/assets/fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-Regular.ttf") format("truetype"), url("/assets/fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-BoldItalic';
  src: url("/assets/fonts/Montserrat-BoldItalic.eot");
  src: url("/assets/fonts/Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-BoldItalic.ttf") format("truetype"), url("/assets/fonts/Montserrat-BoldItalic.svg#Montserrat-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-BlackItalic';
  src: url("/assets/fonts/Montserrat-BlackItalic.eot");
  src: url("/assets/fonts/Montserrat-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-BlackItalic.ttf") format("truetype"), url("/assets/fonts/Montserrat-BlackItalic.svg#Montserrat-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-LightItalic';
  src: url("/assets/fonts/Montserrat-LightItalic.eot");
  src: url("/assets/fonts/Montserrat-LightItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-LightItalic.ttf") format("truetype"), url("/assets/fonts/Montserrat-LightItalic.svg#Montserrat-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-Medium';
  src: url("/assets/fonts/Montserrat-Medium.eot");
  src: url("/assets/fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-Medium.ttf") format("truetype"), url("/assets/fonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrcat-Italic';
  src: url("/assets/fonts/Montserrat-Italic.eot");
  src: url("/assets/fonts/Montserrat-Italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-Italic.ttf") format("truetype"), url("/assets/fonts/Montserrat-Italic.svg#Montserrat-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-ThinItalic';
  src: url("/assets/fonts/Montserrat-ThinItalic.eot");
  src: url("/assets/fonts/Montserrat-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-ThinItalic.ttf") format("truetype"), url("/assets/fonts/Montserrat-ThinItalic.svg#Montserrat-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-Black';
  src: url("/assets/fonts/Montserrat-Black.eot");
  src: url("/assets/fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-Black.ttf") format("truetype"), url("/assets/fonts/Montserrat-Black.svg#Montserrat-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-ExtraLightItalic';
  src: url("/assets/fonts/Montserrat-ExtraLightItalic.eot");
  src: url("/assets/fonts/Montserrat-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-ExtraLightItalic.ttf") format("truetype"), url("/assets/fonts/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url("/assets/fonts/Montserrat-ExtraBold.eot");
  src: url("/assets/fonts/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-ExtraBold.ttf") format("truetype"), url("/assets/fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-SemiBoldItalic';
  src: url("/assets/fonts/Montserrat-SemiBoldItalic.eot");
  src: url("/assets/fonts/Montserrat-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-SemiBoldItalic.ttf") format("truetype"), url("/assets/fonts/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-Bold';
  src: url("/assets/fonts/Montserrat-Bold.eot");
  src: url("/assets/fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-Bold.ttf") format("truetype"), url("/assets/fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url("/assets/fonts/Montserrat-SemiBold.eot");
  src: url("/assets/fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Montserrat-SemiBold.ttf") format("truetype"), url("/assets/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: 100%; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
   *  Owl Carousel - Animate Plugin
   */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
   * 	Owl Carousel - Auto Height Plugin
   */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
   * 	Owl Carousel - Lazy Load Plugin
   */
.owl-carousel .owl-item {
  /**
              This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
              calculation of the height of the owl-item that breaks page layouts
           */ }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
   * 	Owl Carousel - Video Plugin
   */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

html {
  overflow-x: hidden; }

body {
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  font-family: 'Montserrat-Medium';
  padding: 10px 20px 0; }
  @media (max-width: 1024px) {
    body {
      overflow-x: hidden; } }
  @media (max-width: 991px) {
    body {
      padding: 0 20px; } }
  @media (max-width: 500px) {
    body {
      padding: 0 10px; } }
  body ::selection {
    background-color: #FFC85A; }

body.body {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Montserrat-Medium';
  padding: 10px 20px 0; }
  @media (max-width: 1024px) {
    body.body {
      overflow-x: hidden; } }
  @media (max-width: 991px) {
    body.body {
      padding: 0 20px; } }
  @media (max-width: 500px) {
    body.body {
      padding: 0 10px; } }
  body.body ::selection {
    background-color: #FFC85A; }

ul {
  list-style: none; }
  ul li {
    margin: 0 10px;
    color: #27241A;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.7px;
    text-transform: uppercase; }

input,
input[type=text],
input[type=date],
input[type=email],
select {
  border: 1px solid #F2F2F2;
  margin-top: 5px;
  padding: 5px;
  outline: none;
  width: 170px;
  height: 31px;
  color: #817F79;
  background: transparent; }
  @media (max-width: 751px) {
    input,
    input[type=text],
    input[type=date],
    input[type=email],
    select {
      width: 32vw; } }
  @media (max-width: 500px) {
    input,
    input[type=text],
    input[type=date],
    input[type=email],
    select {
      width: 100%; } }

select {
  padding: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23AAAAAA'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: 97% 12px;
  background-repeat: no-repeat;
  color: #757575; }

a:hover {
  color: inherit;
  text-decoration: none; }

h1, h2, h3 {
  font-family: 'Big Caslon Medium', 'Times New Roman', Times, serif; }

h1 {
  font-size: 37px;
  letter-spacing: 1px; }
  @media (max-width: 500px) {
    h1 {
      font-size: 6.5vw; } }

h1.page-title {
  text-align: center;
  padding: 20px 0;
  margin-bottom: 0; }
  @media (max-width: 500px) {
    h1.page-title {
      text-align: center;
      margin: 0 auto;
      line-height: 30px;
      padding: 0;
      padding-bottom: 10px;
      width: 250px;
      font-size: 32px; } }

h2 {
  text-align: center;
  font-size: 35px;
  color: #000000; }
  @media (max-width: 500px) {
    h2 {
      font-size: 6.5vw; } }

h3 {
  font-size: 27px;
  letter-spacing: 1px; }

h3.sub-title {
  font-size: 17px;
  font-family: 'Montserrat-Bold';
  text-transform: uppercase; }
  @media (max-width: 500px) {
    h3.sub-title {
      letter-spacing: 0.8px;
      width: 270px;
      margin: 0 auto 30px; } }
  h3.sub-title--2 {
    text-transform: initial; }
  h3.sub-title.regular {
    font-family: 'Montserrat'; }

h4 {
  font-family: 'Montserrat-MediumItalic';
  font-size: 17px; }

h5 {
  font-family: 'Montserrat-Bold';
  font-size: 15px;
  text-transform: uppercase; }

h6 {
  font-family: 'Montserrat-SemiBold';
  font-size: 15px;
  margin: 5px 0 0 0; }

p, a {
  font-family: 'Montserrat';
  font-size: 15px;
  line-height: 25px; }

p {
  color: #232019;
  letter-spacing: 0.7px; }

a {
  color: #000000; }

.font-medium {
  font-family: 'Montserrat-Medium' !important; }

.font-semibold {
  font-family: 'Montserrat-SemiBold' !important; }

.font-bold {
  font-family: 'Montserrat-Bold' !important; }

.text-initial {
  text-transform: initial !important; }

.link {
  color: inherit;
  text-decoration: underline; }
  .link:hover {
    color: inherit; }

.mail-link {
  color: inherit;
  text-decoration: none; }
  .mail-link:hover {
    color: inherit; }

.secret-link {
  color: inherit;
  text-decoration: none;
  word-break: break-word; }
  .secret-link:hover {
    color: inherit;
    text-decoration: none; }

.flexbox {
  display: flex;
  justify-content: center;
  text-align: center; }
  @media (max-width: 991px) {
    .flexbox {
      flex-wrap: wrap; } }
  .flexbox__each {
    padding: 20px; }


.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  background-color: #EEEEEE;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  text-align: center; }

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; }

@media (max-width: 616px) {
  .mobile-only-divider {
    border-top: 2px solid #DBDBDB;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 50px; } }

.line {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 20px; }
  .line--thin {
    border-top: 1px solid #DDDDDD; }
    @media (max-width: 1199px) {
      .line--thin {
        display: none; } }
  .line--medium {
    border-top: 2px solid #DBDBDB; }
  .line--thick {
    border-top: 3px solid #F1F1F1; }
    @media (max-width: 500px) {
      .line--thick {
        border: none; } }

.c-col {
  display: flex;
  flex-direction: column; }

.c-row {
  display: flex; }

.img-hover-zoom {
  overflow: hidden;
  border-radius: 50%; }
  .img-hover-zoom img {
    transition: transform .5s ease; }
  .img-hover-zoom:hover img {
    transform: scale(1.2); }

.greyout-bg {
  display: none; }
  @media (max-width: 1199px) {
    .greyout-bg.active {
      position: fixed;
      display: block;
      height: 100vh;
      width: 100vw;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      background: #ebebeb;
      opacity: 0.9;
      top: 0;
      z-index: 99; } }

.max-width-revert {
  right: 50%;
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }

.max-width-1100 {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto; }

.max-width-700 {
  max-width: 700px;
  width: 100%;
  margin: 0 auto; }

#openWeather svg {
  display: none; }

input[type=submit],
.btn {
  color: #818181;
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  text-align: center;
  background-color: #F7F7F7;
  padding: 8px;
  outline: none;
  border: none;
  width: 155px;
  height: auto;
  margin: 0 12px;
  border-radius: 0; }
  @media (max-width: 500px) {
    input[type=submit],
    .btn {
      padding: 10px; } }
  input[type=submit]:hover,
  .btn:hover {
    background-color: #D7D7D7; }
  input[type=submit] a,
  .btn a {
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    color: #818181;
    text-decoration: none; }

.btn-fluid {
  border-top: 2px solid #DBDBDB;
  border-bottom: 2px solid #DBDBDB;
  color: #4E4E1B;
  text-align: center;
  width: 100%;
  padding: 5px;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }
  .btn-fluid a {
    color: #4E4E1B;
    font-family: 'Montserrat-Medium';
    font-size: 15px;
    text-decoration: none;
    transition: font-size .5s ease; }
    .btn-fluid a:hover {
      font-weight: bold;
      font-size: 16px; }

.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }

.btn-viewAll {
  background: #F8F8F8;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  text-align: center;
  padding: 10px;
  margin-top: 20px; }
  @media (min-width: 501px) {
    .btn-viewAll {
      display: none; } }
  .btn-viewAll a {
    font-family: 'MONTSERRAT-MEDIUM';
    font-size: medium;
    letter-spacing: 0.7px; }

.close-btn {
  display: none; }
  @media (max-width: 1199px) {
    .close-btn {
      display: block; }
      .close-btn::after {
        content: "x";
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 20px;
        font-size: 20px; } }

.item {
  text-align: center;
  margin: 0 10px; }

.owl-nav {
  display: flex;
  justify-content: space-between;
  width: 70px;
  margin: 0 auto; }
  @media (min-width: 1101px) {
    .owl-nav {
      display: none; } }

#processCarousel {
  padding: 30px 0; }
  #processCarousel .item {
    max-width: 320px; }
  #processCarousel .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center; }
    #processCarousel .owl-dots .owl-dot {
      margin: 10px;
      transform: translateY(-5px);
      opacity: 0.38; }
      #processCarousel .owl-dots .owl-dot.active {
        opacity: 1;
        transform: scale(1.7);
        margin: 0 20px; }
      #processCarousel .owl-dots .owl-dot:first-child > span::after {
        content: "1"; }
      #processCarousel .owl-dots .owl-dot:nth-child(2) > span::after {
        content: "2"; }
      #processCarousel .owl-dots .owl-dot:nth-child(3) > span::after {
        content: "3"; }
      #processCarousel .owl-dots .owl-dot:last-child > span::after {
        content: "···"; }

@media (max-width: 500px) {
  #processCarousel {
    display: flex;
    flex-direction: column; }
    #processCarousel .owl-stage-outer {
      order: 1; }
    #processCarousel .owl-nav {
      order: 2; }
    #processCarousel .owl-dots {
      order: 0; }
    #processCarousel .item span {
      display: none; } }

#citiesCarousel .item,
#citiesMobileCarousel .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px; }
  @media (max-width: 600px) {
    #citiesCarousel .item,
    #citiesMobileCarousel .item {
      flex-wrap: wrap;
      flex-direction: row;
      padding: 0; } }
  #citiesCarousel .item h5,
  #citiesMobileCarousel .item h5 {
    text-transform: capitalize;
    padding: 20px;
    font-size: 17px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.7px; }
    @media (max-width: 600px) {
      #citiesCarousel .item h5,
      #citiesMobileCarousel .item h5 {
        padding: 5px 0; } }

#citiesCarousel .owl-nav {
  display: block; }
  #citiesCarousel .owl-nav .prev-grey {
    position: absolute;
    left: -30px;
    top: 35%; }
  #citiesCarousel .owl-nav .next-grey {
    position: absolute;
    right: -30px;
    top: 35%; }

#citiesCarousel .owl-item img {
  max-width: 150px; }

@media (max-width: 600px) {
  #citiesCarousel {
    display: none; } }

@media (min-width: 600px) {
  #citiesMobileCarousel {
    display: none; } }

#citiesMobileCarousel .item__wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 50%;
  padding: 0; }
  #citiesMobileCarousel .item__wrapper img {
    width: 85%; }

#citiesMobileCarousel .owl-nav {
  padding: 15px 0; }

#reviewCarousel .owl-nav,
#propertyReviewCarousel .owl-nav,
#propertyAmenitiesCarousel .owl-nav,
#awards-carousel .owl-nav,
#blog-carousel .owl-nav {
  display: none; }

#reviewCarousel .owl-dots,
#propertyReviewCarousel .owl-dots,
#propertyAmenitiesCarousel .owl-dots,
#awards-carousel .owl-dots,
#blog-carousel .owl-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: -20px; }
  @media (max-width: 500px) {
    #reviewCarousel .owl-dots,
    #propertyReviewCarousel .owl-dots,
    #propertyAmenitiesCarousel .owl-dots,
    #awards-carousel .owl-dots,
    #blog-carousel .owl-dots {
      margin-top: 20px; } }

#reviewCarousel .owl-dot,
#propertyReviewCarousel .owl-dot,
#propertyAmenitiesCarousel .owl-dot,
#awards-carousel .owl-dot,
#blog-carousel .owl-dot {
  border: 1.7px solid #A5A5A5;
  background-color: #EAEAEA;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  margin: 0 5px; }
  #reviewCarousel .owl-dot.active,
  #propertyReviewCarousel .owl-dot.active,
  #propertyAmenitiesCarousel .owl-dot.active,
  #awards-carousel .owl-dot.active,
  #blog-carousel .owl-dot.active {
    background-color: #FFC85A;
    border: 1.7px solid #636363; }

#propertyAmenitiesCarousel .owl-dots {
  margin-top: 0;
  margin-bottom: 20px; }

.prev-mobile::before {
  background-image: url("/assets/images/prev-mobile.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 25px;
  height: 25px;
  content: "";
  vertical-align: bottom;
  margin-right: 5px; }

.next-mobile::before {
  background-image: url("/assets/images/next-mobile.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 25px;
  height: 25px;
  content: "";
  vertical-align: bottom;
  margin-right: 5px; }

.prev-grey::before {
  background-image: url("/assets/images/prev-grey.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 25px;
  height: 25px;
  content: "";
  vertical-align: bottom;
  margin-right: 5px; }

.next-grey::before {
  background-image: url("/assets/images/next-grey.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 25px;
  height: 25px;
  content: "";
  vertical-align: bottom;
  margin-right: 5px; }

.prev-white::before {
  background-image: url("/assets/images/property/prev.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 30px;
  height: 30px;
  content: "";
  vertical-align: bottom;
  margin-right: 5px; }

.next-white::before {
  background-image: url("/assets/images/property/next.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 30px;
  height: 30px;
  content: "";
  vertical-align: bottom;
  margin-right: 5px; }

.fav::before {
  background-image: url("/assets/images/fav-empty.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 23px;
  height: 23px;
  content: "";
  position: absolute;
  top: 13px;
  right: 0;
  margin-right: 11px;
  z-index: 2; }
  @media (max-width: 500px) {
    .fav::before {
      top: -5px;
      margin-right: -5px; } }

.fav::after {
  background-image: url("/assets/images/triangle.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 50px;
  height: 50px;
  content: "";
  margin-top: -5px;
  position: absolute;
  top: 15px;
  right: 0;
  margin-right: 10px;
  z-index: 1; }
  @media (max-width: 500px) {
    .fav::after {
      display: none; } }

.fav.active::before {
  background-image: url("/assets/images/fav.png"); }

.star {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px; }
  @media (max-width: 500px) {
    .star {
      margin-top: 4px;
      margin-left: 15px; } }
  .star::before {
    background-image: url("/assets/images/star.png");
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 14px;
    height: 14px;
    content: "";
    margin-right: 3px; }

.chat {
  display: flex;
  align-items: center; }
  .chat::before {
    background-image: url("/assets/images/property/icon-chat.png");
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 40px;
    height: 40px;
    content: "";
    margin-right: 10px; }
    @media (max-width: 1199px) {
      .chat::before {
        width: 25px;
        height: 25px; } }

.icon-grid-view {
  opacity: 0.5; }
  .icon-grid-view::before {
    background-image: url("/assets/images/grid-view.png");
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 30px;
    height: 25px;
    content: "";
    margin: 10px 12px 10px 23px;
    cursor: pointer; }
  .icon-grid-view.active {
    opacity: 1; }

.icon-map-view {
  opacity: 0.5; }
  .icon-map-view::before {
    background-image: url("/assets/images/map-view.png");
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 30px;
    height: 25px;
    content: "";
    cursor: pointer; }
  .icon-map-view.active {
    opacity: 1; }

.fav-local {
  position: relative; }
  .fav-local::before {
    content: "Local's Favorite";
    position: absolute;
    right: 0;
    margin: 0px 10px;
    font-size: 14px;
    background: white;
    opacity: 0.9;
    color: #7D796E;
    text-align: center;
    padding: 5px 10px; }

.icon-bed::before {
  background-image: url("/assets/images/property/icon-bed.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 25px;
  height: 20px;
  content: "";
  margin-right: 10px; }

.icon-bath::before {
  background-image: url("/assets/images/property/icon-bath.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: "";
  margin-right: 10px; }

.icon-person::before {
  background-image: url("/assets/images/property/icon-user.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: "";
  margin-right: 10px; }

.icon-pet::before {
  background-image: url("/assets/images/property/icon-paw.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 20px;
  content: "";
  margin-right: 10px; }

.icon-calendar::before {
  background-image: url("/assets/images/property/icon-calender.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 21px;
  height: 20px;
  content: "";
  margin-right: 10px; }

.icon-clock::before {
  background-image: url("/assets/images/property/icon-clock.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 20px;
  height: 22px;
  content: "";
  margin-top: 6px;
  margin-right: 10px; }

.icon-nosmoking::before {
  background-image: url("/assets/images/property/icon-nosmoking.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 25px;
  height: 20px;
  content: "";
  margin-top: 6px;
  margin-right: 10px; }

.icon-wifi::before {
  background-image: url("/assets/images/property/icon-wifi.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 29px;
  height: 22px;
  content: "";
  margin-top: 4px;
  margin-right: 10px; }

.icon-queen::before {
  background-image: url("/assets/images/property/icon-queen.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 29px;
  height: 22px;
  content: "";
  margin-top: 4px;
  margin-right: 10px; }

.icon-laundry::before {
  background-image: url("/assets/images/property/icon-laundry.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 21px;
  height: 28px;
  content: "";
  margin-left: 3px;
  margin-top: 4px;
  margin-right: 16px; }

.icon-phone::before {
  background-image: url("/assets/images/property/icon-telephone.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 22px;
  height: 22px;
  content: "";
  margin-top: 5px;
  margin-right: 16px;
  margin-left: 3px; }

.icon-dishwasher::before {
  background-image: url("/assets/images/property/icon-dishwasher.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 24px;
  height: 22px;
  content: "";
  margin-left: 2px;
  margin-top: 6px;
  margin-right: 15px; }

.icon-pool::before {
  background-image: url("/assets/images/property/icon-pool.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 24px;
  height: 22px;
  content: "";
  margin-left: 2px;
  margin-top: 6px;
  margin-right: 15px; }

.icon-kid::before {
  background-image: url("/assets/images/property/icon-kid.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 21px;
  height: 28px;
  content: "";
  margin-left: 4px;
  margin-top: 0px;
  margin-right: 16px; }

.icon-tv::before {
  background-image: url("/assets/images/property/icon-tv.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 22px;
  height: 23px;
  content: "";
  margin-left: 3px;
  margin-top: 6px;
  margin-right: 15px; }

.icon-parking::before {
  background-image: url("/assets/images/property/icon-parking.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 23px;
  height: 22px;
  content: "";
  margin-left: 2px;
  margin-top: 6px;
  margin-right: 15px; }

.icon-microwave::before {
  background-image: url("/assets/images/property/icon-microwave.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 25px;
  height: 22px;
  content: "";
  margin-top: 8px;
  margin-right: 15px; }

.icon-oval::before {
  background-image: url("/assets/images/property/icon-oval.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 21px;
  height: 21px;
  content: "";
  margin-top: 6px;
  margin-right: 15px; }

.icon-dryer::before {
  background-image: url("/assets/images/property/icon-dryer.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 30px;
  height: 28px;
  content: "";
  margin-top: 0px;
  margin-right: 7px; }

.icon-iron::before {
  background-image: url("/assets/images/property/icon-iron.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 25px;
  height: 20px;
  content: "";
  margin-top: 8px;
  margin-right: 13px; }

.icon-safety::before {
  content: url("/assets/images/why/safety.png");
  margin-right: 20px; }

.icon-cleaning::before {
  content: url("/assets/images/why/cleaning.png");
  margin-right: 20px; }

.icon-laundry-why::before {
  content: url("/assets/images/why/laundry.png");
  margin-right: 20px; }

.icon-tea::before {
  content: url("/assets/images/why/tea.png");
  margin-right: 20px; }

.icon-location::before {
  content: url("/assets/images/why/location.png");
  margin-right: 15px;
  margin-left: 5px; }

.icon-clean::before {
  content: url("/assets/images/why/clean.png");
  margin-right: 20px; }

.icon-workspace::before {
  content: url("/assets/images/why/workspace.png");
  margin-right: 20px; }

.icon-towel::before {
  content: url("/assets/images/why/towel.png");
  margin-right: 20px; }

.icon-bed-why::before {
  content: url("/assets/images/why/bed.png");
  margin-right: 20px; }

.icon-star::before {
  content: url("/assets/images/why/star.png");
  margin-right: 30px;
  margin-left: -10px; }

.icon-wifi-why::before {
  content: url("/assets/images/why/wifi.png");
  margin-right: 20px; }

.icon-chat::before {
  content: url("/assets/images/why/chat.png");
  margin-right: 20px; }

.icon-bubble::before {
  content: url("/assets/images/why/bubble.png");
  margin-right: 20px; }

.nav {
  display: flex;
  flex-direction: column; }
  .nav__top, .nav__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .nav__top li:not(.header__nav__lang) a {
    font-size: 12px;
    color: #777777;
    font-family: 'Montserrat-Bold';
    cursor: pointer; }
    @media (min-width: 350px) and (max-width: 500px) {
      .nav__top li:not(.header__nav__lang) a {
        font-size: 14px; } }
  .nav__top li {
    margin: 0 10px 0 30px; }
    @media (max-width: 600px) {
      .nav__top li {
        margin: 0 10px; } }
  .nav__bottom {
    padding-top: 30px; }
    .nav__bottom li a,
    .nav__bottom li span {
      font-size: 15px;
      cursor: pointer;
      margin: 0 10px;
      color: #27241A;
      font-family: 'Montserrat-Medium';
      letter-spacing: 0.7px;
      text-transform: uppercase; }
      .nav__bottom li a.active,
      .nav__bottom li span.active {
        font-family: 'Montserrat-Bold'; }

.dropdown {
  position: relative;
  display: inline-block; }
  @media (max-width: 1199px) {
    .dropdown {
      display: contents; } }
  .dropdown span {
    cursor: pointer; }
    @media (max-width: 1199px) {
      .dropdown span {
        padding: 10px; } }
    .dropdown span::after {
      content: "v";
      display: inline-block;
      -webkit-transform: scaleX(1.4) scaleY(0.5);
      -moz-transform: scaleX(1.4) scaleY(0.5);
      -o-transform: scaleX(1.4) scaleY(0.5);
      -ms-transform: scaleX(1.4) scaleY(0.5);
      transform: scaleX(1.4) scaleY(0.5);
      font-family: 'Montserrat-Bold';
      color: #27241A;
      font-size: 10px;
      margin-left: 5px; }

.dropdown-content {
  display: none;
  position: absolute;
  width: max-content;
  padding: 12px 16px;
  z-index: 99;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%); }
  .dropdown-content.show {
    display: block; }
  @media (max-width: 1199px) {
    .dropdown-content.w-auto {
      width: 100% !important; } }
  .dropdown-content.w-auto a {
    margin-left: 0; }
  .dropdown-content ul {
    padding-left: 0; }
    .dropdown-content ul li {
      padding: 10px 35px 10px;
      background: #f9f9f9; }
      .dropdown-content ul li:hover {
        background: #F1F0F0; }
      .dropdown-content ul li a {
        text-transform: capitalize;
        font-family: 'Montserrat-Semibold';
        letter-spacing: 0;
        font-size: 15px; }
  .dropdown-content.clicked {
    display: block; }
  @media (max-width: 1199px) {
    .dropdown-content {
      position: relative;
      width: 100%;
      text-align: center; } }

@media (min-width: 1200px) {
  .dropdown:hover .dropdown-content {
    display: block; } }

#matchCarousel .item {
  min-height: 280px;
  width: 100%;
  max-width: 1350px;
  margin: 0 auto; }
  @media (max-width: 1400px) {
    #matchCarousel .item {
      padding: 0 25px; } }
  @media (max-width: 1280px) {
    #matchCarousel .item {
      min-height: 365px; } }
  @media (max-width: 500px) {
    #matchCarousel .item {
      min-height: 320px; } }

#matchCarousel .slider-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 160px; }
  @media (max-width: 1280px) {
    #matchCarousel .slider-title {
      flex-direction: column;
      height: auto; } }
  #matchCarousel .slider-title::before {
    background-image: url("/assets/images/home/plane.png");
    background-size: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    max-height: 150px;
    height: 30vw;
    max-width: 400px;
    content: "";
    position: absolute;
    left: -150px;
    top: 0; }
    @media (max-width: 1280px) {
      #matchCarousel .slider-title::before {
        position: relative;
        left: 0;
        margin-bottom: 15px; } }
  #matchCarousel .slider-title::after {
    background-image: url("/assets/images/home/local.png");
    background-size: 100%;
    background-repeat: no-repeat;
    display: block;
    width: 200px;
    height: 150px;
    content: "";
    position: absolute;
    right: -100px;
    top: 0; }
    @media (max-width: 1280px) {
      #matchCarousel .slider-title::after {
        display: none; } }
  #matchCarousel .slider-title p {
    font-family: 'Montserrat-SemiBold'; }
    @media (max-width: 1280px) {
      #matchCarousel .slider-title p {
        margin-bottom: 35px; } }

#matchCarousel .owl-nav {
  max-width: 1470px;
  width: 100%;
  padding-right: 20px;
  display: flex !important;
  font-family: 'Montserrat-SemiBold'; }
  @media (max-width: 1400px) {
    #matchCarousel .owl-nav {
      padding-right: 0;
      max-width: 991px; } }

.slider-prev {
  display: flex;
  justify-content: center;
  align-items: center; }
  .slider-prev::before {
    background-image: url("/assets/images/prev-thin.png");
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
    content: "";
    margin-right: 5px; }

.slider-next {
  display: flex;
  justify-content: center;
  align-items: center; }
  .slider-next::after {
    background-image: url("/assets/images/next-thin.png");
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
    content: "";
    margin-left: 5px; }

.slider {
  height: 2px; }
  .slider::before {
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid #979797;
    background-color: #D8D8D8;
    border-radius: 50%;
    position: absolute;
    left: -8px;
    top: -7px; }
  .slider::after {
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid #979797;
    background-color: #D8D8D8;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: -7px; }
  .slider .ui-slider-handle {
    background-image: url("/assets/images/slider-handle.png");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    top: -15px;
    border: none;
    background-color: transparent;
    outline: none; }
  .slider label {
    position: absolute;
    width: 20px;
    margin-left: -10px;
    text-align: center;
    margin-top: 20px; }
    @media (max-width: 500px) {
      .slider label {
        visibility: hidden; } }
    .slider label:nth-child(2)::after {
      content: "Not familiar at all with my destination";
      width: 150px;
      font-size: 15px;
      left: -65px;
      position: absolute;
      display: block;
      top: 30px; }
      @media (max-width: 1400px) {
        .slider label:nth-child(2)::after {
          left: -13px;
          width: 11vw;
          min-width: 110px;
          text-align: left; } }
      @media (max-width: 500px) {
        .slider label:nth-child(2)::after {
          visibility: visible;
          top: 0; } }
    .slider label:last-child::after {
      content: "I know it like the back of my hand";
      width: 150px;
      font-size: 15px;
      left: -65px;
      position: absolute;
      top: 30px; }
      @media (max-width: 1400px) {
        .slider label:last-child::after {
          left: unset;
          right: -13px;
          width: 11vw;
          min-width: 100px;
          text-align: right; } }
      @media (max-width: 500px) {
        .slider label:last-child::after {
          visibility: visible;
          top: 0;
          width: 11vw; } }

.result-lists {
  display: flex;
  flex-wrap: wrap; }
  .result-lists__each:not(.property) {
    padding: 10px;
    width: 16.6%;
    margin-bottom: 20px;
    position: relative; }
    @media (max-width: 1199px) {
      .result-lists__each:not(.property) {
        width: 20%; } }
    @media (max-width: 900px) {
      .result-lists__each:not(.property) {
        width: 25%; } }
    @media (max-width: 767px) {
      .result-lists__each:not(.property) {
        width: 33%; } }
    @media (max-width: 500px) {
      .result-lists__each:not(.property) {
        width: 100%;
        margin-bottom: 0; } }
    @media (max-width: 500px) {
      .result-lists__each:not(.property) .carousel-wrap .owl-carousel {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        border-top: 2px solid #DBDBDB;
        padding: 20px 0 10px; } }
    .result-lists__each:not(.property) .carousel-wrap .owl-stage {
      display: flex;
      justify-content: center;
      align-items: center; }
    .result-lists__each:not(.property) .carousel-wrap .owl-item {
      min-width: 130px !important; }
    .result-lists__each:not(.property) .carousel-wrap .item {
      margin: 0; }
    .result-lists__each:not(.property) .carousel-wrap img {
      width: 100%;
      max-width: 255px; }
      @media (max-width: 500px) {
        .result-lists__each:not(.property) .carousel-wrap img {
          width: 130px; } }
    .result-lists__each:not(.property) .carousel-wrap .owl-nav {
      display: flex; }
      .result-lists__each:not(.property) .carousel-wrap .owl-nav .thumbnail-prev::before {
        content: "<";
        font-size: 25px;
        color: white;
        display: flex;
        position: absolute;
        top: 40%;
        left: 15px;
        width: 35px;
        height: 40px; }
      .result-lists__each:not(.property) .carousel-wrap .owl-nav .thumbnail-next::before {
        content: ">";
        font-size: 25px;
        color: white;
        display: flex;
        position: absolute;
        top: 40%;
        right: 0;
        width: 35px;
        height: 40px; }
    .result-lists__each:not(.property) .carousel-wrap .thumbnail-next,
    .result-lists__each:not(.property) .carousel-wrap .thumbnail-prev {
      opacity: 0;
      transition: all 0.5s ease; }
    .result-lists__each:not(.property) .carousel-wrap .owl-carousel:hover .thumbnail-next,
    .result-lists__each:not(.property) .carousel-wrap .owl-carousel:hover .thumbnail-prev {
      opacity: 1;
      transition: all 0.5s ease; }
    .result-lists__each:not(.property) .carousel-wrap .owl-dots {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      display: flex; }
      .result-lists__each:not(.property) .carousel-wrap .owl-dots .owl-dot {
        height: 8px;
        width: 8px;
        opacity: 0.54;
        background-color: #EFEFEF;
        border-radius: 50%;
        border: 1px solid transparent;
        margin: 2px; }
        .result-lists__each:not(.property) .carousel-wrap .owl-dots .owl-dot.active {
          opacity: 1; }
    @media (max-width: 500px) {
      .result-lists__each:not(.property) .text-wrap {
        position: relative; } }
    .result-lists__each:not(.property) .text-wrap div:nth-child(3) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-family: 'Montserrat-Medium';
      text-transform: uppercase;
      font-size: 13px; }
      @media (max-width: 500px) {
        .result-lists__each:not(.property) .text-wrap div:nth-child(3) {
          justify-content: left;
          align-items: center; } }
    .result-lists__each:not(.property) .text-wrap p {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 12px;
      margin: 0 0 -5px 0; }
      .result-lists__each:not(.property) .text-wrap p span {
        font-family: 'Montserrat-Bold'; }

@media (max-width: 500px) {
  .result-lists > .result-lists__each:first-child > .carousel-wrap > .owl-carousel {
    border-top: none;
    margin-top: -20px; } }

.units::after {
  content: " units"; }

label.checkbox {
  display: flex;
  align-items: center; }

label.checkbox input[type=checkbox],
label.checkbox input[type=radio] {
  display: none; }

label.checkbox span {
  display: inline-block;
  border: 2px solid #A5A5A5;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #fff;
  vertical-align: middle;
  margin: 0 5px 0 20px;
  position: relative;
  transition: width 0.1s, height 0.1s, margin 0.1s; }

label.checkbox :checked + span {
  background: #FFC85A;
  width: 15px;
  height: 15px; }

#ajax-pagination {
  padding: 20px 0; }
  #ajax-pagination ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline-start: 0; }
    #ajax-pagination ul li {
      cursor: pointer; }
      #ajax-pagination ul li.active {
        font-weight: 700;
        font-size: 110%; }
      #ajax-pagination ul li:first-child::after {
        content: "|";
        margin: 0 -10px 0 10px; }
      #ajax-pagination ul li:first-child:last-child::after {
        content: ""; }
      #ajax-pagination ul li:first-child.active {
        font-weight: bold; }
        #ajax-pagination ul li:first-child.active::after {
          font-weight: normal; }

#content {
  padding: 10px 15px 0px; }

.gm-style h1 {
  font-family: 'Montserrat-Bold';
  font-size: 18px; }

.gm-style p {
  line-height: 25px !important;
  font-size: 16px;
  text-align: left !important; }

.gm-style a {
  font-weight: bold; }

button.gm-ui-hover-effect {
  top: 4px !important; }

.header {
  display: flex;
  justify-content: space-between;
  z-index: 99; }
  .header__logo {
    display: flex;
    justify-content: center;
    align-items: flex-end; }
    .header__logo img {
      max-width: 200px;
      width: 100%; }
  .header__nav__lang a {
    color: #777777; }
  .header__nav__lang a.active {
    font-family: 'Montserrat-Bold'; }
  .header__nav__contact {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 500px) {
      .header__nav__contact {
        left: 50%;
        transform: translateX(-60%);
        position: absolute; } }
    .header__nav__contact::before {
      background-image: url("/assets/images/contact/icon-call.png");
      background-size: 100%;
      background-repeat: no-repeat;
      display: inline-block;
      width: 25px;
      height: 25px;
      content: "";
      margin-right: 5px; }
  .header__nav__saved {
    display: flex;
    justify-content: center;
    align-items: center; }
    .header__nav__saved::before {
      background-image: url("/assets/images/property/icon-fav.png");
      background-size: 100%;
      background-repeat: no-repeat;
      display: inline-block;
      width: 25px;
      height: 25px;
      content: "";
      margin-right: 5px; }
  @media (max-width: 1199px) {
    .header {
      flex-wrap: wrap;
      background: #F2F2F2;
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw; }
      .header__logo {
        text-align: center;
        width: 100vw;
        background: white;
        padding: 15px 0; }
      .header__nav__lang {
        order: 2; }
      .header__nav__contact {
        order: 0; }
      .header .nav__top {
        padding: 0 20px;
        margin-bottom: 12px;
        margin-top: 10px; }
      .header .nav__bottom {
        position: absolute;
        width: 100%;
        left: 0;
        top: 121px;
        justify-content: initial;
        flex-direction: column;
        background: #ffffff;
        padding: 20px;
        transform: translateX(-100%);
        opacity: 0;
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; }
        .header .nav__bottom li {
          padding: 10px; }
        .header .nav__bottom.show {
          transform: translateX(0);
          opacity: 0.96;
          transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
          z-index: 99; } }
  @media (max-width: 500px) {
    .header .header__nav__saved {
      display: none; }
    .header .nav__top {
      padding: 0 5px; } }

.hamburger-menu {
  padding: 5px 20px 0; }
  @media (min-width: 1199px) {
    .hamburger-menu {
      display: none; } }

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: black;
  border-radius: 10px;
  margin: 6px 0;
  transition: 0.4s; }

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-7px, 6px);
  transform: rotate(-45deg) translate(-7px, 6px); }

.change .bar2 {
  opacity: 0; }

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-9px, -9px);
  transform: rotate(45deg) translate(-9px, -9px); }

.g-header__bg {
  background: #F4F4F4;
  height: 50px;
  width: 100vw;
  position: absolute;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  top: 0;
  z-index: -1; }

.home-header .g-header__bg {
  display: none; }

.footer a:hover {
  opacity: 0.6; }

.footer__contact {
  margin: 10px;
  min-width: fit-content;
  width: 40%; }
  .footer__contact--each {
    display: flex;
    align-items: center; }
    .footer__contact--each:first-child img {
      width: 30px;
      margin-right: 10px; }
    .footer__contact--each:nth-child(2) img {
      width: 20px;
      margin: 5px 15px 5px 5px; }
    .footer__contact--each:nth-child(3) img {
      width: 25px;
      margin-right: 12px;
      margin-left: 2px; }
    .footer__contact--each:last-child {
      margin-top: 30px; }
      @media (max-width: 500px) {
        .footer__contact--each:last-child {
          margin-top: 10px; } }
      .footer__contact--each:last-child img {
        width: 30px;
        margin: 0 5px; }
    @media (max-width: 500px) {
      .footer__contact--each:not(:last-child) {
        display: none; } }

.footer__upper {
  display: flex;
  justify-content: space-between;
  max-width: 1700px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  padding: 30px 20px; }
  @media (max-width: 500px) {
    .footer__upper {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center; } }

.footer__menu {
  display: flex;
  width: 75%;
  justify-content: initial;
  flex-wrap: wrap; }
  @media (max-width: 500px) {
    .footer__menu {
      flex-direction: column;
      text-align: center; } }
  .footer__menu__each {
    display: flex;
    flex-direction: column;
    margin: 10px 30px; }
    @media (max-width: 500px) {
      .footer__menu__each {
        margin: 10px 0; } }

.footer .newsletter-form {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 500px) {
    .footer .newsletter-form {
      flex-direction: column; } }
  .footer .newsletter-form--fields {
    display: flex; }
  @media (max-width: 500px) {
    .footer .newsletter-form--submit input[type=submit] {
      padding: 7px 40px; } }

.footer__copyright {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: small; }
  @media (max-width: 500px) {
    .footer__copyright {
      flex-direction: column;
      color: #8a8a8a; }
      .footer__copyright a {
        color: #8a8a8a; } }
  @media (max-width: 500px) {
    .footer__copyright--right {
      margin-top: 20px;
      font-size: 14px; } }
  .footer__copyright--right img:first-child {
    height: 25px;
    margin-left: 20px;
    margin-right: 10px; }
  .footer__copyright--right img:last-child {
    height: 35px;
    margin-top: -5px; }
  .footer__copyright--left {
    font-size: 14px; }
    @media (max-width: 600px) {
      .footer__copyright--left {
        text-align: center; } }
    .footer__copyright--left a {
      font-size: 14px; }

.footer label {
  margin: 0; }

.footer input[type=email], .footer select {
  background: transparent;
  border: 1px solid #C7C7C7;
  font-size: small;
  width: 100%;
  height: 32px; }

.footer input[type=email] {
  font-style: italic; }

.footer select {
  width: 100px;
  padding: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23AAAAAA'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: 90% 12px;
  background-repeat: no-repeat;
  color: #757575;
  border-radius: 0; }

.footer input[type=submit] {
  background-color: #8E8E8E;
  color: #ffffff;
  width: auto;
  padding: 7px 10px;
  margin: 5px 0 0; }

.questions {
  padding: 50px 0;
  text-align: center; }
  .questions .btn-wrapper {
    padding: 20px 0; }
  .questions .btn {
    border-radius: 0; }

.find-form {
  background: #f2f2f2;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  flex-direction: column;
  padding: 50px 20px 30px;
  border-bottom: 2px solid #DFDFDF; }
  .find-form__upper {
    display: flex;
    max-width: 1620px;
    width: 96%;
    margin: 0 auto;
    justify-content: space-between; }
    @media (max-width: 600px) {
      .find-form__upper__text {
        text-align: center; } }
    .find-form__upper form {
      height: fit-content; }
    @media (max-width: 1199px) {
      .find-form__upper {
        width: auto;
        flex-direction: column; } }
    @media (max-width: 600px) {
      .find-form__upper {
        padding: 0 20px; }
        .find-form__upper .d-flex {
          flex-direction: column; } }
  .find-form__lower {
    text-align: center;
    font-family: 'Montserrat-Bold';
    font-size: 17px; }
  .find-form label {
    display: flex;
    flex-direction: column; }
    .find-form label:nth-child(2) {
      margin: 0 15px 0 25px; }
      @media (max-width: 600px) {
        .find-form label:nth-child(2) {
          margin: 0; } }
    .find-form label:last-child {
      flex-direction: column-reverse; }
  .find-form input[type=text] {
    border: 1px solid #DFDFDF;
    max-width: 240px;
    width: 100%;
    padding: 20px 10px; }
    @media (max-width: 600px) {
      .find-form input[type=text] {
        max-width: none; } }
  .find-form select {
    border: 1px solid #DFDFDF;
    width: 240px;
    height: 42px;
    background-image: url("/assets/images/dropdown-arrow.svg");
    background-position: 97% 14px; }
    @media (max-width: 600px) {
      .find-form select {
        width: 100%; } }
  .find-form input[type=submit] {
    background-color: #FFC85A;
    padding: 9px 40px;
    color: #232019;
    font-family: 'Montserrat-Medium';
    font-size: 16px; }
    @media (max-width: 600px) {
      .find-form input[type=submit] {
        width: 100%;
        margin: 20px 0; } }

.ie-only {
  display: none; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ie-only {
      display: block;
      background-color: #FFC85A;
      color: #000;
      text-align: center;
      margin-top: -10px;
      margin-bottom: 10px;
      padding: 10px; }
      .ie-only::before {
        content: 'The browser you are using to access this site is unsupported. For a better user experience, please use a modern browser. Thank you.'; } }

.mob-only {
  display: none !important; }
  @media (max-width: 500px) {
    .mob-only {
      display: block !important; } }

.desk-only {
  display: block !important; }
  @media (max-width: 500px) {
    .desk-only {
      display: none !important; } }

.home__search {
  height: 550px;
  position: relative; }
  @media (max-width: 1199px) {
    .home__search {
      height: auto;
      margin-bottom: -75px; } }
  @media (max-width: 500px) {
    .home__search {
      margin-bottom: 0; } }
  .home__search__inner {
    background: white;
    padding: 30px 5px 30px 30px;
    position: absolute;
    top: 150px;
    max-width: 700px;
    width: 100%; }
    @media (max-width: 1199px) {
      .home__search__inner {
        position: relative;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        padding: 30px; } }
    @media (max-width: 500px) {
      .home__search__inner {
        position: relative;
        top: 0;
        margin-top: -150px;
        padding: 10px 20px;
        text-align: center; } }
  .home__search__bg {
    background-image: url("/assets/images/home/search-banner.png");
    background-size: 1500px 100%;
    background-position: right center;
    background-repeat: no-repeat;
    display: inline-block;
    max-width: 1920px;
    width: 100vw;
    height: 580px;
    margin-top: -55px;
    z-index: -1;
    position: absolute;
    right: -300px; }
    @media (max-width: 1199px) {
      .home__search__bg {
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background-size: cover;
        background-position: center; } }
    @media (max-width: 500px) {
      .home__search__bg {
        height: 400px;
        margin-top: 0;
        background-image: url("/assets/images/home/mobile-search-banner.png"); } }
  .home__search__form__option {
    display: flex;
    flex-wrap: wrap;
    justify-content: start; }
    .home__search__form__option #labelBedroom {
      margin: 0 10px; }
      @media (max-width: 500px) {
        .home__search__form__option #labelBedroom {
          margin: 0; } }
  .home__search__form__date {
    display: flex; }
    .home__search__form__date #checkIn, .home__search__form__date #checkOut {
      width: 100px; }
      @media (max-width: 751px) {
        .home__search__form__date #checkIn, .home__search__form__date #checkOut {
          width: 32vw; } }
    @media (max-width: 500px) {
      .home__search__form__date {
        width: 100%;
        margin-top: 5px; }
        .home__search__form__date label {
          width: 50% !important; }
          .home__search__form__date label:first-child {
            text-align: right;
            margin-right: 5px; }
          .home__search__form__date label:last-child {
            text-align: left; }
        .home__search__form__date #checkIn, .home__search__form__date #checkOut {
          width: 100%; } }
  .home__search__form__submit input[type=submit] {
    font-size: 14px; }
  @media (max-width: 500px) {
    .home__search__form__submit {
      display: flex;
      justify-content: center;
      align-items: center; } }
  .home__search__form label {
    display: flex;
    flex-direction: column;
    color: #232019;
    font-size: 14px; }
    @media (max-width: 500px) {
      .home__search__form label {
        width: 100%; } }
  .home__search__form input[type=submit] {
    margin: 0;
    margin-top: 20px; }
  @media (max-width: 500px) {
    .home__search__form #searchLocation {
      width: 100%; } }
  @media (max-width: 500px) {
    .home__search__form #bedroom {
      width: 100%; } }

.home__process {
  padding: 30px 0 0; }
  .home__process span {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: black;
    border: 3px solid #0B0B0B;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 23px;
    font-family: sans-serif;
    font-weight: 900;
    margin-bottom: 15px;
    padding-bottom: 2px;
    padding-left: 1px; }

.home__or {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E9E9E9;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  width: 100vw;
  padding: 10px; }
  .home__or a {
    display: flex;
    align-items: center; }
  .home__or img {
    max-width: 40px;
    width: 100%;
    margin-right: 10px; }
  @media (max-width: 500px) {
    .home__or span {
      width: 180px; } }

.home__match {
  background-color: #F8F8F8;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  text-align: center;
  padding: 30px; }

.home__cities {
  padding: 30px; }
  .home__cities p:nth-child(2) {
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    text-align: center; }

.home__help {
  position: relative;
  height: 550px; }
  @media (max-width: 1199px) {
    .home__help {
      height: auto;
      margin-bottom: -100px; } }
  .home__help__bg {
    background-image: url("/assets/images/home/help-banner.png");
    background-size: 1350px;
    background-position: top left;
    background-repeat: no-repeat;
    display: inline-block;
    max-width: 1920px;
    width: 100vw;
    height: 550px;
    position: absolute;
    left: -125px;
    z-index: -1; }
    @media (max-width: 1199px) {
      .home__help__bg {
        background-position: center;
        background-size: cover;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw; } }
    @media (max-width: 500px) {
      .home__help__bg {
        background-size: cover;
        height: 400px;
        margin-top: 0; } }
  .home__help__inner {
    background: white;
    padding: 50px 5px 60px 80px;
    position: absolute;
    top: 45px;
    right: 120px;
    max-width: 700px;
    width: 100%; }
    @media (max-width: 1500px) {
      .home__help__inner {
        right: 0; } }
    @media (max-width: 1199px) {
      .home__help__inner {
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        top: -100px; } }
    @media (max-width: 500px) {
      .home__help__inner {
        position: relative;
        padding: 30px 10px;
        text-align: center; } }
    .home__help__inner h3 {
      text-align: center;
      max-width: 400px;
      width: 100%;
      margin: 30px auto; }
    .home__help__inner .btn-wrapper {
      margin-top: 40px; }

.home__why {
  padding: 40px 0; }
  .home__why__img {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .home__why__title {
    display: inline; }
  @media (max-width: 616px) {
    .home__why .btn-fluid {
      margin-top: 25px; } }
  .home__why .flexbox {
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 20px; }
    .home__why .flexbox__each {
      max-width: 310px;
      width: 100%; }
      @media (max-width: 616px) {
        .home__why .flexbox__each {
          border-bottom: 2px solid #DADADA; }
          .home__why .flexbox__each:first-child {
            border-top: 2px solid #DADADA;
            margin-top: 20px; } }
    .home__why .flexbox h5, .home__why .flexbox p {
      display: inline; }
    .home__why .flexbox__each:first-child img {
      max-width: 45px;
      width: 100%; }
    .home__why .flexbox__each:nth-child(2) img {
      max-width: 45px;
      width: 100%; }
    .home__why .flexbox__each:nth-child(3) img {
      max-width: 35px;
      width: 100%; }
    .home__why .flexbox__each:last-child img {
      max-width: 33px;
      width: 100%; }
    .home__why .flexbox .accordion {
      display: none; }
      @media (max-width: 616px) {
        .home__why .flexbox .accordion {
          display: block;
          text-align: center; } }
    .home__why .flexbox .accordion::after {
      display: block;
      content: "+";
      font-size: 25px;
      margin: 5px auto -15px; }
    .home__why .flexbox .accordion.active::after {
      display: none; }
    .home__why .flexbox .panel {
      display: inline; }
      @media (max-width: 616px) {
        .home__why .flexbox .panel {
          display: none; } }
    @media (max-width: 616px) {
      .home__why .flexbox .panel.show {
        display: block; } }
    .home__why .flexbox .panel-close {
      display: none; }
      @media (max-width: 616px) {
        .home__why .flexbox .panel-close {
          display: block; } }
      .home__why .flexbox .panel-close::before {
        display: block;
        font-size: 25px;
        content: "-";
        transform: scaleX(2);
        margin: -5px auto -15px; }
      .home__why .flexbox .panel-close::after {
        display: none; }
  .home__why p:nth-child(2) {
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    text-align: center; }

.home__review {
  position: relative;
  height: 500px; }
  @media (max-width: 1199px) {
    .home__review {
      height: auto;
      margin-bottom: -100px; } }
  @media (max-width: 500px) {
    .home__review {
      margin-bottom: 0; } }
  .home__review__quote {
    padding: 10px; }
    .home__review__quote::after {
      background-image: url("/assets/images/quote.png");
      background-size: 100%;
      background-repeat: no-repeat;
      display: inline-block;
      width: 50px;
      height: 50px;
      content: ""; }
  .home__review__inner {
    background: white;
    padding: 70px 50px;
    position: absolute;
    top: 35px;
    left: 140px;
    max-width: 700px;
    width: 100%;
    text-align: center; }
    @media (max-width: 1500px) {
      .home__review__inner {
        left: 0; } }
    @media (max-width: 1199px) {
      .home__review__inner {
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        top: -100px; } }
    @media (max-width: 500px) {
      .home__review__inner {
        position: relative;
        margin-top: -150px;
        padding: 30px 10px;
        top: 35px; } }
    .home__review__inner h4 {
      max-width: 500px;
      margin: 0 auto;
      width: 100%;
      font-style: italic;
      line-height: 30px; }
  .home__review__bg {
    background-image: url("/assets/images/home/review-banner.png");
    background-size: 1300px;
    background-position: center right;
    background-repeat: no-repeat;
    display: inline-block;
    max-width: 1920px;
    width: 100vw;
    height: 500px;
    z-index: -1;
    position: absolute;
    right: -125px; }
    @media (max-width: 1199px) {
      .home__review__bg {
        background-position: center;
        background-size: cover;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw; } }
    @media (max-width: 500px) {
      .home__review__bg {
        background-size: cover;
        height: 400px;
        margin-top: 0; } }

.saved__contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0; }
  @media (max-width: 500px) {
    .saved__contact {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      padding: 20px;
      background-color: #F4F4F4; } }
  .saved__contact h3.sub-title {
    margin-bottom: 30px; }
  .saved__contact .btn {
    border-radius: 0; }
    @media (max-width: 500px) {
      .saved__contact .btn {
        width: 100vw;
        background: #EBEBEB;
        margin: 5px; } }
    .saved__contact .btn a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-family: 'Montserrat-Medium'; }
      @media (max-width: 500px) {
        .saved__contact .btn a {
          color: black;
          text-transform: capitalize;
          font-family: 'Montserrat-SemiBold'; } }
      .saved__contact .btn a::before {
        background-image: url("/assets/images/icon-call.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 0px 10px;
        display: inline-block;
        content: "";
        width: 30px;
        height: 50px;
        margin-right: 10px;
        margin-top: -10px;
        margin-bottom: -10px; }
        @media (max-width: 500px) {
          .saved__contact .btn a::before {
            margin-right: 30px; } }
  .saved__contact .btn:first-child a::before {
    background-image: url("/assets/images/contact/icon-chat.png"); }

.find input,
.find label {
  font-size: 14px; }

.find input[type=checkbox],
.find input[type=radio] {
  margin: 0 10px 0 30px; }

@media (max-width: 600px) {
  .find #pagin {
    padding: 15px; } }

.find__filter {
  position: relative; }
  .find__filter form {
    display: flex;
    flex-direction: column; }
  .find__filter p {
    margin-bottom: 0;
    font-size: 14px; }
  .find__filter__top {
    order: 0;
    display: flex;
    justify-content: initial;
    align-items: baseline;
    margin-bottom: 10px; }
    @media (max-width: 1199px) {
      .find__filter__top {
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
    .find__filter__top > label:first-child > input {
      width: 400px; }
      @media (max-width: 1920px) {
        .find__filter__top > label:first-child > input {
          width: 22vw; } }
      @media (max-width: 1350px) {
        .find__filter__top > label:first-child > input {
          width: 19vw; } }
      @media (max-width: 1199px) {
        .find__filter__top > label:first-child > input {
          max-width: 270px;
          width: 100%; } }
      @media (max-width: 1199px) {
        .find__filter__top > label:first-child > input {
          max-width: unset;
          width: 40vw; } }
      @media (max-width: 600px) {
        .find__filter__top > label:first-child > input {
          width: 86vw; } }
    @media (max-width: 1199px) {
      .find__filter__top input[type=checkbox],
      .find__filter__top input[type=radio] {
        margin: 0 10px; } }
    .find__filter__top__date {
      display: flex; }
    .find__filter__top select {
      padding: 0;
      margin: 0 15px;
      width: 290px; }
      @media (max-width: 1920px) {
        .find__filter__top select {
          width: 15vw; } }
      @media (max-width: 1199px) {
        .find__filter__top select {
          width: 40vw; } }
      @media (max-width: 600px) {
        .find__filter__top select {
          width: 86vw; } }
    .find__filter__top #checkIn,
    .find__filter__top #checkOut {
      width: 290px; }
      @media (max-width: 1920px) {
        .find__filter__top #checkIn,
        .find__filter__top #checkOut {
          width: 15vw; } }
      @media (max-width: 1199px) {
        .find__filter__top #checkIn,
        .find__filter__top #checkOut {
          width: 14vw; } }
      @media (max-width: 1199px) {
        .find__filter__top #checkIn,
        .find__filter__top #checkOut {
          width: 20vw; } }
      @media (max-width: 600px) {
        .find__filter__top #checkIn,
        .find__filter__top #checkOut {
          width: 43vw; } }
    .find__filter__top input[type=submit] {
      background-color: #FFC85A;
      color: #000;
      margin-left: 30px; }
      @media (max-width: 1199px) {
        .find__filter__top input[type=submit] {
          margin-left: 15px;
          width: auto;
          padding: 8px 30px; } }
      @media (max-width: 1199px) {
        .find__filter__top input[type=submit] {
          padding: 8px 40px; } }
  .find__filter__tailor {
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: #F8F8F8;
    border-bottom: 2px solid #DBDBDB;
    padding: 15px 5px; }
    @media (max-width: 1199px) {
      .find__filter__tailor {
        flex-direction: column;
        background-color: white;
        padding: 5px;
        border-bottom: none; } }
    .find__filter__tailor p {
      font-family: 'Montserrat-Medium'; }
      @media (max-width: 600px) {
        .find__filter__tailor p {
          width: 100%;
          text-align: center;
          padding: 0 15px; } }
  .find__filter__more {
    display: none; }
    .find__filter__more.active {
      order: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      position: absolute;
      left: 50%;
      top: 55px;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      background-color: #F8F8F8;
      border-bottom: 2px solid #DBDBDB;
      padding: 20px 30px 10px;
      z-index: 99; }
      @media (max-width: 1199px) {
        .find__filter__more.active {
          padding: 20px; } }
      @media (max-width: 1199px) {
        .find__filter__more.active {
          position: relative;
          top: -50px;
          margin-bottom: -40px; } }
    .find__filter__more label.checkbox span {
      margin: 0 8px 0 0px; }
    .find__filter__more .c-row:first-child {
      max-width: 1700px;
      width: 100%;
      justify-content: space-between; }
      @media (max-width: 1199px) {
        .find__filter__more .c-row:first-child {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          align-items: center; } }
      @media (max-width: 600px) {
        .find__filter__more .c-row:first-child {
          flex-direction: column;
          align-items: baseline;
          padding-left: 0;
          padding: 0 10px; } }
    .find__filter__more .c-col {
      margin: 0px 20px 35px; }
      @media (max-width: 1199px) {
        .find__filter__more .c-col {
          margin: 0 10px 35px; } }
      @media (max-width: 600px) {
        .find__filter__more .c-col {
          margin: 10px 0; } }
      .find__filter__more .c-col p {
        margin-bottom: 10px;
        font-size: 14px;
        font-family: 'Montserrat-Semibold';
        text-transform: uppercase; }
      .find__filter__more .c-col label {
        font-size: 13px;
        line-height: 25px;
        display: flex;
        align-items: center; }
      .find__filter__more .c-col .c-row {
        margin-top: 58px; }
        @media (max-width: 600px) {
          .find__filter__more .c-col .c-row {
            flex-direction: column; } }
        .find__filter__more .c-col .c-row label:first-child {
          margin-right: 25px; }
    .find__filter__more__amentities {
      display: flex;
      align-items: baseline; }
      @media (max-width: 600px) {
        .find__filter__more__amentities {
          flex-direction: column; } }
      .find__filter__more__amentities .c-col {
        margin: 0 25px 0 0; }
        .find__filter__more__amentities .c-col:last-child {
          margin: 0 0 0 50px; }
          @media (max-width: 600px) {
            .find__filter__more__amentities .c-col:last-child {
              margin: 0; } }
    .find__filter__more input[type=checkbox],
    .find__filter__more input[type=radio] {
      margin: 0 5px 0 0px; }
    .find__filter__more #budgetSliderLabel {
      margin-bottom: 10px;
      width: fit-content;
      height: 20px;
      line-height: 20px;
      position: relative;
      text-align: center;
      font-size: 14px; }
    .find__filter__more #budgetSlider {
      border-radius: 9.5px;
      background-color: #FFFFFF;
      box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.13);
      width: 290px;
      height: 10px;
      border: none; }
      @media (max-width: 600px) {
        .find__filter__more #budgetSlider {
          width: 75vw; } }
      .find__filter__more #budgetSlider::before {
        content: "$100/night";
        position: absolute;
        top: 23px;
        color: #8B8B8B;
        font-size: 13px;
        left: 0;
        font-family: 'Montserrat'; }
      .find__filter__more #budgetSlider::after {
        content: "$500/night";
        position: absolute;
        top: 23px;
        right: 0;
        color: #8B8B8B;
        font-size: 13px;
        font-family: 'Montserrat'; }
      .find__filter__more #budgetSlider a {
        left: 0%;
        width: 50px;
        border-radius: 9.5px;
        background-color: #e6e6e6;
        box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 5px 0px inset;
        outline: none; }
  .find__filter__show {
    border: 1px solid #DFDFDF;
    padding: 7px 10px;
    color: #818181;
    font-size: 13px;
    text-transform: uppercase;
    margin-left: 20px;
    cursor: pointer; }
    @media (max-width: 1199px) {
      .find__filter__show {
        border: none;
        background: #F8F8F8;
        width: 100vw;
        text-align: center;
        color: black;
        margin: 10px; } }
    .find__filter__show::after {
      content: "V";
      font-size: 13px;
      margin-left: 15px;
      transform: scaleX(1.2) scaleY(0.7);
      font-family: 'Montserrat-SemiBold';
      display: inline-block;
      color: #8B8B8B; }
  .find__filter__apply input[type=submit] {
    background-color: #FFC85A;
    font-size: 14px;
    text-align: center;
    padding: 8px 15px;
    width: 170px;
    margin-bottom: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    color: #000; }
  .find__filter__hide {
    width: 100%;
    color: #818181;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
    padding: 10px 0 0;
    border-top: 2px solid #DBDBDB;
    cursor: pointer; }
    .find__filter__hide::after {
      content: "V";
      font-size: 13px;
      margin-left: 15px;
      transform: scaleX(1.2) scaleY(0.7) rotate(180deg);
      font-family: 'Montserrat-SemiBold';
      display: inline-block; }
    @media (max-width: 600px) {
      .find__filter__hide {
        padding: 20px 0 0; } }

.find__mode {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-top: 10px; }
  @media (max-width: 1199px) {
    .find__mode {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      padding-top: 0px;
      margin-top: -10px; } }
  .find__mode__sort {
    display: flex; }
    @media (max-width: 1199px) {
      .find__mode__sort {
        margin-top: -15px; }
        .find__mode__sort label:nth-child(2),
        .find__mode__sort label:last-child {
          display: none; } }
    @media (max-width: 600px) {
      .find__mode__sort select {
        width: auto; } }
  .find__mode__view {
    display: flex;
    align-items: center; }
    .find__mode__view p {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 0.5rem;
      font-family: 'Montserrat-Medium'; }

.find__best {
  background: #F4F4F4;
  padding: 20px 2px 0; }
  @media (max-width: 600px) {
    .find__best {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw; } }
  .find__best .sub-title {
    margin-left: 10px; }
    @media (max-width: 600px) {
      .find__best .sub-title {
        margin: 0 auto 10px;
        width: auto;
        text-align: center; } }

.find__contact {
  padding: 10px;
  text-align: center;
  background: #EBEBEB; }
  @media (max-width: 600px) {
    .find__contact {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      text-align: center; } }

.find__results {
  padding-top: 30px; }
  @media (max-width: 600px) {
    .find__results {
      padding-top: 10px; } }

.find__recent {
  padding-top: 30px;
  padding-bottom: 10px; }
  @media (max-width: 600px) {
    .find__recent {
      padding: 30px 0;
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      background: #F4F4F4; } }
  .find__recent .sub-title {
    text-align: center; }

.grid-view .find__map {
  display: none; }

@media (max-width: 1199px) {
  .map-view {
    display: block; }
    .map-view #map {
      width: 100%;
      height: 500px;
      margin-left: 0; } }

@media (min-width: 1024px) {
  .map-view {
    display: flex;
    /* width */
    /* Track */
    /* Handle */ }
    .map-view .find__wrapper {
      width: 32%;
      min-width: 490px; }
      .map-view .find__wrapper__results {
        min-height: 700px;
        max-height: 700px;
        overflow-y: scroll;
        border: 2px solid #F2F2F2;
        border-radius: 0 13px 13px 0; }
      .map-view .find__wrapper #pagin {
        margin-top: 20px;
        padding-bottom: 0; }
      .map-view .find__wrapper .result-lists {
        flex-direction: column; }
        .map-view .find__wrapper .result-lists__each {
          width: 100%; }
          .map-view .find__wrapper .result-lists__each img {
            max-width: unset; }
          .map-view .find__wrapper .result-lists__each .carousel-wrap {
            width: 45%;
            float: left; }
          .map-view .find__wrapper .result-lists__each .text-wrap {
            width: 55%;
            float: right;
            padding: 10% 20px 0; }
            .map-view .find__wrapper .result-lists__each .text-wrap div:nth-child(3) {
              flex-direction: column;
              align-items: flex-start;
              margin-bottom: 15px; }
            .map-view .find__wrapper .result-lists__each .text-wrap h6 {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical; }
      .map-view .find__wrapper .fav::before {
        width: 30px;
        height: 30px;
        top: 10px;
        margin-right: 20px; }
      .map-view .find__wrapper .fav::after {
        display: none; }
    .map-view .find__map {
      width: 68%; }
    .map-view #map {
      width: 68%;
      height: auto;
      margin-left: 15px; }
    .map-view ::-webkit-scrollbar {
      width: 30px; }
    .map-view ::-webkit-scrollbar-track {
      border: 1px solid #F0F0F0;
      border-radius: 0 13px 13px 0;
      background-color: #F0F0F0; }
    .map-view ::-webkit-scrollbar-thumb {
      border-radius: 15px;
      border: 6px solid transparent;
      background-color: #CACACA;
      background-clip: content-box; } }

.gm-style-iw-c {
  max-height: 450px !important;
  overflow-y: scroll !important;
  max-width: 300px !important;
  padding: 5px !important;
  border-radius: 0 !important;
  /* Track */
  /* Handle */ }
  .gm-style-iw-c .gm-style-iw-d {
    margin-right: -15px; }
  .gm-style-iw-c::-webkit-scrollbar {
    width: 10px; }
  .gm-style-iw-c::-webkit-scrollbar-track {
    border: none;
    background-color: #FFFFFF;
    border-radius: 0; }
  .gm-style-iw-c::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border: 6px solid transparent;
    background-color: #CACACA;
    background-clip: content-box; }
  .gm-style-iw-c .result-lists__each {
    width: 100%; }
    .gm-style-iw-c .result-lists__each img {
      width: 100% !important; }

.gm-style-cc {
  display: none; }

.slider-col {
  width: 300px;
  margin-right: 35px !important; }
  @media (max-width: 600px) {
    .slider-col {
      width: 230px; } }
  .slider-col .c-row {
    width: 350px; }

.city p:nth-child(2) {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  letter-spacing: 0.7px;
  line-height: 32px; }

.city__category__menu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0; }
  @media (max-width: 1199px) {
    .city__category__menu {
      -ms-overflow-style: none;
      scrollbar-width: none;
      /* Firefox */
      overflow-x: scroll;
      justify-content: end;
      border-top: 2px solid #DFDFDF;
      border-bottom: 2px solid #DFDFDF;
      padding: 10px 0;
      margin-top: 15px; } }
  .city__category__menu li {
    margin: 0 30px; }
    @media (max-width: 1280px) {
      .city__category__menu li {
        margin: 0 15px; } }
    .city__category__menu li a {
      color: #232019; }
      .city__category__menu li a.active {
        font-family: 'Montserrat-Bold';
        position: relative; }
        .city__category__menu li a.active::after {
          content: "";
          width: 70px;
          height: 3px;
          background: #FFC85A;
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 5px;
          top: 20px; }
          @media (max-width: 500px) {
            .city__category__menu li a.active::after {
              margin-top: 0; } }
  .city__category__menu ::-webkit-scrollbar {
    display: none; }

.city__category__content {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

.city__category__each {
  display: flex;
  flex-direction: column;
  max-width: 25%;
  width: 100%;
  padding: 10px; }
  @media (max-width: 1199px) {
    .city__category__each {
      max-width: 33%; } }
  @media (max-width: 768px) {
    .city__category__each {
      max-width: 50%; } }
  @media (max-width: 500px) {
    .city__category__each {
      max-width: 100%; } }
  .city__category__each img {
    width: inherit; }
  .city__category__each .text-wrap p:first-child {
    text-transform: uppercase;
    margin-top: 15px; }
  .city__category__each .text-wrap h6 {
    font-size: 17px;
    margin: -10px 0 10px; }
  .city__category__each .text-wrap p > span {
    font-family: 'Montserrat-Bold';
    cursor: pointer; }

.city__map #map {
  width: 100%;
  height: 600px; }

.city__weather {
  padding: 50px 100px;
  background: #F6F6F6;
  margin-top: 50px; }
  @media (max-width: 500px) {
    .city__weather {
      margin-top: 0; } }

.city__rentals {
  padding: 50px 0; }
  @media (max-width: 500px) {
    .city__rentals {
      padding: 30px 0 50px; } }
  .city__rentals .sub-title {
    text-align: center; }

@media (max-width: 500px) {
  .city h3.sub-title {
    letter-spacing: 0.8px;
    width: 270px;
    margin: 0 auto 30px; }
  .city .questions {
    padding-top: 0;
    margin-top: -20px; }
  .city .line--thick {
    display: none; } }

.property .d-block.d-md-none {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }

.property__gallery {
  margin-top: 25px;
  position: relative; }
  @media (max-width: 1199px) {
    .property__gallery {
      margin-top: 0; } }
  .property__gallery .btn {
    position: absolute;
    bottom: -15px;
    z-index: 1; }

.property__btns {
  display: flex;
  position: absolute;
  right: 0;
  top: 15px; }
  @media (max-width: 767px) {
    .property__btns {
      position: relative;
      top: 0; } }
  .property__btns div {
    display: flex;
    align-items: center;
    margin: 0 15px; }
    @media (max-width: 767px) {
      .property__btns div {
        margin: 0 0 10px; } }
  .property__btns .fav {
    cursor: pointer; }
    .property__btns .fav::before {
      position: relative;
      top: 0;
      background-image: url("/assets/images/property/icon-fav.png"); }
      @media (max-width: 767px) {
        .property__btns .fav::before {
          position: absolute;
          top: -90px; } }
      @media (max-width: 500px) {
        .property__btns .fav::before {
          top: -77px; } }
    .property__btns .fav::after {
      display: none; }
    @media (max-width: 767px) {
      .property__btns .fav span {
        display: none; } }
    .property__btns .fav.active::before {
      background-image: url("/assets/images/fav.png"); }
  .property__btns .download {
    cursor: pointer; }
    @media (max-width: 767px) {
      .property__btns .download {
        border-top: 1px solid #DFDFDF;
        border-bottom: 1px solid #DFDFDF;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        padding: 5px 0;
        font-size: 15px; } }
    .property__btns .download::before {
      background-image: url(/assets/images/property/icon-download.png);
      background-size: 100%;
      background-repeat: no-repeat;
      display: inline-block;
      width: 21px;
      height: 25px;
      content: "";
      position: relative;
      margin-right: 11px; }
      @media (max-width: 767px) {
        .property__btns .download::before {
          margin-left: 25px; } }
      @media (max-width: 500px) {
        .property__btns .download::before {
          margin-left: 10px; } }

.property__mobileOnly {
  display: none; }
  @media (max-width: 1199px) {
    .property__mobileOnly {
      display: block; } }

.property__chat {
  background-color: #F8F8F8;
  padding: 20px 10px 10px;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px; }
  @media (min-width: 1200px) {
    .property__chat {
      display: none; } }
  @media (max-width: 767px) {
    .property__chat {
      flex-direction: column;
      justify-content: left;
      padding: 15px 25px 5px; } }
  @media (max-width: 500px) {
    .property__chat {
      padding: 20px 10px 10px; } }
  .property__chat #openForm {
    cursor: pointer; }

.property__features {
  display: flex; }
  @media (max-width: 1400px) {
    .property__features {
      flex-wrap: wrap; } }
  .property__features p {
    display: flex;
    margin-right: 30px; }

.property ul {
  padding-inline-start: 0;
  column-count: 3; }
  @media (max-width: 767px) {
    .property ul {
      column-count: 2; } }
  @media (max-width: 575px) {
    .property ul {
      column-count: 1; } }
  .property ul li {
    font-family: 'Montserrat';
    letter-spacing: 0.7px;
    text-transform: initial;
    line-height: 35px;
    display: flex; }
    @media (max-width: 1400px) {
      .property ul li {
        font-size: 14px; } }
    .property ul li.na {
      text-decoration: line-through;
      opacity: 0.7; }
  .property ul .icon-calendar::before {
    background-image: url("/assets/images/property/icon-calender.png");
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 23px;
    height: 24px;
    content: "";
    margin-top: 6px;
    margin-right: 10px; }

.property__map #map {
  width: 100%;
  height: 300px; }

.property__explore {
  margin-top: 30px;
  position: relative; }
  .property__explore img {
    width: 600px; }
    @media (max-width: 991px) {
      .property__explore img {
        width: 100%; } }
  .property__explore__text {
    position: absolute;
    right: 0;
    background: white;
    top: 30px;
    width: 55%;
    padding: 50px 80px;
    text-align: center; }
    @media (max-width: 991px) {
      .property__explore__text {
        text-align: center;
        position: relative;
        width: 90%;
        margin: 0 auto;
        top: -50px;
        margin-bottom: -50px; } }
    @media (max-width: 500px) {
      .property__explore__text {
        padding: 50px 10px; } }
  .property__explore__btn {
    margin-top: 5px;
    padding: 10px 25px;
    outline: none;
    color: #818181;
    background: transparent;
    border: 1px solid #DFDFDF;
    text-transform: uppercase;
    font-family: 'Montserrat-Medium'; }
    @media (max-width: 500px) {
      .property__explore__btn {
        padding: 10px; } }

.property__other {
  padding: 50px 0; }
  @media (min-width: 768px) {
    .property__other {
      width: 550px; } }
  @media (max-width: 500px) {
    .property__other .sub-title {
      margin: 0; } }
  .property__other .sub-title--2 {
    margin-top: 30px; }

.property__review {
  position: relative; }
  .property__review img {
    width: 100%; }
  .property__review__content {
    position: absolute;
    top: 100px;
    left: 0;
    width: 50%;
    text-align: center;
    background: white;
    padding: 40px 50px 70px; }
    @media (max-width: 1440px) {
      .property__review__content {
        padding: 25px 25px 50px; } }
    @media (max-width: 991px) {
      .property__review__content {
        position: relative;
        top: -100px;
        width: 90%;
        margin: 0 auto;
        margin-bottom: -100px; } }
    .property__review__content .star::before {
      filter: brightness(0.5); }
  .property__review h4 {
    line-height: 34px;
    font-size: 19px; }
    @media (max-width: 1440px) {
      .property__review h4 {
        font-size: 16px; } }
  .property__review p {
    margin-bottom: 0; }

.property__inquiry__top {
  background: #F6F6F6;
  padding: 15px 20px 1px; }
  @media (max-width: 1199px) {
    .property__inquiry__top {
      display: none; } }
  .property__inquiry__top p {
    margin-left: 50px;
    margin-top: -10px; }

.property__inquiry__form {
  background: #F6F6F6;
  padding: 20px;
  margin-top: 40px;
  position: relative;
  font-size: 14px; }
  @media (max-width: 1199px) {
    .property__inquiry__form {
      display: none; }
      .property__inquiry__form.opened {
        display: block;
        position: fixed;
        top: 20px;
        z-index: 99;
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw; } }
    @media (max-width: 1199px) and (max-width: 320px) {
      .property__inquiry__form.opened {
        top: -16px; } }
  .property__inquiry__form #inquiryPrice {
    width: fit-content;
    position: absolute;
    top: -25px;
    background: white;
    padding: 6px 20px;
    border: 1px solid #7D796E;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    font-size: 15px;
    cursor: pointer; }
  .property__inquiry__form__basic {
    display: flex;
    flex-direction: column; }
  .property__inquiry__form input, .property__inquiry__form input[type=text], .property__inquiry__form input[type=date], .property__inquiry__form input[type=email], .property__inquiry__form select {
    border: 1px solid #DFDFDF;
    width: 100%;
    padding: 6px 15px;
    height: unset;
    color: #8B8B8B; }
  .property__inquiry__form__date {
    display: flex; }
    .property__inquiry__form__date input[type=text], .property__inquiry__form__date input[type=date] {
      text-align: center; }
  .property__inquiry__form__options {
    display: flex; }
    .property__inquiry__form__options label:nth-child(2) {
      margin: 0 15px; }
  .property__inquiry__form__radio {
    display: flex; }
    .property__inquiry__form__radio label.checkbox {
      display: flex;
      align-items: center;
      padding: 25px 0px;
      margin-right: 25px; }
      .property__inquiry__form__radio label.checkbox span {
        width: 20px;
        height: 20px;
        margin: 0 5px; }
  .property__inquiry__form__msg {
    margin-left: 10px; }
    .property__inquiry__form__msg label {
      width: 100%; }
    .property__inquiry__form__msg textarea {
      width: 100%;
      min-height: 100px;
      background: transparent;
      border: 1px solid #DFDFDF;
      margin-top: 5px; }
      @media (max-width: 320px) {
        .property__inquiry__form__msg textarea {
          min-height: 50px; } }
  .property__inquiry__form__submit {
    text-align: center; }
    .property__inquiry__form__submit input[type=submit] {
      background-color: #FFC85A;
      width: fit-content;
      padding: 10px 30px;
      font-size: 14px;
      color: black;
      margin-top: 15px; }
      .property__inquiry__form__submit input[type=submit]:hover {
        color: #818181;
        background-color: #D7D7D7; }
      @media (max-width: 320px) {
        .property__inquiry__form__submit input[type=submit] {
          margin-top: 5px; } }

@media (max-width: 500px) {
  .property__related {
    background-color: #F4F4F4;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding: 30px 0; } }

@media (max-width: 500px) {
  .property .sub-title {
    width: 100%;
    margin: 0px 0px 30px; }
  .property .line--thick {
    display: none; }
  .property h1 {
    font-size: 30px; } }

#propertyGalleryCarousel {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }
  #propertyGalleryCarousel .owl-item:nth-child(odd) {
    width: 40vw !important; }
    @media (max-width: 768px) {
      #propertyGalleryCarousel .owl-item:nth-child(odd) {
        width: 100vw !important; } }
  #propertyGalleryCarousel .owl-item:nth-child(even) {
    width: 20vw !important; }
    @media (max-width: 768px) {
      #propertyGalleryCarousel .owl-item:nth-child(even) {
        width: 100vw !important; } }
    #propertyGalleryCarousel .owl-item:nth-child(even) img:first-child {
      margin-bottom: 10px; }
      @media (max-width: 768px) {
        #propertyGalleryCarousel .owl-item:nth-child(even) img:first-child {
          margin-bottom: 0; } }
  #propertyGalleryCarousel .d-flex .item {
    height: 50%; }
  @media (max-width: 768px) {
    #propertyGalleryCarousel .item {
      margin: 0; } }
  #propertyGalleryCarousel .owl-nav {
    display: block; }
    #propertyGalleryCarousel .owl-nav .owl-prev {
      position: absolute;
      left: 25px;
      top: 46%; }
    #propertyGalleryCarousel .owl-nav .owl-next {
      position: absolute;
      right: 25px;
      top: 46%; }

.contact {
  padding: 20px 0; }
  @media (max-width: 1199px) {
    .contact {
      padding: 0 10px; } }
  .contact__banner img {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw; }
  .contact__main {
    position: relative;
    display: flex; }
    @media (max-width: 991px) {
      .contact__main {
        flex-direction: column; } }
  .contact__text {
    width: 50%;
    padding-top: 50px; }
    @media (max-width: 1600px) {
      .contact__text {
        padding-bottom: 140px; } }
    @media (max-width: 1199px) {
      .contact__text {
        width: 100%;
        padding-bottom: 0; } }
    @media (max-width: 500px) {
      .contact__text {
        padding-top: 30px; } }
    @media (max-width: 500px) {
      .contact__text .sub-title {
        text-align: left;
        margin: 0;
        font-family: 'Big Caslon Medium', 'Times New Roman', Times, serif;
        font-size: 8vw;
        text-transform: initial;
        margin-bottom: 20px; } }
    .contact__text p {
      letter-spacing: 0.7px;
      line-height: 32px; }
    @media (max-width: 500px) {
      .contact__text .d-flex {
        flex-direction: column; } }
    .contact__text .icon-chat, .contact__text .icon-call {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      margin: 0 30px 0 0;
      padding: 10px 20px;
      width: 170px;
      border-radius: 0; }
      @media (max-width: 500px) {
        .contact__text .icon-chat, .contact__text .icon-call {
          width: 100%;
          justify-content: center;
          margin: 0px 0 10px 0;
          background: #EBEBEB;
          text-transform: capitalize;
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
          padding: 10px; }
          .contact__text .icon-chat a, .contact__text .icon-call a {
            color: black;
            font-family: 'Montserrat-SemiBold';
            width: 120px;
            font-size: 15px; } }
      .contact__text .icon-chat::before, .contact__text .icon-call::before {
        background-image: url("/assets/images/contact/icon-chat.png");
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 35px;
        height: 35px;
        content: "";
        margin-right: 10px; }
    .contact__text .icon-call::before {
      background-image: url("/assets/images/contact/icon-call.png"); }
  .contact__form {
    position: absolute;
    width: 45%;
    top: -21vw;
    background: white;
    padding: 50px;
    right: 0; }
    @media (max-width: 1199px) {
      .contact__form {
        position: relative;
        top: 0;
        width: 100%;
        padding: 40px; } }
    @media (max-width: 991px) {
      .contact__form {
        padding: 40px 0 10px; } }
    .contact__form h2 {
      text-align: left;
      margin-bottom: 25px; }
      @media (max-width: 500px) {
        .contact__form h2 {
          text-align: center;
          font-size: 8vw;
          margin-bottom: 20px; } }
    .contact__form__submit input[type=submit]:hover {
      background-color: #d7d7d7;
      color: #818181; }
    @media (max-width: 991px) {
      .contact__form__submit {
        display: flex;
        justify-content: center;
        margin-bottom: 30px; } }
  .contact form label {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 20px 15px 0;
    cursor: default; }
    @media (max-width: 500px) {
      .contact form label {
        width: 100%; } }
    .contact form label:last-child {
      width: 100%; }
      .contact form label:last-child textarea {
        height: 150px;
        margin-top: 15px;
        border: 1px solid #DFDFDF;
        outline: none; }
  .contact form input, .contact form select {
    width: 100%;
    height: unset;
    padding: 10px;
    background-position: 97% 19px;
    border: 1px solid #DFDFDF;
    margin-top: 10px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .contact form input, .contact form select {
        padding: 0 5px;
        font-style: italic; } }
  .contact form input[type=submit] {
    background-color: #FFC85A;
    color: #000;
    float: right;
    width: 170px;
    font-size: 15px;
    margin-top: 10px; }
  .contact form input::placeholder, .contact form select {
    font-style: italic;
    font-size: 15px;
    color: #bdbdbd;
    font-family: 'Montserrat'; }

.solution .page-title {
  padding: 40px 0 10px; }
  @media (max-width: 500px) {
    .solution .page-title {
      padding: 30px 0 0 0;
      margin-bottom: 30px !important;
      line-height: 35px;
      font-size: 26px;
      margin-left: -4px;
      margin-right: -4px;
      width: initial; } }

.solution__each {
  position: relative;
  height: 550px;
  margin-bottom: 50px; }
  @media (max-width: 1199px) {
    .solution__each {
      height: auto;
      margin-bottom: -70px; } }
  @media (max-width: 500px) {
    .solution__each {
      margin-bottom: 0; } }
  .solution__each__bg {
    position: absolute;
    background-size: 1050px 100%;
    background-position: left center;
    background-repeat: no-repeat;
    display: inline-block;
    width: 100%;
    height: 550px;
    z-index: -1; }
    .solution__each__bg.short-term {
      background-image: url("/assets/images/solution/short-term.png"); }
      @media (max-width: 500px) {
        .solution__each__bg.short-term {
          background-image: url("/assets/images/solution/short-term-mobile.png"); } }
    .solution__each__bg.vacation {
      background-image: url("/assets/images/solution/vacation.png"); }
      @media (max-width: 500px) {
        .solution__each__bg.vacation {
          background-image: url("/assets/images/solution/vacation-mobile.png"); } }
    .solution__each__bg.corporate {
      background-image: url("/assets/images/solution/corporate.png"); }
      @media (max-width: 500px) {
        .solution__each__bg.corporate {
          background-image: url("/assets/images/solution/corporate-mobile.png"); } }
    .solution__each__bg.military {
      background-image: url("/assets/images/solution/military.png"); }
      @media (max-width: 500px) {
        .solution__each__bg.military {
          background-image: url("/assets/images/solution/military-mobile.png"); } }
    .solution__each__bg.entertainment {
      background-image: url("/assets/images/solution/entertainment.png"); }
      @media (max-width: 500px) {
        .solution__each__bg.entertainment {
          background-image: url("/assets/images/solution/entertainment-mobile.png"); } }
    .solution__each__bg.insurance {
      background-image: url("/assets/images/solution/insurance.png"); }
      @media (max-width: 500px) {
        .solution__each__bg.insurance {
          background-image: url("/assets/images/solution/insurance-mobile.png"); } }
    .solution__each__bg.medical {
      background-image: url("/assets/images/solution/medical.png"); }
      @media (max-width: 500px) {
        .solution__each__bg.medical {
          background-image: url("/assets/images/solution/medical-mobile.png"); } }
    @media (max-width: 1199px) {
      .solution__each__bg {
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background-size: cover;
        width: 100vw; } }
    @media (max-width: 500px) {
      .solution__each__bg {
        background-size: 100%;
        height: 300px; } }
  .solution__each__text {
    position: absolute;
    background: white;
    padding: 50px;
    width: 70%;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 1199px) {
      .solution__each__text {
        position: relative;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        padding: 30px; } }
    @media (max-width: 768px) {
      .solution__each__text {
        width: 100%; } }
    @media (max-width: 500px) {
      .solution__each__text {
        position: relative;
        top: 0;
        margin-top: -50px;
        padding: 10px 20px; } }
    .solution__each__text b {
      font-family: 'Montserrat-Bold'; }
    @media (max-width: 500px) {
      .solution__each__text .accordion {
        padding-top: 20px; } }
    @media (max-width: 500px) {
      .solution__each__text .panel {
        text-align: left; } }

.solution__faq {
  background: #F6F6F6;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding-top: 50px; }
  @media (max-width: 500px) {
    .solution__faq h3 {
      padding: 0 5px; } }
  @media (max-width: 991px) {
    .solution__faq .d-flex {
      flex-direction: column; } }
  @media (max-width: 991px) {
    .solution__faq__col {
      margin: 0 auto;
      text-align: center;
      border-bottom: 1px solid #979797; } }
  @media (max-width: 500px) {
    .solution__faq__col {
      margin: 0; } }
  .solution__faq__col:last-child {
    margin-left: 200px; }
    @media (max-width: 991px) {
      .solution__faq__col:last-child {
        margin: 0 auto;
        border-bottom: none; } }
    @media (max-width: 500px) {
      .solution__faq__col:last-child {
        margin: 0; } }
  .solution__faq__each {
    padding: 30px 30px 30px 0px;
    width: 420px;
    border-bottom: 1px solid #979797;
    font-family: 'Montserrat-Bold'; }
    .solution__faq__each:last-child {
      border-bottom: none;
      margin-bottom: 10px; }
      @media (max-width: 500px) {
        .solution__faq__each:last-child {
          margin-bottom: 0; } }
    @media (max-width: 500px) {
      .solution__faq__each {
        width: 100%;
        padding: 20px; } }
    .solution__faq__each .accordion {
      cursor: pointer; }
      .solution__faq__each .accordion::before {
        display: none; }
    .solution__faq__each .panel {
      display: none; }
      .solution__faq__each .panel.show {
        display: block; }

@media (max-width: 500px) {
  .solution .solution__each .accordion::before {
    background: #F7F7F7;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    content: url("/assets/images/chevdown.png");
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    display: flex; }
  .solution .solution__each .accordion.active::before {
    content: url("/assets/images/chevdown.png");
    transform: translateX(-50%) rotate(180deg); }
  .solution .solution__each .panel {
    display: none; }
    .solution .solution__each .panel.show {
      display: block; } }

.about {
  width: 100vw;
  margin-left: -20px;
  margin-right: -20px; }
  @media (max-width: 500px) {
    .about {
      margin-left: 0px;
      margin-right: 0px; } }
  .about .container {
    max-width: 1550px; }
    @media (max-width: 1550px) {
      .about .container {
        max-width: 1500px; } }
  @media (max-width: 500px) {
    .about .page-title {
      padding: 30px 0 0 0;
      margin-bottom: -50px !important; } }
  .about__sec1 {
    padding: 0 !important;
    position: relative; }
    .about__sec1__bg img {
      width: 75%;
      height: auto;
      margin-right: 0;
      margin-left: auto;
      display: block;
      min-height: 400px;
      object-fit: cover;
      object-position: right; }
      @media (max-width: 769px) {
        .about__sec1__bg img {
          width: 100%;
          min-height: 240px; } }
    .about__sec1__text {
      position: absolute;
      background: #fff;
      width: 45%;
      top: 50%;
      left: 5%;
      transform: translate(0%, -50%);
      padding: 2rem 4rem; }
      @media (max-width: 769px) {
        .about__sec1__text {
          margin: 0 auto;
          padding: 2rem 1rem;
          margin-top: -70px;
          position: relative;
          width: 90%;
          top: auto;
          left: auto;
          transform: unset; } }
  .about__sec2 {
    padding: 0 !important;
    position: relative;
    margin-bottom: 3rem; }
    .about__sec2__bg img {
      width: 75%;
      height: auto;
      display: block;
      min-height: 500px;
      object-fit: cover;
      object-position: center; }
      @media (max-width: 1025px) {
        .about__sec2__bg img {
          width: 100%;
          min-height: 240px; } }
    .about__sec2__text {
      position: absolute;
      background: #fff;
      width: 60%;
      top: 50%;
      right: 5%;
      transform: translate(0%, -50%);
      padding: 2rem 4rem; }
      @media (max-width: 1025px) {
        .about__sec2__text {
          margin: 0 auto;
          padding: 2rem 1rem;
          margin-top: -70px;
          position: relative;
          width: 90%;
          top: auto;
          right: auto;
          transform: unset; } }
  .about__faq {
    background: #F6F6F6;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-top: 50px; }
    @media (max-width: 500px) {
      .about__faq {
        margin-top: 80px; } }
    @media (max-width: 991px) {
      .about__faq .d-flex {
        flex-direction: column; } }
    @media (max-width: 991px) {
      .about__faq__col {
        margin: 0 auto;
        text-align: center;
        border-bottom: 1px solid #979797; } }
    .about__faq__col:last-child {
      margin-left: 200px; }
      @media (max-width: 991px) {
        .about__faq__col:last-child {
          margin: 0 auto;
          border-bottom: none; } }
    .about__faq__each {
      padding: 30px 30px 30px 0px;
      width: 420px;
      border-bottom: 1px solid #979797;
      font-family: 'Montserrat-Bold'; }
      .about__faq__each:last-child {
        border-bottom: none;
        margin-bottom: 10px; }
      @media (max-width: 500px) {
        .about__faq__each {
          width: 100%; } }
  @media (max-width: 500px) {
    .about .accordion {
      margin-top: 1rem; }
      .about .accordion::after {
        bottom: 0;
        font-size: 1.5rem;
        content: "+";
        line-height: 17px; }
      .about .accordion.active::after {
        content: "-"; }
    .about .panel {
      display: none; }
      .about .panel.show {
        display: block; } }
  .about-info {
    text-align: center;
    max-width: 800px;
    margin: 3rem auto; }
    @media (max-width: 769px) {
      .about-info {
        margin-top: 0; } }
    @media (max-width: 500px) {
      .about-info {
        text-align: left; } }
  .about-features {
    border-top: 3px solid #F1F1F1;
    padding: 4rem 0; }
    @media (max-width: 500px) {
      .about-features {
        padding: 1rem 0; } }
    .about-features .row .about-features-col .card {
      border: 0; }
      @media (max-width: 500px) {
        .about-features .row .about-features-col .card {
          border-bottom: 3px solid #F1F1F1; } }
      .about-features .row .about-features-col .card-title {
        display: flex;
        align-items: center;
        flex-wrap: nowrap; }
        @media (max-width: 500px) {
          .about-features .row .about-features-col .card-title {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 1.25rem auto; } }
        .about-features .row .about-features-col .card-title img {
          height: 40px; }
        .about-features .row .about-features-col .card-title p {
          text-transform: uppercase;
          text-transform: uppercase;
          margin: 0 10px;
          color: #232019;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0.74px;
          line-height: 32px; }
          @media (max-width: 500px) {
            .about-features .row .about-features-col .card-title p {
              font-size: 21px;
              font-weight: bold;
              margin: 10px;
              line-height: 29px; } }
      @media (max-width: 500px) {
        .about-features .row .about-features-col .card-text {
          text-align: center; } }
  .about-awards {
    background: #F6F6F6;
    padding: 4rem 0;
    border-bottom: 3px solid #F1F1F1; }
    @media (max-width: 500px) {
      .about-awards {
        padding: 2.5rem 0 4rem; } }
    .about-awards-title {
      text-align: center; }
    .about-awards .carousel-wrap .awards-carousel .owl-item .item .award-single {
      background: #fff;
      height: 210px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 1.5rem auto;
      padding: 21px;
      max-width: 210px; }
      .about-awards .carousel-wrap .awards-carousel .owl-item .item .award-single h6 {
        font-size: 23px;
        margin-bottom: 9px; }
      @media (max-width: 1600px) {
        .about-awards .carousel-wrap .awards-carousel .owl-item .item .award-single {
          width: 100%; } }
      .about-awards .carousel-wrap .awards-carousel .owl-item .item .award-single p {
        font-size: 14px;
        line-height: 20px;
        display: table; }
    @media (max-width: 500px) {
      .about-awards .carousel-wrap .awards-carousel .owl-dots {
        flex-wrap: wrap;
        max-width: 270px;
        margin: 0 auto; } }
    @media (max-width: 500px) {
      .about-awards .carousel-wrap .awards-carousel .owl-dots .owl-dot {
        margin: 5px !important; } }
  @media (max-width: 769px) {
    .about .questions {
      padding: 50px 15px; } }

@media (max-width: 1250px) {
  .pet .container {
    max-width: 1010px; } }

.pet section {
  padding: 15px; }
  @media (max-width: 500px) {
    .pet section {
      padding: 1rem 2rem; } }

@media (max-width: 500px) {
  .pet .page-title {
    padding: 30px 0 0 0;
    margin-bottom: -50px !important; } }

.pet__sec1 {
  padding: 0 !important;
  position: relative; }
  .pet__sec1__bg img {
    width: 75%;
    height: auto;
    margin-right: 0;
    margin-left: auto;
    display: block;
    min-height: 400px;
    object-fit: cover;
    object-position: center; }
    @media (max-width: 1199px) {
      .pet__sec1__bg img {
        width: 100%;
        min-height: 240px; } }
  .pet__sec1__text {
    position: absolute;
    background: #fff;
    width: 45%;
    top: 50%;
    left: 5%;
    max-width: 640px;
    transform: translate(0%, -50%);
    padding: 2rem 4rem; }
    @media (max-width: 1550px) {
      .pet__sec1__text {
        max-width: 550px; } }
    @media (max-width: 1199px) {
      .pet__sec1__text {
        margin: 0 auto;
        padding: 2rem 1rem;
        margin-top: -70px;
        position: relative;
        width: 90%;
        top: auto;
        left: auto;
        transform: unset; } }
    @media (max-width: 500px) {
      .pet__sec1__text {
        padding-bottom: 0; } }

.pet__sec2 {
  padding: 0 !important;
  position: relative;
  margin-bottom: 3rem; }
  @media (max-width: 1199px) {
    .pet__sec2 {
      margin-bottom: 0; } }
  .pet__sec2__bg img {
    width: 75%;
    height: auto;
    display: block;
    min-height: 500px;
    object-fit: cover;
    object-position: center; }
    @media (max-width: 1199px) {
      .pet__sec2__bg img {
        width: 100%;
        min-height: 240px; } }
  .pet__sec2__text {
    position: absolute;
    background: #fff;
    width: 52%;
    top: 50%;
    right: 5%;
    transform: translate(0%, -50%);
    padding: 2rem 4rem; }
    @media (max-width: 1199px) {
      .pet__sec2__text {
        margin: 0 auto;
        padding: 2rem 1rem;
        margin-top: -70px;
        position: relative;
        width: 90%;
        top: auto;
        right: auto;
        transform: unset; } }
    .pet__sec2__text h3 {
      font-size: 23px;
      margin-bottom: 10px; }
    .pet__sec2__text .btn {
      padding: 8px 25px;
      margin: 0;
      width: auto;
      border-radius: 0; }

.pet__faq {
  background: #F6F6F6;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding-top: 50px; }
  @media (max-width: 500px) {
    .pet__faq {
      margin-top: 80px; } }
  @media (max-width: 991px) {
    .pet__faq .d-flex {
      flex-direction: column; } }
  @media (max-width: 991px) {
    .pet__faq__col {
      margin: 0 auto;
      text-align: center;
      border-bottom: 1px solid #979797; } }
  .pet__faq__col:last-child {
    margin-left: 200px; }
    @media (max-width: 991px) {
      .pet__faq__col:last-child {
        margin: 0 auto;
        border-bottom: none; } }
  .pet__faq__each {
    padding: 30px 30px 30px 0px;
    width: 420px;
    border-bottom: 1px solid #979797;
    font-family: 'Montserrat-Bold'; }
    .pet__faq__each:last-child {
      border-bottom: none;
      margin-bottom: 10px; }
    @media (max-width: 500px) {
      .pet__faq__each {
        width: 100%; } }

@media (max-width: 500px) {
  .pet .accordion {
    margin-top: 1rem; }
    .pet .accordion::after {
      bottom: 0;
      font-size: 1.5rem;
      content: "+";
      line-height: 17px; }
    .pet .accordion.active::after {
      content: "-"; }
  .pet .panel {
    display: none; }
    .pet .panel.show {
      display: block; } }

.pet-info {
  text-align: center;
  max-width: 900px;
  margin: 3rem auto; }
  @media (max-width: 769px) {
    .pet-info {
      margin: 0rem auto 1rem; } }
  .pet-info .row .pet-info-col .card-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left; }
    .pet-info .row .pet-info-col .card-title p {
      margin: 0;
      padding: 0 10px; }

.pet__travel {
  padding: 10px 15px !important;
  padding: 0;
  text-align: center;
  border-top: 2px solid #DBDBDB;
  border-bottom: 2px solid #DBDBDB; }
  .pet__travel p {
    margin: 0; }

.pet-features {
  padding: 4rem 15px 2rem !important; }
  @media (max-width: 769px) {
    .pet-features {
      padding: 1rem 0 1.8rem !important; } }
  .pet-features .row .pet-features-col .card {
    border: 0;
    padding: 1.5rem; }
    @media (max-width: 769px) {
      .pet-features .row .pet-features-col .card {
        border-bottom: 3px solid #F1F1F1; } }
    .pet-features .row .pet-features-col .card-title {
      display: flex;
      align-items: center;
      flex-wrap: nowrap; }
      @media (max-width: 769px) {
        .pet-features .row .pet-features-col .card-title {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: 1.25rem auto; } }
      .pet-features .row .pet-features-col .card-title img {
        height: 40px;
        max-height: 40px;
        max-width: 70px;
        object-fit: contain; }
      .pet-features .row .pet-features-col .card-title h3 {
        padding: 0 15px;
        margin: 0; }
        @media (max-width: 769px) {
          .pet-features .row .pet-features-col .card-title h3 {
            font-size: 17px;
            margin: 1rem auto; } }
    @media (max-width: 769px) {
      .pet-features .row .pet-features-col .card-text {
        text-align: center; } }
    .pet-features .row .pet-features-col .card-text p {
      line-height: 28px; }
    .pet-features .row .pet-features-col .card-text a.card-link {
      font-weight: bold;
      color: #232019;
      line-height: 18px !important; }

@media (max-width: 769px) {
  .pet .questions {
    padding: 50px 15px; } }

.faq {
  padding-top: 30px; }
  .faq .accordion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer; }
    .faq .accordion::after {
      content: '+';
      float: right;
      font-size: 30px; }
    .faq .accordion.active::after {
      content: '-'; }
  .faq .panel {
    position: absolute;
    top: -9999px !important;
    left: -9999px !important; }
    .faq .panel.show {
      position: relative;
      top: 0 !important;
      left: 0 !important;
      margin-top: 30px; }
    .faq .panel b {
      font-family: 'Montserrat-Bold'; }
  @media (max-width: 500px) {
    .faq .line--thick {
      border-top: 3px solid #F1F1F1; } }
  .faq__search {
    text-align: center;
    margin-bottom: 50px; }
    .faq__search input[type=text] {
      padding: 20px;
      width: 100%;
      background-image: url("/assets/images/magnifying.png");
      background-repeat: no-repeat;
      background-size: 10%;
      background-position: 95% center; }
  .faq__each {
    background: #F6F6F6;
    padding: 30px 100px;
    margin: 0 100px 5px; }
    @media (max-width: 1199px) {
      .faq__each {
        padding: 30px;
        margin: 0 0px 5px; } }
    .faq__each .sub-title {
      margin-bottom: 0;
      padding-right: 50px;
      text-transform: initial;
      letter-spacing: 0; }
      @media (max-width: 1199px) {
        .faq__each .sub-title {
          margin: 0; } }
      @media (max-width: 500px) {
        .faq__each .sub-title {
          font-size: 15px;
          padding-right: 20px; } }

@media (max-width: 500px) {
  .why h1 {
    font-size: 27px; } }

.why p {
  letter-spacing: 0.7px;
  line-height: 32px; }

@media (max-width: 1199px) {
  .why .line--thin {
    display: none !important; } }

.why__banner-main {
  position: relative;
  height: 500px;
  margin-bottom: 70px; }
  @media (max-width: 1199px) {
    .why__banner-main {
      height: auto;
      margin-bottom: -100px; } }
  .why__banner-main__bg {
    background-image: url("/assets/images/why/banner1.png");
    position: absolute;
    background-size: 1650px 100%;
    background-position: right center;
    background-repeat: no-repeat;
    display: inline-block;
    max-width: 1920px;
    width: 100vw;
    height: 500px;
    right: -160px;
    z-index: -1; }
    @media (max-width: 1199px) {
      .why__banner-main__bg {
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background-size: cover;
        background-position: center; } }
    @media (max-width: 500px) {
      .why__banner-main__bg {
        background-image: url("/assets/images/why/banner1-mobile.png");
        height: 300px; } }
  .why__banner-main__text {
    position: absolute;
    background: white;
    padding: 50px 50px 50px 0;
    width: 40%;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 1700px) {
      .why__banner-main__text {
        padding: 50px; } }
    @media (max-width: 1199px) {
      .why__banner-main__text {
        position: relative;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        padding: 30px;
        width: 80%; } }
    @media (max-width: 600px) {
      .why__banner-main__text {
        width: 90%; } }

.why__banner-left {
  position: relative;
  height: 400px;
  margin-bottom: 70px; }
  @media (max-width: 1199px) {
    .why__banner-left {
      height: auto;
      margin-bottom: -100px; } }
  .why__banner-left__bg {
    position: absolute;
    background-size: 700px 100%;
    background-position: left center;
    background-repeat: no-repeat;
    display: inline-block;
    width: 100%;
    height: 400px;
    z-index: -1; }
    @media (max-width: 1199px) {
      .why__banner-left__bg {
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background-size: cover;
        background-position: top;
        width: 100vw; } }
    @media (max-width: 500px) {
      .why__banner-left__bg {
        height: 300px; } }
  .why__banner-left__text {
    position: absolute;
    background: white;
    padding: 50px;
    width: 65%;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 1199px) {
      .why__banner-left__text {
        position: relative;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        padding: 30px;
        width: 80%; } }
    @media (max-width: 600px) {
      .why__banner-left__text {
        width: 90%; } }
  .why__banner-left__bg.executive {
    background-image: url("/assets/images/why/banner2.png"); }
  .why__banner-left__bg.professional {
    background-image: url("/assets/images/why/banner4.png"); }
    @media (max-width: 1199px) {
      .why__banner-left__bg.professional {
        background-position: center; } }
  .why__banner-left__text.professional {
    padding: 40px 50px; }
    @media (max-width: 600px) {
      .why__banner-left__text.professional {
        padding: 40px 20px; } }

.why .row h3 {
  display: flex;
  align-items: center; }

.why .row .accordion {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  @media (max-width: 991px) {
    .why .row .accordion {
      margin-bottom: 30px; } }
  .why .row .accordion img {
    height: 60px;
    margin-right: 20px; }

.why__banner-right {
  position: relative;
  height: 400px;
  margin-bottom: 50px;
  margin-top: 50px; }
  @media (max-width: 1199px) {
    .why__banner-right {
      height: auto;
      margin-bottom: -100px; } }
  .why__banner-right__bg {
    background-image: url("/assets/images/why/banner3.png");
    position: absolute;
    background-size: 700px 100%;
    background-position: right center;
    background-repeat: no-repeat;
    display: inline-block;
    width: 100%;
    height: 400px;
    z-index: -1; }
    @media (max-width: 1199px) {
      .why__banner-right__bg {
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background-size: cover;
        background-position: center;
        width: 100vw; } }
    @media (max-width: 500px) {
      .why__banner-right__bg {
        background-image: url("/assets/images/why/banner3-mobile.png");
        height: 300px; } }
  .why__banner-right__text {
    position: absolute;
    background: white;
    padding: 25px 50px 25px 0;
    width: 65%;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 1199px) {
      .why__banner-right__text {
        position: relative;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        padding: 30px;
        width: 80%; } }
    @media (max-width: 600px) {
      .why__banner-right__text {
        width: 90%; } }

.why__rewards {
  background-color: #F6F6F6;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative; }
  .why__rewards .container {
    max-width: 1700px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    overflow: hidden; }
    @media (max-width: 1199px) {
      .why__rewards .container {
        padding: 40px;
        flex-direction: column; } }
    @media (max-width: 600px) {
      .why__rewards .container {
        padding: 40px 0; } }
  .why__rewards__banner {
    width: 500px;
    border-radius: 50%;
    background: white;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
    margin-bottom: -100px;
    margin-left: 50px; }
    @media (max-width: 1199px) {
      .why__rewards__banner {
        justify-content: initial;
        align-items: center;
        padding-top: 50px;
        margin-bottom: -280px;
        margin-left: 50px;
        margin-top: 0; } }
    @media (max-width: 600px) {
      .why__rewards__banner {
        margin-left: 0; } }
    .why__rewards__banner h1 {
      font-family: 'Montserrat-SemiBold';
      font-size: 77px; }
    .why__rewards__banner h2 {
      font-family: 'Montserrat-Medium';
      font-size: 27px; }
  .why__rewards__text {
    width: 65%; }
    @media (max-width: 600px) {
      .why__rewards__text {
        text-align: center; } }

.why__etc {
  padding: 60px 0; }
  @media (max-width: 600px) {
    .why__etc {
      padding: 40px 0;
      border-top: 5px solid #F1F1F1; } }
  .why__etc ul {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 600px) {
      .why__etc ul {
        padding-inline-start: 0; } }
    .why__etc ul li {
      font-family: 'Montserrat-Medium';
      font-size: 15px;
      text-transform: initial;
      width: 30%;
      display: flex;
      align-items: center;
      padding: 10px; }
      @media (max-width: 767px) {
        .why__etc ul li {
          width: auto; } }
      .why__etc ul li::before {
        width: 50px; }

@media (max-width: 991px) {
  .why .col-lg-6 {
    border-bottom: 2px solid #DADADA;
    padding: 0 120px;
    margin-top: 30px; } }
  @media (max-width: 991px) and (max-width: 600px) {
    .why .col-lg-6 {
      padding: 0 40px; } }

@media (max-width: 991px) {
  .why .accordion {
    position: relative; }
    .why .accordion::after {
      content: "+";
      font-size: 25px;
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%); }
    .why .accordion.active::after {
      content: "-"; }
  .why .panel {
    display: none; }
    .why .panel.show {
      display: block; } }

.privacy {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 0 0; }
  .privacy h3.sub-title {
    text-transform: initial;
    padding: 5px 0;
    margin: 0; }
  .privacy p {
    text-transform: initial;
    letter-spacing: 0.7px;
    line-height: 32px; }
    .privacy p b {
      font-family: 'Montserrat-Bold'; }
  .privacy ul {
    padding-inline-start: 0; }
    .privacy ul li {
      margin: 0; }
  @media (max-width: 500px) {
    .privacy {
      padding: 10px; }
      .privacy .line--thick {
        border-top: 3px solid #F1F1F1; } }

.blogs {
  padding-top: 50px;
  padding-bottom: 30px;
  border-bottom: 4px solid #f1f1f1; }
  @media (max-width: 500px) {
    .blogs {
      padding-top: 30px;
      padding-bottom: 0px;
      border-bottom: 2px solid #f1f1f1; } }
  .blogs .sub-title {
    color: #000000;
    font-family: Montserrat;
    font-size: 17px;
    letter-spacing: 0.47px;
    line-height: 32px;
    text-align: center; }

.blog {
  min-height: 300px;
  margin: 25px 0; }
  .blog--img {
    width: 100%;
    height: auto;
    margin-bottom: 15px; }
  .blog--date {
    display: flex;
    align-items: center;
    color: #383838;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0.61px;
    line-height: 30px; }
    .blog--date .icon-calendar:before {
      margin-right: 8px; }
  .blog--title {
    color: #000000;
    font-family: "Montserrat-SemiBold";
    font-size: 18px;
    letter-spacing: 0.79px;
    line-height: 25px;
    margin: 10px 0; }
  .blog--detail {
    color: #232019;
    font-family: "Montserrat";
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 32px; }
  @media (max-width: 500px) {
    .blog {
      margin: 15px 0px; }
      .blog--img {
        margin-left: -10px;
        margin-right: -10px;
        width: calc(100% + 20px); } }
  .blog-latest {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    position: relative;
    margin: 40px 0 10px;
    margin-top: 40px;
    min-height: initial; }
    .blog-latest .blog--img {
      height: auto; }
    .blog-latest .blog--box {
      background: white;
      padding: 16px 35px;
      transform: translateY(-100px);
      margin-bottom: -100px; }
      @media screen and (min-width: 501px) and (max-width: 1100px) {
        .blog-latest .blog--box {
          margin-left: 20px;
          margin-right: 20px; } }
      .blog-latest .blog--box .blog--title {
        margin: 20px 0 15px; }
    @media (min-width: 1100px) {
      .blog-latest .blog--img {
        width: 49.5%;
        margin-left: 0; }
      .blog-latest .blog--box {
        padding: 30px 58px;
        width: 60%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); } }

@media (max-width: 1190px) {
  .blogPage .line--thin {
    display: none; } }

.blogPage .img-container {
  max-height: 40vh;
  overflow-y: hidden;
  margin-left: -50px;
  margin-right: -50px;
  width: calc(100% + 100px);
  max-width: 1920px; }

.blogPage .blogBox {
  max-width: 1091px;
  margin: 0 auto;
  transform: translateY(-90px);
  background: white;
  padding: 45px 25px 95px; }
  .blogPage .blogBox--img {
    width: 100%;
    transform: translateY(-50%); }
  .blogPage .blogBox--title {
    font-size: 35px; }
    @media (max-width: 480px) {
      .blogPage .blogBox--title {
        font-size: 25px; } }
  .blogPage .blogBox--date {
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 32px;
    margin: 15px 0 30px;
    display: flex;
    align-items: center; }
    @media (max-width: 600px) {
      .blogPage .blogBox--date {
        margin: 12px 0 10px; } }
  .blogPage .blogBox .blog--title {
    margin-top: 40px;
    margin-bottom: 15px; }
  .blogPage .blogBox .blog--detail {
    margin-bottom: 30px; }

.blogPage .also-divider {
  width: 100%;
  text-align: center;
  font-family: "Montserrat-SemiBold";
  font-size: 19px;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.83px;
  line-height: 30px;
  border-top: 2px solid #979797;
  padding-top: 30px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: -90px; }

.blogPage .btn-fluid {
  margin-bottom: 40px; }

@media (max-width: 640px) {
  .blogPage .img-container {
    max-height: 100%; }
    .blogPage .img-container .blogBox--img {
      transform: none; }
  .blogPage .blogBox {
    transform: none;
    padding: 20px 15px 95px; } }

.blogPage #blog-carousel .item {
  text-align: left; }

.blogPage #blog-carousel img {
  margin-bottom: 15px; }

@media screen and (min-width: 991px) {
  .blogPage #blog-carousel .owl-item.cloned {
    display: none !important; }
  .blogPage #blog-carousel .owl-stage {
    transform: none !important;
    transition: none !important;
    width: auto !important;
    display: flex; }
  .blogPage #blog-carousel .owl-item {
    width: auto !important;
    margin-right: 18px !important; }
  .blogPage #blog-carousel .owl-dots {
    display: none; } }

.blogPage #blog-carousel .owl-item:last-child {
  margin-right: 0px !important; }

.blogPage #blog-carousel .owl-dots {
  margin-top: 12px;
  margin-bottom: 40px; }

@media (max-width: 500px) {
  .indivSol h3.sub-title {
    margin: 10px auto;
    line-height: 25px;
    width: auto; } }

.indivSol__banner {
  position: relative;
  height: 500px;
  margin-bottom: 70px; }
  @media (max-width: 1199px) {
    .indivSol__banner {
      height: auto; } }
  @media (max-width: 600px) {
    .indivSol__banner {
      margin-bottom: 20px; } }
  .indivSol__banner__bg {
    position: absolute;
    background-size: 1650px 100%;
    background-position: right center;
    background-repeat: no-repeat;
    display: inline-block;
    max-width: 1920px;
    width: 100vw;
    height: 500px;
    right: -160px;
    z-index: -1; }
    @media (max-width: 1199px) {
      .indivSol__banner__bg {
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background-size: cover;
        background-position: 70%; } }
    @media (max-width: 500px) {
      .indivSol__banner__bg {
        height: 300px; } }
    .indivSol__banner__bg.short-term {
      background-image: url("/assets/images/solution/indiv-short-term.png"); }
      @media (max-width: 600px) {
        .indivSol__banner__bg.short-term {
          background-image: url("/assets/images/solution/short-term-mobile.png"); } }
    .indivSol__banner__bg.government {
      background-image: url("/assets/images/solution/indiv-military.png"); }
      @media (max-width: 600px) {
        .indivSol__banner__bg.government {
          background-image: url("/assets/images/solution/military-mobile.png"); } }
    .indivSol__banner__bg.entertainment {
      background-image: url("/assets/images/solution/indiv-entertainment.png"); }
      @media (max-width: 600px) {
        .indivSol__banner__bg.entertainment {
          background-image: url("/assets/images/solution/entertainment-mobile.png"); } }
    .indivSol__banner__bg.medical {
      background-image: url("/assets/images/solution/indiv-medical.png"); }
      @media (max-width: 600px) {
        .indivSol__banner__bg.medical {
          background-image: url("/assets/images/solution/medical-mobile.png"); } }
    .indivSol__banner__bg.insurance {
      background-image: url("/assets/images/solution/indiv-insurance.png"); }
      @media (max-width: 600px) {
        .indivSol__banner__bg.insurance {
          background-image: url("/assets/images/solution/insurance-mobile.png"); } }
    .indivSol__banner__bg.vacation {
      background-image: url("/assets/images/solution/indiv-vacation.png"); }
      @media (max-width: 600px) {
        .indivSol__banner__bg.vacation {
          background-image: url("/assets/images/solution/vacation-mobile.png"); } }
    .indivSol__banner__bg.work {
      background-image: url("/assets/images/solution/indiv-work.png"); }
      @media (max-width: 600px) {
        .indivSol__banner__bg.work {
          background-image: url("/assets/images/solution/corporate-mobile.png"); } }
  .indivSol__banner__text {
    max-width: 650px;
    width: 100%;
    position: absolute;
    bottom: -50px;
    background: white;
    padding: 30px 100px 0 0; }
    @media (max-width: 1199px) {
      .indivSol__banner__text {
        max-width: none;
        width: 90%;
        margin: -100px auto 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 10px 20px; } }
    @media (max-width: 600px) {
      .indivSol__banner__text {
        position: relative;
        bottom: -20px;
        padding: 30px 20px 10px;
        max-width: none;
        width: 95%;
        margin: -100px auto 0;
        left: unset;
        transform: unset; } }
    .indivSol__banner__text .page-title {
      text-align: left; }
      @media (max-width: 600px) {
        .indivSol__banner__text .page-title {
          margin: 0;
          width: auto; } }
    .indivSol__banner__text .sub-title {
      text-align: left;
      font-size: 16px !important;
      letter-spacing: 0.7px;
      line-height: 32px;
      text-transform: initial;
      margin-bottom: 0; }
      @media (max-width: 600px) {
        .indivSol__banner__text .sub-title {
          margin: 0;
          width: auto; } }

.indivSol__body {
  width: 80%; }
  @media (max-width: 1199px) {
    .indivSol__body {
      width: 90%;
      margin: 0 auto;
      padding: 0 20px; } }
  @media (max-width: 500px) {
    .indivSol__body {
      padding: 0 10px; } }

.indivSol__half {
  margin: 80px 0;
  display: flex;
  align-items: center; }
  @media (max-width: 991px) {
    .indivSol__half {
      margin: 50px 0 0; } }
  .indivSol__half.work {
    margin: 60px 0 80px; }
  .indivSol__half .col-lg-6, .indivSol__half .col-lg-7 {
    padding: 0; }
    .indivSol__half .col-lg-6:first-child, .indivSol__half .col-lg-7:first-child {
      padding-right: 50px; }
      @media (max-width: 1199px) {
        .indivSol__half .col-lg-6:first-child, .indivSol__half .col-lg-7:first-child {
          padding: 0 40px 20px; } }
  .indivSol__half .discover {
    background: #F6F6F6;
    padding: 40px; }
    @media (max-width: 500px) {
      .indivSol__half .discover {
        padding: 20px; } }
    .indivSol__half .discover li a {
      text-decoration: underline; }
    .indivSol__half .discover li p, .indivSol__half .discover li a {
      font-family: 'MONTSERRAT-MEDIUM'; }
    .indivSol__half .discover .sub-title {
      line-height: 30px; }
      @media (max-width: 500px) {
        .indivSol__half .discover .sub-title {
          margin: 0; } }

.indivSol .two-col__title {
  margin-top: 70px;
  margin-bottom: 30px; }
  @media (max-width: 768px) {
    .indivSol .two-col__title {
      margin-top: 20px; } }

.indivSol__two-col p {
  line-height: 32px;
  margin-bottom: 10px; }

@media (max-width: 768px) {
  .indivSol__two-col {
    background: #F6F6F6;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding: 25px 30px;
    margin-top: 50px; }
    .indivSol__two-col.insurance {
      margin-top: 30px; }
    .indivSol__two-col .accordion {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer; }
      .indivSol__two-col .accordion::after {
        content: '+';
        float: right;
        font-size: 30px; }
      .indivSol__two-col .accordion.active::after {
        content: '-'; }
      .indivSol__two-col .accordion .sub-title {
        margin: 10px 0; }
    .indivSol__two-col .panel {
      display: none; }
      .indivSol__two-col .panel.show {
        display: block; } }

@media (max-width: 500px) {
  .indivSol__two-col .sub-title {
    margin: 0; } }

.indivSol__half ul, .indivSol__two-col ul {
  padding-inline-start: 0;
  margin-left: -15px; }
  .indivSol__half ul li, .indivSol__two-col ul li {
    text-transform: initial;
    margin-bottom: 10px;
    font-size: 15px;
    display: flex; }
    .indivSol__half ul li:before, .indivSol__two-col ul li:before {
      content: "·";
      vertical-align: text-bottom;
      margin-right: 20px; }
    .indivSol__half ul li p, .indivSol__two-col ul li p {
      margin-bottom: 0; }

.indivSol__half ul.inline li:before, .indivSol__two-col ul.inline li:before {
  margin: 4px 20px 0 0; }

.indivSol__banner-left {
  position: relative;
  height: 500px;
  margin-top: 100px; }
  .indivSol__banner-left.insurance {
    margin-top: 50px;
    margin-bottom: 80px; }
  @media (max-width: 1199px) {
    .indivSol__banner-left {
      height: auto;
      margin-bottom: -150px;
      margin-top: 0; }
      .indivSol__banner-left.insurance {
        margin-bottom: -80px; } }
  .indivSol__banner-left__bg {
    background-image: url("/assets/images/solution/short-term-banner2.png");
    background-size: 1350px;
    background-position: top left;
    background-repeat: no-repeat;
    display: inline-block;
    max-width: 1920px;
    width: 100vw;
    height: 500px;
    position: absolute;
    left: -130px;
    z-index: -1; }
    .indivSol__banner-left__bg.medical {
      background-image: url("/assets/images/solution/medical-banner2.png"); }
      @media (max-width: 1199px) {
        .indivSol__banner-left__bg.medical {
          background-position: 25%; } }
    @media (max-width: 1199px) {
      .indivSol__banner-left__bg {
        background-position: center;
        background-size: cover;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw; } }
    @media (max-width: 500px) {
      .indivSol__banner-left__bg {
        background-size: cover;
        height: 400px;
        margin-top: 0; } }
  .indivSol__banner-left__text {
    background: white;
    padding: 70px 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    max-width: 900px;
    width: 100%; }
    @media (max-width: 1199px) {
      .indivSol__banner-left__text {
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        top: -100px; } }
    .indivSol__banner-left__text.insurance {
      max-width: 1250px; }
      @media (max-width: 1500px) {
        .indivSol__banner-left__text.insurance {
          max-width: 85%;
          padding: 40px; } }
      .indivSol__banner-left__text.insurance .row p {
        margin-bottom: 10px;
        line-height: 21px; }
    .indivSol__banner-left__text.work {
      max-width: 1100px;
      padding: 40px; }
      @media (max-width: 600px) {
        .indivSol__banner-left__text.work {
          padding: 30px; } }
    @media (max-width: 600px) {
      .indivSol__banner-left__text {
        position: relative;
        padding: 30px;
        width: 95%; } }
    @media (max-width: 500px) {
      .indivSol__banner-left__text h3 {
        margin-bottom: 20px; } }

.indivSol__banner-right {
  position: relative;
  height: 500px; }
  @media (max-width: 1199px) {
    .indivSol__banner-right {
      height: auto;
      margin-bottom: -100px; } }
  @media (max-width: 500px) {
    .indivSol__banner-right {
      margin-bottom: 0; } }
  .indivSol__banner-right__text {
    background: white;
    padding: 50px 50px 50px 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    max-width: 850px;
    width: 100%;
    text-align: left; }
    @media (max-width: 1199px) {
      .indivSol__banner-right__text {
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        top: -100px;
        padding: 50px 50px 0; } }
    @media (max-width: 500px) {
      .indivSol__banner-right__text {
        position: relative;
        margin-top: -150px;
        padding: 30px;
        top: 35px;
        width: 95%; } }
  .indivSol__banner-right__bg {
    background-image: url("/assets/images/solution/insurance-banner2.png");
    background-size: 1300px;
    background-position: center right;
    background-repeat: no-repeat;
    display: inline-block;
    max-width: 1920px;
    width: 100vw;
    height: 500px;
    z-index: -1;
    position: absolute;
    right: -125px; }
    .indivSol__banner-right__bg.work {
      background-image: url("/assets/images/solution/work-banner2.png"); }
    @media (max-width: 1199px) {
      .indivSol__banner-right__bg {
        background-position: 75%;
        background-size: cover;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw; } }
    @media (max-width: 500px) {
      .indivSol__banner-right__bg {
        background-size: cover;
        height: 400px;
        margin-top: 0; } }

.indivSol__top-rated {
  padding: 60px 0 40px; }
  .indivSol__top-rated .view-all {
    display: none; }
    @media (max-width: 500px) {
      .indivSol__top-rated .view-all {
        display: block;
        background-color: #F8F8F8;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        width: 100vw;
        padding: 10px;
        margin-top: 10px;
        text-align: center; }
        .indivSol__top-rated .view-all a {
          font-family: 'Montserrat-SemiBold'; } }

.indivSol__tips {
  background: #efefef;
  padding: 50px 150px; }
  @media (max-width: 1199px) {
    .indivSol__tips {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      padding: 50px 30px; } }
  .indivSol__tips h3.text-center {
    margin-bottom: 30px; }
  .indivSol__tips__each {
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 20px 0; }
    @media (max-width: 500px) {
      .indivSol__tips__each {
        flex-direction: column; } }
    .indivSol__tips__each img {
      margin-right: 20px; }
      @media (max-width: 500px) {
        .indivSol__tips__each img {
          margin: -10px auto 10px; } }

.medical .indivSol__two-col {
  padding: 100px 0 20px; }
  @media (max-width: 1199px) {
    .medical .indivSol__two-col {
      padding: 40px 0 0; } }
  @media (max-width: 768px) {
    .medical .indivSol__two-col {
      padding: 30px; } }

@media (max-width: 1199px) {
  .medical .indivSol__body {
    padding: 0 20px 50px; } }
