label.checkbox {
    display:flex;
    align-items: center;
}

label.checkbox input[type=checkbox],
label.checkbox input[type=radio] {display:none;}

label.checkbox span {
  display:inline-block;
  border: 2px solid #A5A5A5;
  border-radius:50%;
  width:15px;
  height:15px;
  background:#fff;
  vertical-align:middle;
  margin:0 5px 0 20px;
  position: relative;
  transition:width 0.1s, height 0.1s, margin 0.1s;
}

label.checkbox :checked + span {
  background:#FFC85A;
  width:15px;
  height:15px;
}
