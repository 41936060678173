.link{
  color: inherit;
  text-decoration: underline;
  &:hover{
    color: inherit;
  }
}

.mail-link{
  color: inherit;
  text-decoration: none;
  &:hover{
    color: inherit;
  }
}

.secret-link{
  color: inherit;
  text-decoration: none;
  word-break: break-word;  
  &:hover{
    color: inherit;
    text-decoration: none;
  }
}

.flexbox {
  display: flex;
  justify-content: center;
  text-align: center;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  &__each {
    padding: 20px;
  }
}

.container-fluid {
    background-color: #EEEEEE;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    text-align: center;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.mobile-only-divider {
  @media (max-width: 616px) {
    border-top: 2px solid #DBDBDB;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 50px;
  }
}

.line{
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 20px;

  &--thin {
    border-top: 1px solid #DDDDDD;   

    @media (max-width: 1199px) {
      display: none;
    }

    // @media (max-width: 1199px) {
    //   display: block;
    //   border: none;
    // }
  }

  &--medium {
    border-top: 2px solid #DBDBDB;
  }

  &--thick {
    border-top: 3px solid #F1F1F1;

    @media (max-width: 500px) {
      border: none;
    }
  }
}

.c-col {
  display: flex;
  flex-direction: column;
}

.c-row {
  display: flex;
}

.img-hover-zoom {
  overflow: hidden;
  border-radius: 50%;

  img {
    transition: transform .5s ease;
  }

  &:hover img {
    transform: scale(1.2);
  }
}

.greyout-bg {
    display: none;

    &.active {
      @media (max-width: 1199px) {
        position: fixed;
        display: block;
        height: 100vh;
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background: #ebebeb;
        opacity: 0.9;
        top: 0;
        z-index: 99;
      }
    }

}

.max-width-revert {
  right: 50%;
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.max-width-1100 {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}

.max-width-700 {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}