.why {

    h1 {
        @media (max-width: 500px) {
            font-size: 27px;
        }
    }
    p {
        letter-spacing: 0.7px;
        line-height: 32px;
    }

    .line--thin {
        @media (max-width: 1199px) {
            display: none !important;
        }
    }

    &__banner-main {
        position: relative;
        height: 500px;
        margin-bottom: 70px;

        @media (max-width: 1199px) {
            height: auto;
            margin-bottom: -100px;
        }

        &__bg {
            background-image: url('/assets/images/why/banner1.png');  
            position: absolute;
            background-size: 1650px 100%;
            background-position: right center;
            background-repeat: no-repeat;
            display: inline-block;
            max-width: 1920px;
            width: 100vw;
            height: 500px;
            right: -160px;
            z-index: -1;

            @media (max-width: 1199px) {
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                background-size: cover;
                background-position: center;
            }
            @media (max-width: 500px) {
                background-image: url('/assets/images/why/banner1-mobile.png');
                height: 300px;                
            } 
        }

        &__text {
            position: absolute;
            background: white;
            padding: 50px 50px 50px 0;
            width: 40%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 1700px) {
                padding: 50px;
            }

            @media (max-width: 1199px){
                position: relative;
                top: -100px;
                left: 50%;
                transform: translateX(-50%);
                padding: 30px;
                width: 80%;
            }

            @media (max-width: 600px) {
                width: 90%;
            }
        }
    }

    &__banner-left {
        position: relative;
        height: 400px;
        margin-bottom: 70px;

        @media (max-width: 1199px) {
            height: auto;
            margin-bottom: -100px;
        }

        &__bg {
            position: absolute;
            background-size: 700px 100%;
            background-position: left center;
            background-repeat: no-repeat;
            display: inline-block;
            width: 100%;
            height: 400px;
            z-index: -1;

            @media (max-width: 1199px) {
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                background-size: cover;
                background-position: top;
                width: 100vw;
            }

            @media (max-width: 500px) {
                height: 300px;                
            }
        }

        &__text {
            position: absolute;
            background: white;
            padding: 50px;
            width: 65%;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 1199px){
                position: relative;
                top: -100px;
                left: 50%;
                transform: translateX(-50%);
                padding: 30px;
                width: 80%;
            }

            @media (max-width: 600px) {
                width: 90%;
            }
        }

        &__bg.executive {
            background-image: url('/assets/images/why/banner2.png');  
        }

        &__bg.professional {
            background-image: url('/assets/images/why/banner4.png');  

            @media (max-width: 1199px) {
                background-position: center;
            }
        }

        &__text.professional {
            padding: 40px 50px;

            @media (max-width: 600px) {
                padding: 40px 20px;
            }
            
        }
    }

    .row {
        h3 {
            display: flex;
            align-items: center;
        }

        .accordion {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            @media (max-width: 991px) {
                margin-bottom: 30px;
            }

            img {
                height: 60px;
                margin-right: 20px;
            }
        }
    }

    &__banner-right {
        position: relative;
        height: 400px;
        margin-bottom: 50px;
        margin-top: 50px;

        @media (max-width: 1199px) {
            height: auto;
            margin-bottom: -100px;
        }

        &__bg {
            background-image: url('/assets/images/why/banner3.png');  
            position: absolute;
            background-size: 700px 100%;
            background-position: right center;
            background-repeat: no-repeat;
            display: inline-block;
            width: 100%;
            height: 400px;
            z-index: -1;

            @media (max-width: 1199px) {
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                background-size: cover;
                background-position: center;
                width: 100vw;
            }

            @media (max-width: 500px) {
                background-image: url('/assets/images/why/banner3-mobile.png');
                height: 300px;
            } 


        }

        &__text {
            position: absolute;
            background: white;
            padding: 25px 50px 25px 0;
            width: 65%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 1199px){
                position: relative;
                top: -100px;
                left: 50%;
                transform: translateX(-50%);
                padding: 30px;
                width: 80%;
            }

            @media (max-width: 600px) {
                width: 90%;
            }
        }
    }

    &__rewards {
        background-color: #F6F6F6;
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        position: relative;

        .container {
            max-width: 1700px;
            display: flex;
            align-items: center;
            padding: 0 30px;
            overflow: hidden;

            @media (max-width: 1199px) {
                padding: 40px;
                flex-direction: column;
            }

            @media (max-width: 600px) {
                padding: 40px 0;
            }
        }

        &__banner {
            width: 500px;
            border-radius: 50%;
            background: white;
            height: 500px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -50px;
            margin-bottom: -100px;
            margin-left: 50px;

            @media (max-width: 1199px) {
                justify-content: initial;
                align-items: center;
                padding-top: 50px;
                margin-bottom: -280px;
                margin-left: 50px;
                margin-top: 0;
            }

            @media (max-width: 600px) {
                margin-left: 0;
            }

            h1 {
                font-family: 'Montserrat-SemiBold';
                font-size: 77px;
            }

            h2 {
                font-family: 'Montserrat-Medium';
                font-size: 27px;
            }
        }

        &__text {
            width: 65%;

            @media (max-width: 600px) {
                text-align: center;
            }
        }
    }

    &__etc {
        padding: 60px 0;

        @media (max-width: 600px) {
            padding: 40px 0;
            border-top: 5px solid #F1F1F1;
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 600px) {
                padding-inline-start: 0;
            }

            li {
                font-family: 'Montserrat-Medium';
                font-size: 15px;
                text-transform: initial;
                width: 30%;
                display: flex;
                align-items: center;
                padding: 10px;

                @media (max-width: 767px) {
                    width: auto;
                }

                &::before {
                    width: 50px;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .col-lg-6 {
            border-bottom: 2px solid #DADADA;
            padding: 0 120px;
            margin-top: 30px;

            @media (max-width: 600px) {
                padding: 0 40px;
            }
        }

        .accordion {
            position: relative;
          
            &::after {
                content: "+";
                font-size: 25px;
                position: absolute;
                bottom: -25px;
                left: 50%;
                transform: translateX(-50%);
            }
    
            &.active {    
                &::after {
                    content: "-";
                }
            }
        }
    
        .panel {
            display: none;
        
            &.show {
                display: block;
            }
        }
        
    }
    
}