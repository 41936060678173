.indivSol {
    h3.sub-title {
        @media (max-width: 500px) {
            margin: 10px auto;
            line-height: 25px;
            width: auto;
        }
    }

    &__banner {
        position: relative;
        height: 500px;
        margin-bottom: 70px;

        @media (max-width: 1199px) {
            height: auto;
        }

        @media (max-width: 600px) {
            margin-bottom: 20px; 
        }

        &__bg {
            position: absolute;
            background-size: 1650px 100%;
            background-position: right center;
            background-repeat: no-repeat;
            display: inline-block;
            max-width: 1920px;
            width: 100vw;
            height: 500px;
            right: -160px;
            z-index: -1;

            @media (max-width: 1199px) {
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                background-size: cover;
                background-position: 70%;
            }
            @media (max-width: 500px) {
                height: 300px;                
            }
            
            &.short-term {
                background-image: url('/assets/images/solution/indiv-short-term.png');  

                @media (max-width: 600px) {
                    background-image: url('/assets/images/solution/short-term-mobile.png');
                }
            }

            &.government {
                background-image: url('/assets/images/solution/indiv-military.png');  

                @media (max-width: 600px) {
                    background-image: url('/assets/images/solution/military-mobile.png');
                }
            }

            &.entertainment {
                background-image: url('/assets/images/solution/indiv-entertainment.png');  

                @media (max-width: 600px) {
                    background-image: url('/assets/images/solution/entertainment-mobile.png');
                }
            }

            &.medical {
                background-image: url('/assets/images/solution/indiv-medical.png');  

                @media (max-width: 600px) {
                    background-image: url('/assets/images/solution/medical-mobile.png');
                }
            }

            &.insurance {
                background-image: url('/assets/images/solution/indiv-insurance.png');  

                @media (max-width: 600px) {
                    background-image: url('/assets/images/solution/insurance-mobile.png');
                }
            }

            &.vacation {
                background-image: url('/assets/images/solution/indiv-vacation.png');  

                @media (max-width: 600px) {
                    background-image: url('/assets/images/solution/vacation-mobile.png');
                }
            }

            &.work {
                background-image: url('/assets/images/solution/indiv-work.png');  

                @media (max-width: 600px) {
                    background-image: url('/assets/images/solution/corporate-mobile.png');
                }
            }
        }

        &__text {
            max-width: 650px;
            width: 100%;
            position: absolute;
            bottom: -50px;
            background: white;
            padding: 30px 100px 0 0;

            @media (max-width: 1199px) {
                max-width: none;
                width: 90%;
                margin: -100px auto 0;
                left: 50%;
                transform: translateX(-50%);
                padding: 10px 20px;
            }

            @media (max-width: 600px) {
                position: relative;
                bottom: -20px;
                padding: 30px 20px 10px;
                max-width: none;
                width: 95%;
                margin: -100px auto 0;
                left: unset;
                transform: unset;
            }

            .page-title {
                text-align: left;

                @media (max-width: 600px) {
                    margin: 0;
                    width: auto;
                }
            }

            .sub-title {
                text-align: left;
                font-size: 16px !important;
                letter-spacing: 0.7px;
                line-height: 32px;
                text-transform: initial;
                margin-bottom: 0;

                @media (max-width: 600px) {
                    margin: 0;
                    width: auto;
                }
            }
        }
    }

    &__body {
        width: 80%;
        
        @media (max-width: 1199px){
            width: 90%;
            margin: 0 auto;
            padding: 0 20px;
        }

        @media (max-width: 500px) {
            padding: 0 10px;
        }
    }

    &__half {
        margin: 80px 0;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            margin: 50px 0 0;
        }

        &.work {
            margin: 60px 0 80px;
        }

        .col-lg-6, .col-lg-7 {
            padding: 0;

            &:first-child {
                padding-right: 50px;

                @media (max-width: 1199px) {
                    padding: 0 40px 20px;
                }
            }
        }

        .discover {
            background: #F6F6F6;
            padding: 40px;

            @media (max-width: 500px) {
                padding: 20px;
            }

            li a {
                text-decoration: underline;
            }

            li p, li a {
                font-family: 'MONTSERRAT-MEDIUM';
            }

            .sub-title {
                line-height: 30px;

                @media (max-width: 500px) {
                    margin: 0;
                }
            }
        }
    }

    .two-col__title {
        margin-top: 70px;
        margin-bottom: 30px;

        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }

    &__two-col {

        p {
            line-height: 32px;
            margin-bottom: 10px;
        }

        @media (max-width: 768px) {
            background: #F6F6F6;
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            padding: 25px 30px;
            margin-top: 50px;

            &.insurance {
                margin-top: 30px;
            }

            .accordion {
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                
            
                &::after {
                    content: '+';
                    float: right;
                    font-size: 30px;
                }

                &.active {    
                    &::after {
                        content: '-';
                    }
                }

                .sub-title {
                    margin: 10px 0;
                }
            }

            .panel {
                display: none;

                &.show {
                    display: block;
                }
            }
        }

        @media (max-width: 500px) {
            .sub-title {
                margin: 0;
            }
        }
    }

    &__half,
    &__two-col {
        ul {
            padding-inline-start: 0;
            margin-left: -15px;

            li {
                text-transform: initial;
                margin-bottom: 10px;
                font-size: 15px;
                display: flex;

                &:before {
                    content:"·";
                    vertical-align: text-bottom;
                    margin-right: 20px;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }

        ul.inline li:before {
            margin: 4px 20px 0 0;
        }
    }

    &__banner-left {
        position: relative; 
        height: 500px;
        margin-top: 100px;

        &.insurance {
            margin-top: 50px;
            margin-bottom: 80px;
        }

        @media (max-width: 1199px) {
            height: auto;
            margin-bottom: -150px;
            margin-top: 0;

            &.insurance {
                margin-bottom: -80px;
            }
        }

        &__bg {
            background-image: url('/assets/images/solution/short-term-banner2.png');            
            background-size: 1350px;
            background-position: top left;
            background-repeat: no-repeat;
            display: inline-block;
            max-width: 1920px;
            width: 100vw;
            height: 500px;
            position: absolute;
            left: -130px;
            z-index: -1;

            &.medical {
                background-image: url('/assets/images/solution/medical-banner2.png');
                
                @media (max-width: 1199px) {
                    background-position: 25%;
                }
            }

            @media (max-width: 1199px) {
                background-position: center;     
                background-size: cover;
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;               
            }

            @media (max-width: 500px) {
                background-size: cover;                
                height: 400px;
                margin-top: 0;
            }
        }

        &__text {
            background: white;
            padding: 70px 40px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            max-width: 900px;
            width: 100%;

            @media (max-width: 1199px) {
                left: 50%;
                transform: translateX(-50%);
                position: relative;
                top: -100px;
            }

            &.insurance {
                max-width: 1250px;

                @media (max-width: 1500px) {
                    max-width: 85%;
                    padding: 40px;
                }

                .row p {
                    margin-bottom: 10px;
                    line-height: 21px;
                }
            }

            &.work {
                max-width: 1100px;
                padding: 40px;

                @media (max-width: 600px) {
                    padding: 30px;
                }
            }

            @media (max-width: 600px) {
                position: relative;
                padding: 30px;
                width: 95%;
            }

            h3 {
                @media (max-width: 500px) {
                    margin-bottom: 20px;
                }
            }
        }
    }

    &__banner-right {
        position: relative;
        height: 500px;

        @media (max-width: 1199px ){
            height: auto;
            margin-bottom: -100px;
        }

        @media (max-width: 500px ){
            margin-bottom: 0;
        }

        &__text {
            background: white;
            padding: 50px 50px 50px 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            max-width: 850px;
            width: 100%;
            text-align: left;


            @media (max-width: 1199px) {
                left: 50%;
                transform: translateX(-50%);
                position: relative;
                top: -100px;
                padding: 50px 50px 0;
            }

            @media (max-width: 500px) {
                position: relative;
                margin-top: -150px;
                padding: 30px;
                top: 35px;
                width: 95%;
            }
        }

        &__bg {
            background-image: url('/assets/images/solution/insurance-banner2.png');            
            background-size: 1300px;
            background-position: center right;
            background-repeat: no-repeat;
            display: inline-block;
            max-width: 1920px;
            width: 100vw;
            height: 500px;
            z-index: -1;
            position: absolute;
            right: -125px;

            &.work {
                background-image: url('/assets/images/solution/work-banner2.png');             
            }


            @media (max-width: 1199px) {
                background-position: 75%; 
                background-size: cover;   
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;                            
            }

            @media (max-width: 500px) {
                background-size: cover;                
                height: 400px;
                margin-top: 0;
            }
        }
    }

    &__top-rated {
        padding: 60px 0 40px;

        .view-all {
            display: none;
            @media (max-width: 500px) {
                display: block;
                background-color: #F8F8F8;
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                width: 100vw;
                padding: 10px;
                margin-top: 10px;
                text-align: center;
    
                a {
                    font-family: 'Montserrat-SemiBold';
                }
            }
            
        }
    }

    &__tips {
        background: #efefef;
        padding: 50px 150px;

        @media (max-width: 1199px) {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            padding: 50px 30px;
        }

        h3.text-center {
            margin-bottom: 30px;
        }

        &__each {
            display: flex;
            justify-content: center;
            align-items: start;
            padding: 20px 0;

            @media (max-width: 500px) {
                flex-direction: column;
            }

            img {
                margin-right: 20px;

                @media (max-width: 500px) {
                    margin: -10px auto 10px;
                }
            }
        }
    }
}

.medical {
    .indivSol__two-col {
        padding: 100px 0 20px;

        @media (max-width: 1199px) {
            padding: 40px 0 0;
        }

        @media (max-width: 768px) {
            padding: 30px;
        }
    }

    .indivSol__body {
        @media (max-width: 1199px) {
            padding: 0 20px 50px;
        }
    }
}