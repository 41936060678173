.blogPage {

    .line--thin {
        @media(max-width: 1190px) {
            display: none;
        }
    }

    .img-container {
        max-height: 40vh;
        overflow-y: hidden;
        margin-left: -50px;
        margin-right: -50px;
        width: calc(100% + 100px);
        max-width: 1920px;
    }
    .blogBox {
        max-width: 1091px;
        margin: 0 auto;
        transform: translateY(-90px);
        background: white;
        padding: 45px 25px 95px;

        &--img {
            width:100%;
            transform: translateY(-50%);
        }

        &--title {
            font-size: 35px;  
            
            @media(max-width: 480px) {
                font-size: 25px;
            }
        }
        &--date {
            font-size: 16px;
            letter-spacing: 0.7px;
            line-height: 32px;
            margin:15px 0 30px;
            display: flex;
            align-items: center;

            @media(max-width: 600px) {
                margin:12px 0 10px;
            }
        }
        
        .blog--title {
            margin-top: 40px;
            margin-bottom: 15px;
        }

        .blog--detail {
            margin-bottom: 30px;
        }
    }


    .also-divider {
        width: 100%;
        text-align: center;
        font-family: "Montserrat-SemiBold";
        font-size: 19px;
        color: black;
        text-transform: uppercase;
        letter-spacing: 0.83px;
        line-height: 30px;
        border-top: 2px solid #979797;
        padding-top: 30px;
        text-align: center;
        margin-bottom: 5px;
        margin-top: -90px;
    }
    .btn-fluid {
        margin-bottom: 40px;
    }

    @media(max-width: 640px) {
        .img-container {
            max-height:100%;

            .blogBox--img {
                transform: none;
            }
        }

        .blogBox {
            transform: none;
            padding: 20px 15px 95px;
        }

    }
    #blog-carousel {
        //owl carousel override
        .item {
            text-align: left;
        }
        
        img {
            margin-bottom: 15px;
        }

        // carousel only on mobile
        @media screen and (min-width: 991px) {
            .owl-item.cloned{
            display: none !important;
            }
            .owl-stage{
            transform:none !important;
            transition: none !important;
            width: auto !important;
            display:flex;
            }
            .owl-item{
                width: auto !important;
                margin-right: 18px !important;

            }

            .owl-dots {
                display: none;
            }
        }

        .owl-item:last-child {
            margin-right: 0px !important;
        }

        .owl-dots {
            margin-top: 12px;
            margin-bottom: 40px;
        }
    }
}
