.property {
    .d-block.d-md-none {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }

    &__gallery {
        margin-top: 25px;
        position: relative;

        @media (max-width: 1199px) {
            margin-top: 0;
        }

        .btn {
            position: absolute;
            bottom: -15px;
            z-index: 1;
        }
    }
    
    &__btns {
        display: flex;
        position: absolute;
        right: 0;
        top: 15px;

        @media (max-width: 767px) {
            position: relative;
            top: 0;
        }
        

        div {
            display: flex;
            align-items: center;
            margin: 0 15px;

            @media (max-width: 767px) {
                margin: 0 0 10px;
            }
        }

        .fav {
            cursor: pointer;

            &::before {
                position: relative;
                top: 0;
                background-image: url('/assets/images/property/icon-fav.png');

                @media (max-width: 767px) {
                    position: absolute;
                    top: -90px;
                }

                @media (max-width: 500px) {
                    top: -77px;                    
                }
            }

            &::after{
                display: none;
            }

            span {
                @media (max-width: 767px) {
                    display: none;
                }
            }

            &.active {
                &::before {
                    background-image: url('/assets/images/fav.png');
                }
            }

        }
        

        .download {
            cursor: pointer;
            
            @media (max-width: 767px) {
                border-top: 1px solid #DFDFDF;
                border-bottom: 1px solid #DFDFDF;
                width: 100vw;
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                padding: 5px 0;
                font-size: 15px;
            }

            &::before {
                background-image: url(/assets/images/property/icon-download.png);
                background-size: 100%;
                background-repeat: no-repeat;
                display: inline-block;
                width: 21px;
                height: 25px;
                content: "";
                position: relative;
                margin-right: 11px;

                @media (max-width: 767px) {
                    margin-left: 25px;
                }

                @media (max-width: 500px) {
                    margin-left: 10px;
                }
            }
        }
    }

    &__mobileOnly {
        display: none;

        @media (max-width: 1199px) {
            display: block;
        }
    }

    &__chat {
        background-color: #F8F8F8;
        padding: 20px 10px 10px;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 15px;

        @media (min-width: 1200px) {
            display: none;
        }

        @media (max-width: 767px) {
            flex-direction: column;
            justify-content: left;
            padding: 15px 25px 5px;
        }

        @media (max-width: 500px) {
            padding: 20px 10px 10px;
        }

        #openForm {
            cursor: pointer;
        }
    }

    &__features {
        display: flex;

        @media (max-width: 1400px) {
            flex-wrap: wrap;
        }

        p {
            display: flex;
            margin-right: 30px;
        }
    }

    ul {
        padding-inline-start: 0;
        column-count: 3;

        @media (max-width: 767px) {
            column-count: 2;
        }

        @media (max-width: 575px) {
            column-count: 1;
        }

        li {
            font-family: 'Montserrat';
            letter-spacing: 0.7px;
            text-transform: initial;
            line-height: 35px;
            display: flex;

            @media (max-width: 1400px) {
                font-size: 14px;
            }

            &.na {
                text-decoration: line-through;
                opacity: 0.7;
            }
        }

        .icon-calendar {
            &::before {
                background-image: url('/assets/images/property/icon-calender.png');
                background-size: 100%;
                background-repeat: no-repeat;
                display: inline-block;
                width: 23px;
                height: 24px;
                content: "";
                margin-top: 6px;
                margin-right: 10px;
            }
        }
    }

    &__map {
        #map {
            width: 100%;
            height: 300px;
        }
    }

    &__explore {
        margin-top: 30px;
        position: relative;
        
        img {
            width: 600px;

            @media (max-width: 991px) {
                width: 100%;
            }
        }

        &__text {
            position: absolute;
            right: 0;
            background: white;
            top: 30px;
            width: 55%;
            padding: 50px 80px;
            text-align: center;

            @media (max-width: 991px) {
                text-align: center;
                position: relative;
                width: 90%;
                margin: 0 auto;
                top: -50px;
                margin-bottom: -50px;
            }

            @media (max-width: 500px) {
                padding: 50px 10px;
            }
        }

        &__btn {
            margin-top: 5px;
            padding: 10px 25px;
            outline: none;
            color: #818181;
            background: transparent;
            border: 1px solid #DFDFDF;
            text-transform: uppercase;
            font-family: 'Montserrat-Medium';

            @media (max-width: 500px) {
                padding: 10px;
            }
        }
    }

    &__other {
        padding: 50px 0;

        @media (min-width: 768px) {
            width: 550px;
        }

        @media (max-width: 500px) {
            .sub-title {
                margin: 0;
            }
        }

        .sub-title--2 {
            margin-top: 30px;
        }

       
    }

    &__review {
        position: relative; 

        img {
            width: 100%;
        }

        &__content {
            position: absolute;
            top: 100px;
            left: 0;
            width: 50%;
            text-align: center;
            background: white;
            padding: 40px 50px 70px;

            @media (max-width: 1440px) {
                padding: 25px 25px 50px;
            }

            @media (max-width: 991px) {
                position: relative;
                top: -100px;
                width: 90%;
                margin: 0 auto;
                margin-bottom: -100px;
            }

            .star {
                &::before {
                    filter: brightness(0.5);
                }
            }
        }

        h4 {
            line-height: 34px;
            font-size: 19px;

            @media (max-width: 1440px) {
                font-size: 16px;
            }
            
        }

        p {
            margin-bottom: 0;
        }
    }

    &__inquiry {
        &__top {
            background: #F6F6F6;
            padding: 15px 20px 1px;

            @media (max-width: 1199px) {
                display: none;
            }

            p {
                margin-left: 50px;
                margin-top: -10px;
            }
        }

        &__form {
            background: #F6F6F6;
            padding: 20px;
            margin-top: 40px;
            position: relative;
            font-size: 14px;

            @media (max-width: 1199px) {
                display: none;

                &.opened {
                    display: block;
                    position: fixed;
                    top: 20px;
                    z-index: 99;
                    width: 100vw;
                    left: 50%;
                    right: 50%;
                    margin-left: -50vw;
                    margin-right: -50vw;

                    @media (max-width: 320px) {
                        top: -16px; 
                    }
                }
            }
            

            #inquiryPrice {
                width: fit-content;
                position: absolute;
                top: -25px;
                background: white;
                padding: 6px 20px;
                border: 1px solid #7D796E;
                left: 50%;
                transform: translateX(-50%);
                color: black;
                font-size: 15px;
                cursor: pointer
            }

            &__basic {
                display: flex;
                flex-direction: column;
            }

            input, input[type=text], input[type=date], input[type=email], select {
                border: 1px solid #DFDFDF;
                width: 100%;
                padding: 6px 15px;
                height: unset;
                color: #8B8B8B;
            }

            &__date {
                display: flex;

                input[type=text], input[type=date] {
                    text-align: center;
                }
            }

            &__options {
                display: flex;

                label:nth-child(2) {
                    margin: 0 15px;
                }
            }

            &__radio {
                display: flex;

                label.checkbox {
                    display: flex;
                    align-items: center;
                    padding: 25px 0px;
                    margin-right: 25px;

                    span {
                        width: 20px;
                        height: 20px;
                        margin: 0 5px;
                    }
                }

            }

            &__msg {
                margin-left: 10px;

                label {
                    width: 100%;
                }

                textarea {
                    width: 100%;
                    min-height: 100px;
                    background: transparent;
                    border: 1px solid #DFDFDF;
                    margin-top: 5px;

                    @media (max-width: 320px) {
                        min-height: 50px;
                    }
                }
            }

            &__submit {
                text-align: center;

                input[type=submit] {
                    background-color: #FFC85A;
                    width: fit-content;
                    padding: 10px 30px;
                    font-size: 14px;
                    color: black;
                    margin-top: 15px;

                    &:hover {
                        color: #818181;
                        background-color:#D7D7D7; 
                    }

                    @media (max-width: 320px) {
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    &__related {
        @media (max-width: 500px) {
            background-color: #F4F4F4;
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            padding: 30px 0;
        }
    }

    @media (max-width: 500px) {
        .sub-title {
            width: 100%;
            margin: 0px 0px 30px;
        }

        .line--thick {
            display: none;
        }

        h1 {
            font-size: 30px;
        }
    }

}


#propertyGalleryCarousel {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    .owl-item:nth-child(odd) {
        width: 40vw !important;

        @media (max-width: 768px) {
            width: 100vw !important;
        }
    }

    .owl-item:nth-child(even) {
        width: 20vw !important;

        @media (max-width: 768px) {
            width: 100vw !important;
        }

        img:first-child {
            margin-bottom: 10px;

            @media (max-width: 768px) {
                margin-bottom: 0;
            }
        }
    }

    .d-flex {
        .item {
            height: 50%;
        }
    }

    .item {
        @media (max-width: 768px) {
            margin: 0;
        }
    }
    

    .owl-nav {
        display: block;

        .owl-prev {
            position: absolute;
            left: 25px;
            top: 46%;
        }

        .owl-next {
            position: absolute;
            right: 25px;
            top: 46%;
        }
    }
}

#propertyGalleryCarousel {
    

   
}