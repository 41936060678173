#content {
    padding: 10px 15px 0px;
}

.gm-style {
    h1 {
        font-family: 'Montserrat-Bold';
        font-size: 18px;
    }

    p {
        line-height: 25px !important;
        font-size: 16px;
        text-align: left !important;
    }

    a {
        font-weight: bold;
    }
}

button.gm-ui-hover-effect {
    top: 4px !important;
}