#matchCarousel {
    // border-top: 2px solid #DBDBDB;
    // padding-top: 20px;

    .item {
        min-height: 280px;
        width: 100%;
        max-width: 1350px;
        margin: 0 auto;

        @media (max-width: 1400px) {
            padding: 0 25px;
        }

        @media (max-width: 1280px) {
            min-height: 365px;
        }

        @media (max-width: 500px) {
            min-height: 320px;
        }
    }

    .slider-title {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 160px;

        @media (max-width: 1280px) {
            flex-direction: column;
            height: auto;
        }

        &::before {
            background-image: url('/assets/images/home/plane.png');
            background-size: 100%;
            background-position: left center;
            background-repeat: no-repeat;
            display: block;
            width: 100%;
            max-height: 150px;
            height: 30vw;
            max-width: 400px;
            content: "";
            position: absolute;
            left: -150px;
            top: 0;

            @media (max-width: 1280px) {
                position: relative;
                left: 0;
                margin-bottom: 15px;
            }
        }

        &::after {
            background-image: url('/assets/images/home/local.png');
            background-size: 100%;
            background-repeat: no-repeat;
            display: block;
            width: 200px;
            height: 150px;
            content: "";
            position: absolute;
            right: -100px;
            top: 0;

            @media (max-width: 1280px) {
                display: none;
            }
        }

        p {
            font-family: 'Montserrat-SemiBold';

            @media (max-width: 1280px) {
                margin-bottom: 35px;
            }
        }
    }



    .owl-nav {
        max-width: 1470px;
        width: 100%;
        padding-right: 20px;
        display: flex !important;
        font-family: 'Montserrat-SemiBold';

        @media (max-width: 1400px) {
            padding-right: 0;
            max-width: 991px;
        }
    }
}

.slider-prev {
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        background-image: url('/assets/images/prev-thin.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 16px;
        height: 16px;
        content: "";
        margin-right: 5px;
    }
}

.slider-next {
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        background-image: url('/assets/images/next-thin.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        width: 16px;
        height: 16px;
        content: "";
        margin-left: 5px;
    }
}

.slider {
    height: 2px;

    &::before {
        content: "";
        width: 15px;
        height: 15px;
        border: 1px solid #979797;
        background-color: #D8D8D8;
        border-radius: 50%;
        position: absolute;
        left: -8px;
        top: -7px;
    }

    &::after {
        content: "";
        width: 15px;
        height: 15px;
        border: 1px solid #979797;
        background-color: #D8D8D8;
        border-radius: 50%;
        position: absolute;
        right: -8px;
        top: -7px;
    }

    .ui-slider-handle {
        background-image: url('/assets/images/slider-handle.png');            
        background-size: 100%;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        top: -15px;
        border: none;
        background-color: transparent;
        outline: none;
    }

    label {
        position: absolute;
        width: 20px;
        margin-left: -10px;
        text-align: center;
        margin-top: 20px;

        @media (max-width: 500px) {
            visibility: hidden;
        }

        &:nth-child(2) {
            &::after {
                content: "Not familiar at all with my destination";
                width: 150px;
                font-size: 15px;
                left: -65px;
                position: absolute;
                display: block;
                top: 30px;

                @media (max-width: 1400px) {
                    left: -13px;
                    width: 11vw;
                    min-width: 110px;                    
                    text-align: left;
                }

                @media (max-width: 500px) {
                    visibility: visible;
                    top: 0;                    
                }
        

            }
        }

        &:last-child {
            &::after {
                content: "I know it like the back of my hand";
                width: 150px;
                font-size: 15px;
                left: -65px;
                position: absolute;
                top: 30px;

                @media (max-width: 1400px) {
                    left: unset;
                    right: -13px;
                    width: 11vw;
                    min-width: 100px;                    
                    text-align: right;                    
                }

                @media (max-width: 500px) {
                    visibility: visible;
                    top: 0;
                    width: 11vw;
                    
                }
        
            }
        }
    }    
    
}
