.result-lists {
    display: flex;
    flex-wrap: wrap;

    &__each:not(.property) {
        padding: 10px;
        width: 16.6%;
        margin-bottom: 20px;
        position: relative;

        @media (max-width: 1199px) {
            width: 20%;
        }

        @media (max-width: 900px) {
            width: 25%;
        }

        @media (max-width: 767px) {
            width: 33%;
        }

        @media (max-width: 500px) {
            width: 100%;
            margin-bottom: 0;
        }

        .carousel-wrap {
            .owl-carousel {

                @media (max-width: 500px) {
                    width: 100vw;
                    position: relative;
                    left: 50%;
                    right: 50%;
                    margin-left: -50vw;
                    margin-right: -50vw;
                    border-top: 2px solid #DBDBDB;
                    padding: 20px 0 10px;
                }
            }
            
            .owl-stage {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .owl-item {
                min-width: 130px !important;
            }

            .item {
                margin: 0;
            }

            img {
                width: 100%;
                max-width: 255px;

                @media (max-width: 500px) {
                    width: 130px;
                }
            }

            .owl-nav {
                display: flex;

                .thumbnail-prev {
                    &::before {
                        content: "<";
                        font-size: 25px;
                        color: white;
                        display: flex;
                        position: absolute;
                        top: 40%;
                        left: 15px;
                        width: 35px;
                        height: 40px;
                    }
                }
                
                .thumbnail-next {
                    &::before {
                        content: ">";
                        font-size: 25px;
                        color: white;
                        display: flex;
                        position: absolute;
                        top: 40%;
                        right: 0;
                        width: 35px;
                        height: 40px;
                    }
                }

            }

            .thumbnail-next,
            .thumbnail-prev {
                opacity: 0;
                transition: all 0.5s ease;
            }

            .owl-carousel:hover .thumbnail-next,
            .owl-carousel:hover .thumbnail-prev
             {
                opacity: 1;
                transition: all 0.5s ease;
            }

            .owl-dots {
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                display: flex;

                .owl-dot {
                    height: 8px;
                    width: 8px;
                    opacity: 0.54;
                    background-color: #EFEFEF;
                    border-radius: 50%;
                    border: 1px solid transparent;
                    margin: 2px;

                    &.active {
                        opacity: 1;
                    }
                }
            }
        }

        .text-wrap {
            @media (max-width: 500px) {
                position: relative;
            }
            
            div:nth-child(3) {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                font-family: 'Montserrat-Medium';
                text-transform: uppercase;
                font-size: 13px;

                @media (max-width: 500px) {
                    justify-content: left;
                    align-items: center;
                }
            }

            p {
                margin-bottom: 0;
                font-weight: 600;
                font-size: 12px;
                margin: 0 0 -5px 0;

                span {
                    font-family: 'Montserrat-Bold';
                }
            }
        }
    }
}

.result-lists > .result-lists__each:first-child > .carousel-wrap > .owl-carousel {
    @media (max-width: 500px) {
        border-top: none;
        margin-top: -20px;
    }
}

.units::after {
    content: " units";
}