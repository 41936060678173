input[type=submit], 
.btn {
    color: #818181;
    font-family: 'Montserrat-Medium';
    font-size: 12px;
    text-align: center;
    background-color: #F7F7F7;
    padding: 8px;
    outline: none;
    border: none;
    width: 155px;
    height: auto;
    margin: 0 12px;
    border-radius: 0;

    @media (max-width: 500px) {
        padding: 10px;        
    }

    &:hover {
        background-color: #D7D7D7;
    }

    a {
        font-family: 'Montserrat-Medium';
        font-size: 14px;
        color: #818181;
        text-decoration: none;
    }
}

.btn-fluid {
    border-top: 2px solid #DBDBDB;
    border-bottom: 2px solid #DBDBDB;    
    color: #4E4E1B;
    text-align: center;
    width: 100%;
    padding: 5px;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    a {
        color: #4E4E1B; 
        font-family: 'Montserrat-Medium';
        font-size: 15px;      
        text-decoration: none;   
        transition: font-size .5s ease;
        
        &:hover {
            font-weight: bold;
            font-size: 16px;
        }
    }
}

.btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-viewAll {
    background: #F8F8F8;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    text-align: center;
    padding: 10px;
    margin-top: 20px;

    @media (min-width: 501px) {
        display: none;
    }

    a {
        font-family: 'MONTSERRAT-MEDIUM';
        font-size: medium;
        letter-spacing: 0.7px;
    }
}

.close-btn {
    display: none;

    @media (max-width: 1199px) {
        display: block;

        &::after {
            content: "x";
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 20px;
            font-size: 20px;
        }
    }
}